import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import moment from 'moment';
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { SummaryComponent } from '@newageerp/v3.toolbar.summary-component';

export default function DashboardRecurringPivot() {
    const { t } = useTranslation();

    const defDates = {
        dateFrom: moment().add(-10, 'day').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    return (
        <Fragment>
            <MainToolbarTitle title={"Recurring pivot"} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                    </div>
                </WhiteCard>

                <SummaryComponent network={{
                    url: '/app/plugins/Recurring/pivotData',
                    type: 'post',
                    data: {
                        dateFrom: chartDates.dateFrom,
                        dateTo: chartDates.dateTo
                    }
                }} initConfigId={'recurring-performace'} />
            </div>
        </Fragment>
    )
}
