import { useUList } from '@newageerp/v3.bundles.hooks-bundle';
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { Compact, CompactRow, FieldDateRange, FieldLabel, FieldSelect, FieldSelectMulti } from '@newageerp/v3.bundles.form-bundle';
import { useTranslation } from 'react-i18next';

export default function DashboardAdCosts() {
    const { t } = useTranslation();

    const [countryGroup, setCountryGroup] = useState(0);

    const defDates = {
        dateFrom: moment().subtract(1, 'month').format("YYYY-MM-DD"),
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };

    const [lang, setLang] = useState('');
    const [countries, setCountries] = useState<string[]>([]);

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [countriesReq, countriesData] = useUList("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    useEffect(() => {
        if (countryGroup <= 0) {
            setCountries([]);
        } else {
            const _cGroup: any = countryGroupsData.data.data.find((el: any) => el.id === countryGroup);
            let _countries: string[] = [];
            if (_cGroup) {
                const countriesExclude = _cGroup.countriesExclude.filter((c: string) => !!c);
                const countriesInclude = _cGroup.countries.filter((c: string) => !!c);
                if (countriesExclude.length > 0) {
                    _countries = countriesData.data.data.filter((c: any) => countriesExclude.indexOf(c.name) === -1).map((c: any) => c.name);
                }
                if (countriesInclude.length > 0) {
                    _countries = [..._countries, ...countriesInclude];
                }
            }
            setCountries(_countries);
        }
    }, [countryGroup]);

    return (
        <Fragment>
            <MainToolbarTitle title={"Ad costs dashboard"} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Language')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={lang}
                                        onChange={setLang}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "En",
                                                value: "en",
                                            },
                                            {
                                                label: "Es",
                                                value: "es",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Country')}</FieldLabel>}
                                control={
                                    <FieldSelectMulti
                                        value={countries}
                                        onChange={setCountries}
                                        asString={true}
                                        options={
                                            countriesData.data.data.map((i: any) => {
                                                return (
                                                    {
                                                        label: i.name,
                                                        value: i.name,
                                                    }
                                                )
                                            })
                                        }
                                    />
                                }
                                controlClassName='max-w-[500px]'
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Country group')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={countryGroup}
                                        onChange={setCountryGroup}
                                        options={[
                                            {
                                                label: "All",
                                                value: 0,
                                            },
                                            ...countryGroupsData.data.data.map((i: any) => {
                                                return (
                                                    {
                                                        label: i.name,
                                                        value: i.id,
                                                    }
                                                )
                                            })
                                        ]}
                                    />
                                }

                            />
                        </Compact>
                    </div>
                </WhiteCard>

            </div>
        </Fragment>
    )
}
