import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { LogoLoader, MainToolbarTitle, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';

const uri = 'data:application/vnd.ms-excel;base64,';

const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{table}</body></html>';

const base64 = (s: string) => {
    return window.btoa(unescape(encodeURIComponent(s)));
}

const doFormat = (s: string, tableId: string) => {
    const context = {
        worksheet: 'Worksheet',
        // @ts-ignore
        table: window.document.getElementById(tableId).outerHTML,
    };

    // @ts-ignore
    return s.replace(/{(\w+)}/g, (_m, p) => context[p]);
}

const doDownload = (tableId: string) => {
    const element = window.document.createElement('a');
    element.href = uri + base64(doFormat(template, tableId));
    element.download = 'export.xls';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export default function DashboardAccounting() {
    const { t } = useTranslation();

    const [chartStats, setChartStats] = useState([]);
    const [totals, setChartTotals] = useState<any>({});

    const defDates = {
        dateFrom: moment().startOf('month').format("YYYY-MM-DD"),
        dateTo: moment().endOf('month').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [reqData, reqDataParams] = useURequest("/app/plugins/DashboardAccounting/calculate");
    const loadData = () => {
        reqData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
        }).then((res: any) => {
            setChartStats(res.data.data);
            setChartTotals(res.data.totals);
        })
    }

    useEffect(() => {
        loadData();
    }, [chartDates]);

    const borderRight = 'border-r border-blue-400';
    const borderLeft = 'border-l border-blue-400';

    const ppMethods = ['paypal', 'other', 'all'];

    return (
        <Fragment>
            <MainToolbarTitle title={"Accounting dashboard"} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>

                        <div>
                            <ToolbarButton
                                iconName={"file-excel"}
                                onClick={() => doDownload('gross-pl-export')}
                            />
                        </div>
                    </div>
                </WhiteCard>


                {reqDataParams.loading &&
                    <div className='flex justify-center'>
                        <LogoLoader size={60} />
                    </div>
                }

                {!!chartStats && chartStats.length > 0 &&

                    <WhiteCard isCompact={true}>

                        <Table
                            id='gross-pl-export'
                            thead={<thead>
                                <tr>
                                    <Th>{t('Date')}</Th>
                                    <Th textAlignment={classNames('text-right', borderLeft)}>{t('Paypal gross revenue, $')}</Th>
                                    <Th textAlignment='text-right'>{t('Count')}</Th>
                                    <Th textAlignment='text-right'>{t('Paypal refunds, $')}</Th>
                                    <Th textAlignment='text-right'>{t('Count')}</Th>
                                    <Th textAlignment={classNames('text-right')}>{t('Paypal net revenue, $')}</Th>

                                    <Th textAlignment={classNames('text-right', borderLeft)}>{t('Other gross revenue, $')}</Th>
                                    <Th textAlignment='text-right'>{t('Count')}</Th>
                                    <Th textAlignment='text-right'>{t('Other refunds, $')}</Th>
                                    <Th textAlignment='text-right'>{t('Count')}</Th>
                                    <Th textAlignment={classNames('text-right')}>{t('Other net revenue, $')}</Th>

                                    <Th textAlignment={classNames('text-right', borderLeft)}>{t('All gross revenue, $')}</Th>
                                    <Th textAlignment='text-right'>{t('Count')}</Th>
                                    <Th textAlignment='text-right'>{t('All refunds, $')}</Th>
                                    <Th textAlignment='text-right'>{t('Count')}</Th>
                                    <Th textAlignment={classNames('text-right')}>{t('All net revenue, $')}</Th>

                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {chartStats.map((stat: any) => {

                                        return (
                                            <tr key={`stat-${stat.title}`}>
                                                <Td className='whitespace-nowrap'>{stat.title}</Td>

                                                {ppMethods.map(pp => {
                                                    return (
                                                        <Fragment key={`pp-${stat.title}-${pp}`}>
                                                            <Td textAlignment={classNames('text-right', borderLeft)}>{stat[pp].gross.toFixed(2)}</Td>
                                                            <Td textAlignment={classNames('text-right')}>{stat[pp].grossCount}</Td>
                                                            <Td textAlignment={classNames('text-right')}>{stat[pp].refund.toFixed(2)}</Td>
                                                            <Td textAlignment={classNames('text-right')}>{stat[pp].refundCount}</Td>
                                                            <Td textAlignment={classNames('text-right')}>{(stat[pp].gross + stat[pp].refund).toFixed(2)}</Td>
                                                        </Fragment>
                                                    )
                                                })}


                                            </tr>
                                        )
                                    })}

                                    <tr className='total-row'>
                                        <Td className='whitespace-nowrap'>Total</Td>

                                        {ppMethods.map(pp => {
                                            return (
                                                <Fragment key={`pp-${pp}`}>
                                                    <Td textAlignment={classNames('text-right', borderLeft)}>{totals[pp].gross.toFixed(2)}</Td>
                                                    <Td textAlignment={classNames('text-right')}>{totals[pp].grossCount}</Td>
                                                    <Td textAlignment={classNames('text-right')}>{totals[pp].refund.toFixed(2)}</Td>
                                                    <Td textAlignment={classNames('text-right')}>{totals[pp].refundCount}</Td>
                                                    <Td textAlignment={classNames('text-right')}>{(totals[pp].gross + totals[pp].refund).toFixed(2)}</Td>
                                                </Fragment>
                                            )
                                        })}


                                    </tr>
                                </tbody>
                            }
                        />

                    </WhiteCard>
                }
            </div>
        </Fragment>
    )
}
