import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import React, { Fragment } from 'react'

interface Props {

}

export default function QuizParseResult(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    const results = element['results'];
    const keys = Object.keys(results).filter(k => k.indexOf('q-') === 0 && k !== 'q--10');

    return (
        <Table
            thead={
                <thead>
                    <tr>
                        <Th>Question</Th>
                        <Th>Answer</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {keys.map(key => {
                        const answer = results[key];

                        return (<tr key={`k-${key}`}>
                            <Td>{key}</Td>
                            <Td>
                                {Array.isArray(answer) ? <Fragment>
                                    {answer.map(a => {
                                        return (
                                            <p key={`k-${key}-${a.id}`}>{a.Text}</p>
                                        )
                                    })}
                                </Fragment> : answer.Text}
                            </Td>
                        </tr>)
                    })}
                </tbody>
            }

        />
    )
}
