import { Form } from '@newageerp/crm-ui'
import React from 'react'
import { RealtimeV3DateSelection } from './types'

type Props = {
    dates: RealtimeV3DateSelection,
    setDates: (v: RealtimeV3DateSelection) => void,
    clearState: () => void,
}

export default function RealtimeV3Dates({ dates, setDates, clearState }: Props) {
    return (
        <div className='flex items-start gap-2'>
            <Form.DateRangeInput dates={dates} setDates={setDates} onClear={clearState} compact={true} />
            {/* <p className='uppercase'>Step 3:</p>
            <p className='text-slate-500 w-[150px]'>select dates</p>
            <div>
                <Form.DateRangeInput dates={dates} setDates={setDates} onClear={clearState} compact={true} />
            </div> */}
        </div>
    )
}
