import React, { useEffect, useState } from 'react'
import { RealtimeV3GroupingRowItem } from './types'
import RealtimeV3GroupingRow from './RealtimeV3GroupingRow'
import RealtimeV3Data from './RealtimeV3Data';
import { MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import { Card } from '@newageerp/crm-ui';

type Props = {
    filters: MpFilterFactorySetUniversalItem[],
    parentItem: RealtimeV3GroupingRowItem
}

export default function RealtimeV3DataExplore(props: Props) {
    const [item, setItem] = useState<RealtimeV3GroupingRowItem>({
        group: '',
        fields: props.parentItem.fields,
    })
    const [reloadKey, setReloadKey] = useState(0);

    useEffect(() => {
        if (item.group && item.fields) {
            setReloadKey(new Date().getTime());
        }
    }, [item]);

    return (
        <div className='pb-[400px] space-y-4'>
            <RealtimeV3GroupingRow
                item={item}
                changeItem={(k, v) => setItem({ ...item, [k]: v })}
            />
            {reloadKey > 0 &&
                <Card.WhiteCard>
                    <RealtimeV3Data
                        key={`r-${reloadKey}`}
                        filters={props.filters}
                        item={item}
                    />
                </Card.WhiteCard>
            }
        </div>
    )
}
