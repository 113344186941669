import { FieldLabel, Wide, WideRow } from '@newageerp/v3.bundles.form-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment, useEffect, useState } from 'react'

type Props = {
  orderId: string,
}

export default function ReportItemShippingAddress(props: Props) {
  const [shippingAddress, setShippingAddress] = useState();

  const getData = async () => {
    const url = '/app/plugins/CrmSession/getShippingAddress';
    const res = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({
          orderId: props.orderId
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    const resJson = await res.json();

    if (resJson && resJson.success === 1 && !!resJson.shippingAddress) {
      setShippingAddress(resJson.shippingAddress);
    }
  }

  useEffect(() => {
    getData().catch(console.error);
  }, [props.orderId])

  if (!shippingAddress) {
    return <Fragment />
  }

  const keys = Object.keys(shippingAddress);

  return (
    <WhiteCard title='Shipping address'>
      <Wide>
        {keys.map(k => {
          return (
            <WideRow key={`k-${k}`}
              label={<FieldLabel>{k}</FieldLabel>}
              control={
                <p>{shippingAddress[k]}</p>
              }
            />
          )
        })}
      </Wide>
    </WhiteCard>
  )
}
