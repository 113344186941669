import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import React from 'react'
import { SFSForm } from '@newageerp/v3.templates.json-form';
import validator from '@rjsf/validator-ajv8'

interface Props {
    fieldKey: string,
}

export default function DataExplodeColumns(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element, updateElement } = tData;

    return (
        <SFSForm
            schema={schema}
            validator={validator}
            onSubmit={(e) => {
                updateElement(props.fieldKey, e.formData)
            }}
            formData={element[props.fieldKey]}
        />
    )
}

const schema = {
    type: 'array',
    items: {
        type: 'object',
        required: [
            "path"
        ],
        properties: {
            title: { type: 'string', title: 'Title' },
            path: { type: 'string', title: 'Path', default: 'i.' },
            type: { type: 'string', title: 'Type', default: 'string' },
            link: {
                type: 'object',
                properties: {
                    entity: { type: 'string', title: 'Entity' },
                    field: { type: 'string', title: 'Key' },
                    value: { type: 'string', title: 'Value' },
                }
            }
        },
    },
}
