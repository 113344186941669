import { ResolverWindowElementFormOptions } from '@newageerp/crm-ui'
import React, { Fragment } from 'react'
import JsonField from './Components/JsonField'
import DataExplodeColumns from './Components/DataExplodeColumns'

export default function ResolverEditFormCustomField(props: ResolverWindowElementFormOptions) {
  return (
    <Fragment>
      {props.name === 'EditFields/JsonField' && <JsonField fieldKey={props.fieldKey} />}
      {props.name === 'DataExplore/Columns' && <DataExplodeColumns fieldKey={props.fieldKey} />}
    </Fragment>
  )
}
