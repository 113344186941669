import { Card, Button, MP, Table, Form } from '@newageerp/crm-ui'
import React, { useState } from 'react'
import { MpFilterStorageListResponseData, MpChartSettings, MpFilterFactorySetUniversalItem } from "@newageerp/mp-types";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

type Props = {
    setShowFiltersPopup: (v: boolean) => void,
    selectedCharts: string[][],
    availableFilters: MpFilterStorageListResponseData[],
}

type TotalLine = {
    total: number,
    title: string,
    users: number,
}

type IncomeItem = {
    period: number,
    total: number,
}

type UserItem = {
    period: number,
    count: number,
}

type ChartItem = {
    users: number,
    income: number,
    ltvOnPeriod: number,
    ltv: number,
}

type ChartPeriodItem = {
    x: string,
    data: {
        [key: string]: ChartItem,
    }
}

const LtvIncomeConfig: MpChartSettings = {
    "title": "Chart",
    "axisX": "payment.week_after_sub_start",
    "aggregate": [
        {
            "column": "payment.total",
            "function": "sum",
            "title": "Income",
            "chartType": "line"
        }
    ]
}

const LtvUsersConfig: MpChartSettings = {
    "title": "Chart",
    "axisX": "week_after_sub_start",
    "aggregate": [
        {
            "column": "report_item.order_id",
            "function": "countDistinct",
            "title": "Users",
            "chartType": "bar"
        }
    ]
}

const tbl = Table.default;

export default function ProductPerformanceChartChart(props: Props) {
    const defDates = {};
    const [chartDates, setChartDates] = useState<{ dateFrom?: string, dateTo?: string }>(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const selectedCharts = props.selectedCharts.filter(f => f.length > 0);

    const [totalData, setTotalData] = useState<TotalLine[]>([])
    const [chartData, setChartData] = useState<ChartPeriodItem[]>([]);
    const [activeFilters, setActiveFilters] = useState<MpFilterStorageListResponseData[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [doReqIncome] = MP.useMpChartData({
        config: LtvIncomeConfig,
        dataSource: 'product-performance-ltv'
    });
    const [doReqUsers] = MP.useMpChartData({
        config: LtvUsersConfig,
        dataSource: 'product-performance-ltv-users'
    });

    const isActiveDateFilter = (!!chartDates.dateFrom && !!chartDates.dateTo);

    const loadData = async () => {
        if (loadingData) return false;

        setLoadingData(true);
        const _totalData: TotalLine[] = [];
        const tmpData: {
            [key: string]: {
                income: IncomeItem[],
                users: UserItem[],
                periods: number[],
            }
        } = {};
        const periods: number[] = [];
        const filters: any[] = [];


        let index = 1;
        for (const charts of selectedCharts) {
            const fTitle = `chart_${index}`;
            filters.push({
                title: fTitle
            });

            const chartFilters: MpFilterFactorySetUniversalItem[] = []

            for (const fTitle of charts) {
                const f = props.availableFilters.find(t => t.title === fTitle);
                if (!!f && !!f.filter) {
                    chartFilters.push(...f.filter);
                }
            }

            if (isActiveDateFilter) {
                chartFilters.push(
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DateBetweenFilter",
                                "props": { "column": "subscriptionStart", "valueFrom": chartDates.dateFrom, "valueTo": chartDates.dateTo }
                            }
                        ]
                    }
                )
            }

            const resIncome = await doReqIncome(chartFilters);
            const dataIncome = resIncome.data.data;

            const resUsers = await doReqUsers(chartFilters);
            const dataUsers = resUsers.data.data;

            _totalData.push({
                title: fTitle,
                total: dataIncome.map((e: any) => e.payment.total_sum).reduce((a: number, b: number) => a + b, 0),
                users: dataUsers.map((e: any) => e.order_id_countDistinct).reduce((a: number, b: number) => a + b, 0),
            })

            tmpData[fTitle] = {
                income: dataIncome.map((e: any) => ({
                    period: e.payment.week_after_sub_start,
                    total: e.payment.total_sum
                })),
                users: dataUsers.map((e: any) => ({
                    period: e.week_after_sub_start,
                    count: e.order_id_countDistinct
                })),
                periods: [
                    ...dataIncome.map((e: any) => e.payment.week_after_sub_start),
                    ...dataUsers.map((e: any) => e.week_after_sub_start)
                ].filter((value, index, array) => array.indexOf(value) === index)
            };
            periods.push(Math.max(...tmpData[fTitle].periods));
            index++;
        }

        const maxPeriod = Math.max(...periods);
        const _chartData = [];
        for (let period = 0; period <= maxPeriod; period++) {
            const el: ChartPeriodItem = {
                x: period.toFixed(0),
                data: {

                }
            }
            for (const line of filters) {
                const lineKey = line.title;

                const lineData: ChartItem = {
                    users: 0,
                    income: 0,
                    ltvOnPeriod: 0,
                    ltv: 0,
                };

                const usersOnPeriod = tmpData[line.title].users.filter(u => u.period >= period).map(u => u.count).reduce((a, b) => a + b, 0);
                const incomeOnPeriod = tmpData[line.title].income.filter(u => u.period === period).map(u => u.total).reduce((a, b) => a + b, 0);
                if (usersOnPeriod > 0) {
                    lineData.users = usersOnPeriod;

                    lineData.income = roundNum(incomeOnPeriod, 2);
                    lineData.ltvOnPeriod = roundNum(incomeOnPeriod / usersOnPeriod, 4);

                    const ltvSum = _chartData.map(e => e.data[lineKey].ltvOnPeriod).reduce((a, b) => a + b, lineData.ltvOnPeriod);
                    lineData.ltv = roundNum(ltvSum, 2);
                    el.data[lineKey] = lineData;
                }

            }
            _chartData.push(el);
        }
        setActiveFilters(filters);
        setTotalData(_totalData);
        setChartData(_chartData);
        setLoadingData(false);
    }
    
    const labelClassName = 'text-sm font-bold';
    return (
        <Card.WhiteCard
            header={{
                title: 'Chart',
                toolbar: <Card.toolbar.CardToolbar>
                    <div className='flex items-center gap-2'>

                        <Form.DateRangeInput dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />

                        <Button.ToolbarButton loading={loadingData} iconName='chart-line' onClick={loadData}>Draw charts</Button.ToolbarButton>

                        <p className='text-sm'>Selected: {selectedCharts.length}</p>
                    </div>
                </Card.toolbar.CardToolbar>
            }}
        >
            <div className='space-y-2'>
                <p className={labelClassName}>Chart definitions</p>
                <tbl.Table
                    thead={
                        <thead>
                            <tbl.Th>Chart</tbl.Th>
                            <tbl.Th>Filters</tbl.Th>

                        </thead>
                    }
                    tbody={
                        <tbody>
                            {selectedCharts.map((charts, chIdx) => {
                                return <tr key={`chart-r-${chIdx}`}>
                                    <tbl.Td>chart_{chIdx + 1}</tbl.Td>
                                    <tbl.Td>{charts.map(fTitle => {
                                        const f = props.availableFilters.find(t => t.title === fTitle);
                                        return f?.title;
                                    }).join(" + ")}
                                    {isActiveDateFilter && ` + Subscription start date filter`}
                                    </tbl.Td>
                                </tr>
                            })}
                        </tbody>
                    }
                />
            </div>
            {chartData.length > 0 && !loadingData && <div className='space-y-4'>


                <div className='space-y-2'>
                    <p className={labelClassName}>Cumulative LTV per customer, USD</p>
                    <ResponsiveContainer width='100%' height={300}>

                        <LineChart data={chartData} syncId="ltv">
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {activeFilters.map((p, idx) => <Line
                                dot={false}
                                key={`line-${p.title}`}
                                type="monotone"
                                dataKey={`data.${p.title}.ltv`}
                                stroke={colors[idx]}
                                name={p.title}
                            />)}
                        </LineChart>

                    </ResponsiveContainer>
                </div>
                <div className='space-y-2'>
                    <p className={labelClassName}>Customers on period</p>
                    <ResponsiveContainer width='100%' height={300}>

                        <LineChart data={chartData} syncId="ltv">
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {activeFilters.map((p, idx) => <Line
                                dot={false}
                                key={`line-${p.title}`}
                                type="monotone"
                                dataKey={`data.${p.title}.users`}
                                stroke={colors[idx]}
                                name={p.title}
                            />)}
                        </LineChart>

                    </ResponsiveContainer>
                </div>
                <div className='space-y-2'>
                    <p className={labelClassName}>Net income total, USD</p>
                    <ResponsiveContainer width='100%' height={300}>

                        <LineChart data={chartData} syncId="ltv">
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {activeFilters.map((p, idx) => <Line
                                dot={false}
                                key={`line-${p.title}`}
                                type="monotone"
                                dataKey={`data.${p.title}.income`}
                                stroke={colors[idx]}
                                name={p.title}
                            />)}
                        </LineChart>

                    </ResponsiveContainer>
                </div>
                <div className='space-y-2'>
                    <p className={labelClassName}>LTV per customer, USD</p>
                    <ResponsiveContainer width='100%' height={300}>

                        <LineChart data={chartData} syncId="ltv">
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {activeFilters.map((p, idx) => <Line
                                dot={false}
                                key={`line-${p.title}`}
                                type="monotone"
                                dataKey={`data.${p.title}.ltvOnPeriod`}
                                stroke={colors[idx]}
                                name={p.title}
                            />)}
                        </LineChart>

                    </ResponsiveContainer>
                </div>

                <div className='space-y-2'>
                    <p className={labelClassName}>Summary</p>
                    <tbl.Table
                        thead={
                            <thead>
                                <tr>
                                    <tbl.Th>Filter</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>Income</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>Users</tbl.Th>
                                </tr>
                            </thead>
                        }
                        tbody={
                            <tbody>
                                {totalData.map((t, tIdx) => {
                                    return (
                                        <tr key={`total-${tIdx}`}>
                                            <tbl.Td>{t.title}</tbl.Td>
                                            <tbl.Td props={{ className: 'text-right' }}>{t.total.toFixed(2)}</tbl.Td>
                                            <tbl.Td props={{ className: 'text-right' }}>{t.users.toFixed(0)}</tbl.Td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    />
                </div>


            </div>}
        </Card.WhiteCard>
    )
}

const roundNum = (n: number, p: number = 2) => (e => Math.round(n * e) / e)(Math.pow(10, p))
