import { ResolverWindowElementFormOptions } from '@newageerp/crm-ui'
import React, { Fragment } from 'react'
import JsonField from './Components/JsonField'
import QuizParseResult from './Components/QuizParseResult'

export default function ResolverViewFormCustomField(props: ResolverWindowElementFormOptions) {
  return (
    <Fragment>
      {props.name === 'ViewFields/JsonField' && <JsonField fieldKey={props.fieldKey} />}
      {props.name === 'QuizComponents/ParseResult' && <QuizParseResult />}
    </Fragment>)
}
