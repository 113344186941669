import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CountryFilterCountry } from './CountryFilter';

type Props = {
    countryGroup: number,
    setCountryGroup: (v: number) => any,
    countryGroupsData: CountryGroupFilterGroup[],

    setCountries: (v: string[]) => void,
    countriesData: CountryFilterCountry[],
}

export default function CountryGroupFilter(props: Props) {
    const { t } = useTranslation();
    const { countryGroup, setCountryGroup, countryGroupsData, setCountries, countriesData } = props;

    useEffect(() => {
        if (countryGroup <= 0) {
            setCountries([]);
        } else {
            const _cGroup: any = countryGroupsData.find((el: any) => el.id === countryGroup);
            let _countries: string[] = [];
            if (_cGroup) {
                const countriesExclude = _cGroup.countriesExclude.filter((c: string) => !!c);
                const countriesInclude = _cGroup.countries.filter((c: string) => !!c);
                if (countriesExclude.length > 0) {
                    _countries = countriesData.filter((c: any) => countriesExclude.indexOf(c.name) === -1).map((c: any) => c.name);
                }
                if (countriesInclude.length > 0) {
                    _countries = [..._countries, ...countriesInclude];
                }
            }
            setCountries(_countries);
        }
    }, [countryGroup]);

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Country group')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={countryGroup}
                        onChange={setCountryGroup}
                        options={[
                            {
                                label: "All",
                                value: 0,
                            },
                            ...countryGroupsData.map((i: any) => {
                                return (
                                    {
                                        label: i.name,
                                        value: i.id,
                                    }
                                )
                            })
                        ]}
                    />
                }

            />
        </Compact>
    )
}

export type CountryGroupFilterGroup = {
    id: number,
    name: string,
    countries: string[],
    countriesExclude: string[]
}
