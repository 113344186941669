import React from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";

interface Props {
    fieldKey: string,
}

export default function JsonField(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    const val = element[props.fieldKey];

    return (
        <div>
            <pre>
                {JSON.stringify(val)}
            </pre>
        </div>
    )
}
