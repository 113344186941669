import React, { Fragment } from 'react'
import SubscriptionSupportReport from './Components/SubscriptionSupportReport'
import ShippingOrderExport from './Components/ShippingOrderExport'
import PaymentRefundRequest from './Components/PaymentRefundRequest'

type Props = {
    dataSource: string
}

export default function ResolverListToolbarLeft(props: Props) {
    return (
        <Fragment>
            {props.dataSource === 'subscription-support-report' && <SubscriptionSupportReport />}
            {props.dataSource === 'shipping-order' && <ShippingOrderExport />}
            {props.dataSource === 'payment-refund-request' && <PaymentRefundRequest />}

        </Fragment>
    )
}
