import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    gender: string,
    setGender: (v: string) => void,
}

export default function GenderFilter(props: Props) {
    const { t } = useTranslation();
    const { gender, setGender } = props;
    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Gender')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={gender}
                        onChange={setGender}
                        options={[
                            {
                                label: "All",
                                value: "",
                            },
                            {
                                label: "Male",
                                value: "male",
                            },
                            {
                                label: "Female",
                                value: "female",
                            },
                            {
                                label: "Other",
                                value: "-",
                            }
                        ]}
                    />
                }
            />
        </Compact>
    )
}
