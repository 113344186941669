import React from 'react'
import { MP } from '@newageerp/crm-ui'
import { useParams } from '@newageerp/v3.templates.templates-core'
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";

export default function MpEndPoint() {
    const params = useParams();
    const { settings } = useUIBuilder();
    return (
        <MP.GlobalProvider settings={{
            apiKey: settings.mp.apiKey,
            baseUrl: settings.mp.baseUrl,
        }}>
            <MP.InfoProvider key={`dt-${params.dataSource}`} dataSource={params.dataSource} contentChildren={<MP.Data />} user='all' />
        </MP.GlobalProvider>
    )
}
