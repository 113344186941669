import React, { useEffect, useState } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { FieldTextarea } from '@newageerp/v3.bundles.form-bundle';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';

interface Props {
    fieldKey: string,
}

export default function JsonField(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element, updateElement } = tData;
    const [val, setVal] = useState('');

    const isJson = isJsonString(val);

    useEffect(() => {
        setVal(JSON.stringify(element[props.fieldKey]))
    }, [element]);

    const doUpdate = () => {
        updateElement(props.fieldKey, JSON.parse(val));
    }

    return (
        <div className='space-y-1'>
            <FieldTextarea
                className='w-full'
                value={val}
                onChange={e => setVal(e.target.value)}
            />
            <ToolbarButton small={true} iconName='save' onClick={doUpdate}>Update</ToolbarButton>
            <pre>
                {JSON.stringify(element[props.fieldKey])}
            </pre>
            {!isJson && <p className='text-red-500'>Check json string</p>}
        </div>
    )
}

function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}