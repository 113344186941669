import { MenuItemWithCreate } from '@newageerp/v3.bundles.modal-bundle'
import React from 'react'
import { PropsId } from '../../../_generated/_custom/models-cache-data/types'

export default function CancelSubscription(props: PropsId) {
  return (
    <MenuItemWithCreate
      elementId={props.id}
      sourceSchema={"customer"}
      targetSchema={"subscription-cancel"}
      forcePopup={true}
    >Cancel subscription</MenuItemWithCreate>
  )
}
