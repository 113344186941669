import React, { Fragment } from 'react'
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';
import { checkPermission, Permissions } from '../../../../../Plugins/Permissions/Permissions';

interface Props {
    fieldKey: string,
}

export default function SubscriptionPaymentActions(props: Props) {
    const { userState } = useTemplatesCore();
    const row = useListTableRow();
    const { element } = row;
    const isAdmin = checkPermission(userState, Permissions.Enum.admin);

    const [doReq, reqParams] = useURequestRaw<any>("/app/plugins/SubscriptionPayment/doRecurring")

    const onClick = () => {
        doReq({ id: element.id })
    }

    if (!isAdmin) {
        return <Fragment />
    }

    return (
        <MainButton loading={reqParams.loading} iconName='dollar' onClick={onClick} confirmation={true} color={!!reqParams.data && reqParams.data.error ? 'red' : (reqParams.data ? (reqParams.data.success === 1 ? 'teal' : 'orange') : 'sky')}>
            Try to charge
        </MainButton>
    )
}
