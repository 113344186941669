import React, { Fragment, useEffect, useState } from 'react'
import { MP } from '@newageerp/crm-ui'
import { DashboardGrossCustomAdSpendItem, DashboardGrossCustomConfig, DashboardGrossCustomFilter, DashboardGrossCustomGrossPerfItem, DashboardGrossCustomGroupBy } from './types';
import { Table, Card } from '@newageerp/crm-ui';
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';
import classNames from 'classnames';
import { CrmAdsFields, CrmAdsFromFb, CrmAdsFromFbAll, CrmAdsFromGoogleAll, CrmAdsFromTk, CrmAdsFromTkAll, CrmGrossFields, CrmGrossFrom, DashboardGrossCustomAdSpendAggregate, DashboardGrossCustomAdSpendFilter, DashboardGrossCustomAdSpendFilterAll, DashboardGrossCustomDataAggregate, DashboardGrossCustomDataFilter, DashboardGrossCustomDataSort, DashboardGrossCustomRecurringFilter, DashboardGrossCustomRecurringGroupByPath } from './utils';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkPermission, Permissions } from '../../Permissions/Permissions';

type Props = {
    groupBy: DashboardGrossCustomGroupBy,
    config: DashboardGrossCustomConfig,
    reloadKey: number
    filter: DashboardGrossCustomFilter,
    loading: {
        get: boolean,
        set: (v: boolean) => void,
    }
}

export default function DashboardGrossCustomPivotAdSource(props: Props) {
    const { userState } = useTemplatesCore();
    const isAdmin = checkPermission(userState, Permissions.Enum.admin);
    
    const [doGrossPerfReq, grosPerfData] = MP.useMpDataRequest(CrmGrossFrom);
    const [doRecurringReq, recurringData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>('payment-recurring-report');

    const [doAdSpendFbReq, adSpendFbData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>(CrmAdsFromFb);
    const [doAdSpendTkReq, adSpendTkData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>(CrmAdsFromTk);

    const [doAdSpendFbAllReq, adSpendFbAllData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>(CrmAdsFromFbAll);
    const [doAdSpendTkAllReq, adSpendTkAllData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>(CrmAdsFromTkAll);
    const [doAdSpendGoogleAllReq, adSpendGoogleAllData] = MP.useMpDataRequest<DashboardGrossCustomAdSpendItem>(CrmAdsFromGoogleAll);
    
    const [data, setData] = useState<DashboardGrossCustomGrossPerfItem[]>([])

    const loadGrossPerformanceData = async () => {

        if (props.config.filters.ad_cost) {
                await doAdSpendFbReq({
                    limit: -1,
                    offset: 0,
                    aggregate: DashboardGrossCustomAdSpendAggregate,
                    columns: ['date'],
                    dataFilter: DashboardGrossCustomAdSpendFilter(props.config, props.filter),
                    fields: CrmAdsFields,
                })
                await doAdSpendTkReq({
                    limit: -1,
                    offset: 0,
                    aggregate: DashboardGrossCustomAdSpendAggregate,
                    columns: ['date'],
                    dataFilter: DashboardGrossCustomAdSpendFilter(props.config, props.filter),
                    fields: CrmAdsFields,
                })

                if (props.config.filters.ad_cost_substraction) {
                    await doAdSpendFbAllReq({
                        limit: -1,
                        offset: 0,
                        aggregate: DashboardGrossCustomAdSpendAggregate,
                        columns: ['date'],
                        dataFilter: DashboardGrossCustomAdSpendFilterAll(props.config, props.filter),
                        fields: CrmAdsFields,
                    })
                    await doAdSpendTkAllReq({
                        limit: -1,
                        offset: 0,
                        aggregate: DashboardGrossCustomAdSpendAggregate,
                        columns: ['date'],
                        dataFilter: DashboardGrossCustomAdSpendFilterAll(props.config, props.filter),
                        fields: CrmAdsFields,
                    })
                    await doAdSpendGoogleAllReq({
                        limit: -1,
                        offset: 0,
                        aggregate: DashboardGrossCustomAdSpendAggregate,
                        columns: ['date'],
                        dataFilter: DashboardGrossCustomAdSpendFilterAll(props.config, props.filter),
                        fields: CrmAdsFields,
                    })
                }
        }
        if (props.config.filters.recurring) {
            const groupByColumn = DashboardGrossCustomRecurringGroupByPath(props.groupBy);
            await doRecurringReq({
                limit: -1,
                offset: 0,
                aggregate: [
                    {
                        title: "",
                        function: 'countDistinct',
                        column: 'order_id'
                    },
                    {
                        title: "",
                        function: 'sum',
                        column: 'total'
                    }
                ],
                columns: [
                    groupByColumn
                ],
                dataFilter: DashboardGrossCustomRecurringFilter(props.config, props.filter),
                sort: [
                    {
                        "column": groupByColumn,
                        "direction": "ASC"
                    }
                ]
            })
        }

        if (props.config.filters.gross) {
            const res = await doGrossPerfReq({
                limit: -1,
                offset: 0,
                aggregate: DashboardGrossCustomDataAggregate,
                columns: [props.groupBy],
                dataFilter: DashboardGrossCustomDataFilter(props.config, props.filter),
                sort: DashboardGrossCustomDataSort(props.groupBy),
                fields: CrmGrossFields,
            })
            const _data: DashboardGrossCustomGrossPerfItem[] = res.data.data;
            _data.sort((a, b) => {
                if (a.report_item_payment.gross_total_sum > b.report_item_payment.gross_total_sum) {
                    return -1;
                }
                if (a.report_item_payment.gross_total_sum < b.report_item_payment.gross_total_sum) {
                    return 1;
                }
                return 0;
            })
            setData(_data);
        }
    }

    useEffect(() => {
        props.loading.set(
            grosPerfData.loading || adSpendFbData.loading || adSpendTkData.loading || recurringData.loading ||
            adSpendFbAllData.loading || adSpendTkAllData.loading || adSpendGoogleAllData.loading
        )
    }, [
        grosPerfData.loading, adSpendFbData.loading, adSpendTkData.loading, recurringData.loading,
        adSpendFbAllData, adSpendTkAllData, adSpendGoogleAllData
    ]);

    useEffect(() => {
        loadGrossPerformanceData().catch(console.error)
    }, [props.config, props.groupBy, props.reloadKey]);

    const tbl = Table.default;


    return (
        <Card.WhiteCard
            style={{
                body: {
                    paddingHorizontal: 'p-2'
                }
            }}
        >
            <tbl.Table
                thead={
                    <thead>
                        <tr className='sticky top-0 bg-white'>
                            <tbl.Th props={{ className: classNames('sticky left-0', borderBR, ' bg-white') }}>{""}</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>Initial</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Initial (subs)</tbl.Th>
                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>Initial (lifetime)</tbl.Th>
                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>Initial (upsells)</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Subscriptions</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Ad spend</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>CPA</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>AOV</tbl.Th>
                            <tbl.Th props={{ className: classNames('text-right', borderBottom) }}>ROAS</tbl.Th>

                            {isAdmin && <Fragment>
                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Recurring</tbl.Th>

                            <tbl.Th props={{ className: classNames('text-right', borderBL) }}>Gross performance</tbl.Th>
                            </Fragment>}
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {data.map(el => {
                            const id = getValueByPath(el, props.groupBy);

                            let fbAdSpend = id != 'Facebook' ? 0 : (adSpendFbData.data.map(a => a.total_sum).reduce((a, b) => a + b, 0));
                            let tkAdSpend = id != 'Tiktok' ? 0 : (adSpendTkData.data.map(a => a.total_sum).reduce((a, b) => a + b, 0));
                            let googleAdSpend = 0;

                            if (props.config.filters.ad_cost_substraction) {
                                const fbAllAdSpend = id != 'Facebook' ? 0 : (adSpendFbAllData.data.map(a => a.total_sum).reduce((a, b) => a + b, 0));;
                                const tkAllAdSpend =  id != 'Tiktok' ? 0 : (adSpendTkAllData.data.map(a => a.total_sum).reduce((a, b) => a + b, 0));
                                const googleAllAdSpend =  id != 'Google' ? 0 : (adSpendGoogleAllData.data.map(a => a.total_sum).reduce((a, b) => a + b, 0));

                                fbAdSpend = fbAllAdSpend - fbAdSpend;
                                tkAdSpend = tkAllAdSpend - tkAdSpend;
                                googleAdSpend = googleAllAdSpend - googleAdSpend;
                            }

                            const recurring = recurringData.data.filter(a => getValueByPath(a, DashboardGrossCustomRecurringGroupByPath(props.groupBy)) === id).map(a => a.total_sum).reduce((a, b) => a + b, 0);


                            const adSpend = fbAdSpend + tkAdSpend + googleAdSpend;

                            const cpa = el.id_count > 0 ? adSpend / el.id_count : adSpend;

                            const aov = el.id_count > 0 ? el.report_item_payment.gross_total_sum / el.id_count : 0;

                            const roas = adSpend ? el.report_item_payment.gross_total_sum / adSpend : 0;

                            return (
                                <tr key={`row-${id}`}>
                                    <tbl.Td props={{ className: classNames('sticky left-0', borderRight, ' bg-white') }}>{id}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(el.report_item_payment.gross_total_sum)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(el.report_item_payment.gross_initial_sub_total_sum - el.report_item_payment.gross_initial_lifetime_total_sum)}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(el.report_item_payment.gross_initial_lifetime_total_sum)}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(el.report_item_payment.gross_upsell_total_sum)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{intFormat(el.id_count)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(adSpend)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(cpa)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(aov)}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right') }}>{floatFormat(roas)}</tbl.Td>
                                    {isAdmin && <Fragment>
                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(recurring)}</tbl.Td>

                                    <tbl.Td props={{ className: classNames('text-right', borderLeft) }}>{floatFormat(el.report_item_payment.gross_total_sum + recurring - adSpend)}</tbl.Td>
                                    </Fragment>}

                                </tr>
                            )
                        })}
                        <TotalLine
                            data={data}
                            fbData={adSpendFbData.data}
                            tkData={adSpendTkData.data}

                            fbDataAll={adSpendFbAllData.data}
                            tkDataAll={adSpendTkAllData.data}
                            googleDataAll={adSpendGoogleAllData.data}
                            adCostSubstraction={props.config.filters.ad_cost_substraction}

                            recurringData={recurringData.data}
                        />
                    </tbody>
                }
            />
        </Card.WhiteCard>
    )
}

type TotalLineProps = {
    data: DashboardGrossCustomGrossPerfItem[],
    fbData: DashboardGrossCustomAdSpendItem[],
    tkData: DashboardGrossCustomAdSpendItem[],

    fbDataAll: DashboardGrossCustomAdSpendItem[],
    tkDataAll: DashboardGrossCustomAdSpendItem[],
    googleDataAll: DashboardGrossCustomAdSpendItem[],
    adCostSubstraction?: boolean,

    recurringData: DashboardGrossCustomAdSpendItem[],
}

const TotalLine = ({ data, fbData, tkData, recurringData, adCostSubstraction, fbDataAll, tkDataAll, googleDataAll }: TotalLineProps) => {
    const { userState } = useTemplatesCore();
    const isAdmin = checkPermission(userState, Permissions.Enum.admin);

    const tbl = Table.default;

    const allGrossTotalSum = data.map(el => el.report_item_payment.gross_total_sum).reduce((a, b) => a + b, 0)
    const allGrossInitialSubTotalSum = data.map(el => el.report_item_payment.gross_initial_sub_total_sum).reduce((a, b) => a + b, 0)
    const allGrossInitialLifetimeTotalSum = data.map(el => el.report_item_payment.gross_initial_lifetime_total_sum).reduce((a, b) => a + b, 0)
    const allGrossInitialUpsellTotalSum = data.map(el => el.report_item_payment.gross_upsell_total_sum).reduce((a, b) => a + b, 0)

    const allSubs = data.map(el => el.id_count).reduce((a, b) => a + b, 0)

    let fbAdSpend = fbData.map(a => a.total_sum).reduce((a, b) => a + b, 0);
    let tkAdSpend = tkData.map(a => a.total_sum).reduce((a, b) => a + b, 0);
    let googleAdSpend = 0;

    if (adCostSubstraction) {
        const fbAdSpendAll = fbDataAll.map(a => a.total_sum).reduce((a, b) => a + b, 0);
        const tkAdSpendAll = tkDataAll.map(a => a.total_sum).reduce((a, b) => a + b, 0);
        const googleAdSpendAll = tkDataAll.map(a => a.total_sum).reduce((a, b) => a + b, 0);

        fbAdSpend = fbAdSpendAll - fbAdSpend;
        tkAdSpend = tkAdSpendAll - tkAdSpend;
        googleAdSpend = googleAdSpendAll - googleAdSpend;
    }

    const adSpend = fbAdSpend + tkAdSpend + googleAdSpend;

    const cpa = allSubs > 0 ? adSpend / allSubs : adSpend;

    const aov = allSubs > 0 ? allGrossTotalSum / allSubs : 0;

    const roas = adSpend ? allGrossTotalSum / adSpend : 0;

    const recurring = recurringData.map(a => a.total_sum).reduce((a, b) => a + b, 0);

    return (
        <tr className='font-bold'>
            <tbl.Td props={{ className: classNames('sticky left-0', borderTR) }}>{"Total"}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(allGrossTotalSum)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(allGrossInitialSubTotalSum - allGrossInitialLifetimeTotalSum)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(allGrossInitialLifetimeTotalSum)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(allGrossInitialUpsellTotalSum)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{intFormat(allSubs)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(adSpend)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(cpa)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(aov)}</tbl.Td>
            <tbl.Td props={{ className: classNames('text-right', borderTop) }}>{floatFormat(roas)}</tbl.Td>

            {isAdmin && <Fragment>
            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(recurring)}</tbl.Td>

            <tbl.Td props={{ className: classNames('text-right', borderTL) }}>{floatFormat(allGrossTotalSum + recurring - adSpend)}</tbl.Td>
            </Fragment>}
        </tr>
    )
}

export const getValueByPath = (el: any, path: string) => {
    let value: any = "";
    try {
        value = path.split('.').reduce((previous: any, current: any) => previous[current], el);
    } catch (e) {
    }
    return value;
}

const floatFormat = (value: number) => numberFormat({
    userInput: value,
    decimals: 2,
    decPoint: '.',
    thousandsSep: ' '
})

const intFormat = (value: number) => numberFormat({
    userInput: value,
    decimals: 0,
    decPoint: '.',
    thousandsSep: ' '
})

const borderRight = 'shadow-[inset_-1px_0px_0px_0px_rgba(96,165,250,1)]';
const borderLeft = 'shadow-[inset_1px_0px_0px_0px_rgba(96,165,250,1)]';
const borderTL = 'shadow-[inset_1px_1px_0px_0px_rgba(96,165,250,1)]';
const borderTR = 'shadow-[inset_-1px_1px_0px_0px_rgba(96,165,250,1)]';
const borderTop = 'shadow-[inset_0px_1px_0px_0px_rgba(96,165,250,1)]';
const borderBottom = 'shadow-[inset_0px_-1px_0px_0px_rgba(96,165,250,1)]';
const borderBL = 'shadow-[inset_1px_-1px_0px_0px_rgba(96,165,250,1)]';
const borderBR = 'shadow-[inset_-1px_-1px_0px_0px_rgba(96,165,250,1)]';