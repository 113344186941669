import React, { Fragment, useEffect } from 'react'
import { Card, MP } from '@newageerp/crm-ui'
import { z } from "zod";

type Props = {
    id: number
}

export default function ReportItem(props: Props) {

    const Element = z.object({
        initial_total: z.number(),
        upsell_total: z.number(),
        recurring_total: z.number(),
        refund_total: z.number(),
    });


    const [getElement, { element }] = MP.useMpElementRequest<z.infer<typeof Element>>('crm.report_item');
    useEffect(() => {
        getElement({
            fields: Element.keyof().options,
            id: props.id
        })
    }, []);


    return (
        <Fragment>
                <Card.widget.NumberCardWidget
                    content={{
                        title: "Initial",
                        content: <Card.widget.NumberWithFraction value={element?.initial_total} currency='usd' />
                    }}
                    isCompact
                />
                <Card.widget.NumberCardWidget
                    content={{
                        title: "Upsell",
                        content: <Card.widget.NumberWithFraction value={element?.upsell_total} currency='usd' />
                    }}
                    isCompact
                />
                <Card.widget.NumberCardWidget
                    content={{
                        title: "Recurring",
                        content: <Card.widget.NumberWithFraction value={element?.recurring_total} currency='usd' />
                    }}
                    isCompact
                />
                <Card.widget.NumberCardWidget
                    content={{
                        title: "Refund",
                        content: <Card.widget.NumberWithFraction value={element?.refund_total} currency='usd' />
                    }}
                    isCompact
                />
        </Fragment>
    )
}
