import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { MainToolbarTitle, Table, Th, Td, TabContainer } from '@newageerp/v3.bundles.layout-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { SummaryComponent } from '@newageerp/v3.toolbar.summary-component';

type ConfigItem = {
    title: string,
    id: string,
}

export default function DashboardAdditionalSubs() {
    const { t } = useTranslation();


    const [lastReload, setLastReload] = useState('');
    // const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

    const [doReq, doReqParams] = useURequest<ConfigItem>("/app/plugins/SubPrefix/list");

    useEffect(() => {
        doReq();
    }, []);

    const defDates = {
        dateFrom: moment().subtract(1, 'month').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
    };

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }


    return (
        <Fragment>
            <MainToolbarTitle title={"Additional subscriptions"} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>


                    </div>
                </WhiteCard>

                <WhiteCard>
                    <TabContainer
                        items={doReqParams.data.data.map(item => {
                            return {
                                content: <DashboardConfig chartDates={chartDates} item={item} />,
                                tab: {
                                    children: item.title,
                                }
                            }
                        })}
                    />
                </WhiteCard>

            </div>
        </Fragment>
    )
}

type ConfigProps = {
    item: ConfigItem,
    chartDates: {
        dateFrom: string,
        dateTo: string,
    }
}

const DashboardConfig = (props: ConfigProps) => {
    const { chartDates, item, } = props;
    
    return (
        <SummaryComponent network={{
            url: '/app/plugins/SubPrefix/pivotData',
            type: 'post',
            data: {
                dateFrom: chartDates.dateFrom,
                dateTo: chartDates.dateTo,
                prefix: item.id
            }
        }} initConfigId={"sub-subsciptions"} />
    )
}