import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorAdCostNae = createSelector(orm.AdCostModel);
export const SelectorAdaloUserNae = createSelector(orm.AdaloUserModel);
export const SelectorCountryNae = createSelector(orm.CountryModel);
export const SelectorCountryGroupNae = createSelector(orm.CountryGroupModel);
export const SelectorCustomerNae = createSelector(orm.CustomerModel);
export const SelectorFrontendNae = createSelector(orm.FrontendModel);
export const SelectorKlavyioEventSyncNae = createSelector(orm.KlavyioEventSyncModel);
export const SelectorOrderItemNae = createSelector(orm.OrderItemModel);
export const SelectorOrderItemSkuGroupNae = createSelector(orm.OrderItemSkuGroupModel);
export const SelectorPaymentNae = createSelector(orm.PaymentModel);
export const SelectorPaymentRefundRequestNae = createSelector(orm.PaymentRefundRequestModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorQuizResultNae = createSelector(orm.QuizResultModel);
export const SelectorReportItemNae = createSelector(orm.ReportItemModel);
export const SelectorShippingOrderNae = createSelector(orm.ShippingOrderModel);
export const SelectorSubscriptionNae = createSelector(orm.SubscriptionModel);
export const SelectorSubscriptionCancelNae = createSelector(orm.SubscriptionCancelModel);
export const SelectorSubscriptionCancelReasonNae = createSelector(orm.SubscriptionCancelReasonModel);
export const SelectorSubscriptionCancelRequestNae = createSelector(orm.SubscriptionCancelRequestModel);
export const SelectorSubscriptionPaymentNae = createSelector(orm.SubscriptionPaymentModel);
export const SelectorSubscriptionPriceNae = createSelector(orm.SubscriptionPriceModel);
export const SelectorSubscriptionSupportReportNae = createSelector(orm.SubscriptionSupportReportModel);
export const SelectorUserNae = createSelector(orm.UserModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'AdCost') {
        return SelectorAdCostNae;
    }
        if (schema === 'AdaloUser') {
        return SelectorAdaloUserNae;
    }
        if (schema === 'Country') {
        return SelectorCountryNae;
    }
        if (schema === 'CountryGroup') {
        return SelectorCountryGroupNae;
    }
        if (schema === 'Customer') {
        return SelectorCustomerNae;
    }
        if (schema === 'Frontend') {
        return SelectorFrontendNae;
    }
        if (schema === 'KlavyioEventSync') {
        return SelectorKlavyioEventSyncNae;
    }
        if (schema === 'OrderItem') {
        return SelectorOrderItemNae;
    }
        if (schema === 'OrderItemSkuGroup') {
        return SelectorOrderItemSkuGroupNae;
    }
        if (schema === 'Payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'PaymentRefundRequest') {
        return SelectorPaymentRefundRequestNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'QuizResult') {
        return SelectorQuizResultNae;
    }
        if (schema === 'ReportItem') {
        return SelectorReportItemNae;
    }
        if (schema === 'ShippingOrder') {
        return SelectorShippingOrderNae;
    }
        if (schema === 'Subscription') {
        return SelectorSubscriptionNae;
    }
        if (schema === 'SubscriptionCancel') {
        return SelectorSubscriptionCancelNae;
    }
        if (schema === 'SubscriptionCancelReason') {
        return SelectorSubscriptionCancelReasonNae;
    }
        if (schema === 'SubscriptionCancelRequest') {
        return SelectorSubscriptionCancelRequestNae;
    }
        if (schema === 'SubscriptionPayment') {
        return SelectorSubscriptionPaymentNae;
    }
        if (schema === 'SubscriptionPrice') {
        return SelectorSubscriptionPriceNae;
    }
        if (schema === 'SubscriptionSupportReport') {
        return SelectorSubscriptionSupportReportNae;
    }
        if (schema === 'User') {
        return SelectorUserNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'ad-cost') {
        return SelectorAdCostNae;
    }
        if (schema === 'adalo-user') {
        return SelectorAdaloUserNae;
    }
        if (schema === 'country') {
        return SelectorCountryNae;
    }
        if (schema === 'country-group') {
        return SelectorCountryGroupNae;
    }
        if (schema === 'customer') {
        return SelectorCustomerNae;
    }
        if (schema === 'frontend') {
        return SelectorFrontendNae;
    }
        if (schema === 'klavyio-event-sync') {
        return SelectorKlavyioEventSyncNae;
    }
        if (schema === 'order-item') {
        return SelectorOrderItemNae;
    }
        if (schema === 'order-item-sku-group') {
        return SelectorOrderItemSkuGroupNae;
    }
        if (schema === 'payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'payment-refund-request') {
        return SelectorPaymentRefundRequestNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'quiz-result') {
        return SelectorQuizResultNae;
    }
        if (schema === 'report-item') {
        return SelectorReportItemNae;
    }
        if (schema === 'shipping-order') {
        return SelectorShippingOrderNae;
    }
        if (schema === 'subscription') {
        return SelectorSubscriptionNae;
    }
        if (schema === 'subscription-cancel') {
        return SelectorSubscriptionCancelNae;
    }
        if (schema === 'subscription-cancel-reason') {
        return SelectorSubscriptionCancelReasonNae;
    }
        if (schema === 'subscription-cancel-request') {
        return SelectorSubscriptionCancelRequestNae;
    }
        if (schema === 'subscription-payment') {
        return SelectorSubscriptionPaymentNae;
    }
        if (schema === 'subscription-price') {
        return SelectorSubscriptionPriceNae;
    }
        if (schema === 'subscription-support-report') {
        return SelectorSubscriptionSupportReportNae;
    }
        if (schema === 'user') {
        return SelectorUserNae;
    }
    }