import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

type RevenueItem = {
    days: number,
    plan: string,
    t: number
}

type UserItem = {
    days: number,
    plan: string,
    u: number
}

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

type Props = {
    type: 'DAY' | 'WEEK' | 'MONTH',
}

const titles = {
    DAY: "By day",
    WEEK: "By week",
    MONTH: "By month",
}

export default function Chart(props: Props) {
    const [ltvDataChart, setLtvDataChart] = useState<any[]>([]);
    const [ltvRevenueChart, setLtvRevenueChart] = useState<any[]>([]);
    const [usersChart, setUsersChart] = useState<any[]>([]);

    const [plans, setPlans] = useState<string[]>([]);

    const getData = async () => {
        const res = await axios.post(
            '/app/plugins/ProductPerformance/tmpLtvByDays',
            {
                type: props.type
            }
        )
        const data: {
            users: UserItem[],
            revenue: RevenueItem[]
        } = res.data.data;

        const userDaysMax = Math.max(...data.users.map(u => u.days));
        const revenueDaysMax = Math.max(...data.revenue.map(u => u.days));
        const daysMax = Math.max(userDaysMax, revenueDaysMax);

        const plans = data.revenue.map(u => u.plan).filter((value, index, array) => array.indexOf(value) === index);

        const ltvData = [];
        const ltvDataCum = [];
        const usersData = [];

        for (let currentDay = 0; currentDay <= daysMax; currentDay++) {
            const el: any = {
                days: currentDay
            };
            for (const plan of plans) {
                const usersOnDays = data.users.filter(u => u.plan === plan).filter(u => u.days >= currentDay).map(u => u.u).reduce((a, b) => a + b, 0);
                const revenueOnDay = data.revenue.filter(r => r.plan === plan).filter(r => r.days === currentDay).map(u => u.t).reduce((a, b) => a + b, 0);
                el[plan] = usersOnDays > 0 ? roundNum(revenueOnDay / usersOnDays) : 0
            }
            ltvData.push(el);
        }

        for (let currentDay = 0; currentDay <= daysMax; currentDay++) {
            const el: any = {
                days: currentDay
            };
            for (const plan of plans) {
                const usersOnDays = data.users.filter(u => u.plan === plan).filter(u => u.days >= currentDay).map(u => u.u).reduce((a, b) => a + b, 0);
                if (usersOnDays > 0) {
                    const ltvOnDays = ltvData.filter(u => u.days <= currentDay).map(u => u[plan]).reduce((a, b) => a + b, 0);
                    el[plan] = roundNum(ltvOnDays);
                }
            }
            ltvDataCum.push(el);
        }

        for (let currentDay = 0; currentDay <= daysMax; currentDay++) {
            const el: any = {
                days: currentDay
            };
            for (const plan of plans) {
                const usersOnDays = data.users.filter(u => u.plan === plan).filter(u => u.days >= currentDay).map(u => u.u).reduce((a, b) => a + b, 0);
                if (usersOnDays > 0) {
                    el[plan] = usersOnDays;
                }
            }
            usersData.push(el);
        }

        setPlans(plans);
        setLtvDataChart(ltvDataCum);
        setLtvRevenueChart(ltvData);
        setUsersChart(usersData);
    }

    useEffect(() => {
        getData().catch(console.error);
    }, []);

    return (
        <div className='space-y-1'>
            <p className='font-medium text-lg'>{titles[props.type]}</p>
            {ltvDataChart.length > 0 && <Fragment>

                <ResponsiveContainer width='100%' height={600}>

                    <LineChart data={ltvDataChart} title='Cumulative LTV per customer, USD'>
                        <XAxis dataKey="days" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        {plans.map((p, idx) => <Line dot={false} key={p} type="monotone" dataKey={p} stroke={colors[idx]} />)}
                    </LineChart>

                </ResponsiveContainer>

            </Fragment>}
        </div>
    )
}


const roundNum = (n: number, p: number = 2) => (e => Math.round(n * e) / e)(Math.pow(10, p))
