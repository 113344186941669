'use client'

import React, { Fragment, useEffect, useState } from 'react'
import { SFSForm } from '@newageerp/v3.templates.json-form'
import validator from '@rjsf/validator-ajv8'
import { TabContainer } from '@newageerp/v3.bundles.layout-bundle'
import { WhiteCard } from '@newageerp/v3.widgets.white-card'
import { useParams } from '@newageerp/v3.templates.templates-core'
import { AbSegmentSetAdminSchema, AbVarConfigSchema } from './schema'
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle'

type AbSegment = {
	type:
		| 'path'
		| 'url param'
		| 'country'
		| 'browserName'
		| 'mobileVendor'
		| 'mobileModel'
		| 'deviceType'
		| 'osName'
	param?: string
	comparison: 'equals' | 'contains' | 'begins' | 'ends'
	value: string
}

type AbSegmentSet = {
	values: AbSegment[]
	segmentId: string
}

export default function AbTestVarsForm() {
    const routeParams = useParams<{ project: string }>();

    const [config, setConfig] = useState<any>();
    const [enumsData, setEnumsData] = useState<any>()

    const loadConfig = async () => {
        if (routeParams.project) {
            const res = await axiosInstance.post('/app/nae-core/sf-config/getUserConfig', { config: 'abtest-vars' })
            if (!!res.data && !!res.data.success) {
                const data = res.data.data.find((el: any) => el.project === routeParams.project);

                if (data) {
                    const resEnums = await fetch(`${data.enumsUrl}/api/enums-vars`, {
                        method: 'POST'
                    })
                    const resEnumsJson = await resEnums.json();

                    setEnumsData(resEnumsJson.data);
                    setConfig(data);
                }
            }
        }
    }
    useEffect(() => {
        setEnumsData(undefined);
        setConfig(undefined);
        loadConfig().catch(console.error);
    }, [routeParams.project])

    if (!config) {
        return <Fragment />
    }
    
    return <AbTestVarsFormInner
        project={routeParams.project}
        adminUrl={config.dataUrl}
        enumData={enumsData}
    />
}

function AbTestVarsFormInner(props: {
    project: string,
    adminUrl: string,
    enumData: {
        [key: string]: string[]
    }
}) {
    const {project, adminUrl} = props;

    console.log('AdminItem')
    const getItem = async (slug: string) => {
        const res = await fetch(`${adminUrl}/funnel/get`, {
            body: JSON.stringify({
                slug,
                project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const resJson = await res.json()
        return resJson?.data?.templates
    }
    const updateItem = async (slug: string, formData: any) => {
        await fetch(`${adminUrl}/funnel/updateTemplates`, {
            body: JSON.stringify({
                slug,
                templates: formData,
                project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    useEffect(() => {
        console.log('TEST')
    }, [])

    return (
        <Fragment>
            <WhiteCard>
                <TabContainer
                    items={[
                        {
                            tab: {
                                children: 'Segments',
                            },
                            content: (
                                <SegmentsForm
                                    getItem={getItem}
                                    updateItem={updateItem}
                                    enumData={props.enumData}
                                />
                            ),
                        },
                        {
                            tab: {
                                children: 'Splits',
                            },
                            content: (
                                <SplitForm
                                    getItem={getItem}
                                    updateItem={updateItem}
                                    enumData={props.enumData}
                                />
                            ),
                        }
                    ]}
                />
            </WhiteCard>
        </Fragment>
    )
}

type TabProps = {
    getItem: (slug: string) => any
    updateItem: (slug: string, formData: any) => void
    enumData: {
        [key: string]: string[]
    }
}
const SegmentsForm = (props: TabProps) => {
    const [data, setData] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    const slug = 'segments'

    useEffect(() => {
        const asyncF = async () => {
            const _data = await props.getItem(slug)
            if (_data) {
                setData(_data)
            }
            setIsLoaded(true)
        }
        asyncF().catch(console.error)
    }, [])

    return (
        <div className={'w-[600px]'}>
            {isLoaded && (
                <SFSForm
                    schema={AbSegmentSetAdminSchema}
                    validator={validator}
                    onSubmit={(e) => props.updateItem(slug, e.formData)}
                    formData={data}
                />
            )}
        </div>
    )
}

const SplitForm = (props: TabProps) => {
    const [data, setData] = useState()
    const [segments, setSegments] = useState<string[]>([])
    const [isLoaded, setIsLoaded] = useState(false)

    const slug = 'splits'

    let properties: any = {}
    
    const abKeys = Object.keys(props.enumData)

    for (const abKey of abKeys) {
        properties[abKey] = {
            ...AbVarConfigSchema(props.enumData, abKey),
            title: abKey,
        }
    }

    let schema = {
        definitions: {
            segments: {
                enumNames: segments,
                enum: segments,
            },
        },
        type: 'object',
        properties,
    }

    useEffect(() => {
        const asyncF = async () => {
            const _segmentsData = await props.getItem('segments')
            if (_segmentsData) {
                setSegments(['all', ..._segmentsData.segments.map((s: AbSegmentSet) => s.segmentId)])
            }

            const _data = await props.getItem(slug)
            if (_data) {
                setData(_data)
            }
            setIsLoaded(true)
        }
        asyncF().catch(console.error)
    }, [])

    return (
        <div className={'w-[600px]'}>
            {isLoaded && (
                <SFSForm
                    schema={schema}
                    validator={validator}
                    onSubmit={(e) => props.updateItem(slug, e.formData)}
                    formData={data}
                />
            )}
        </div>
    )
}


