import { MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import DashboardGrossCustomPivot from './DashboardGrossCustomPivot';
import DashboardGrossCustomPivotAdSource from './DashboardGrossCustomPivotAdSource';
import DashboardGrossCustomPivotFunnel from './DashboardGrossCustomPivotFunnel';

export type DashboardGrossCustomGroupBy = 'date' | 'country' | 'slug' | 'report_item_ad_source.ad_source_level1' | 'report_item_ad_source.ad_source_level1_adopted';

export const DashboardGrossCustomTabs: {
    type: DashboardGrossCustomGroupBy,
    title: string,
    comp: any,
}[] = [
        {
            type: 'date',
            title: "Date",
            comp: DashboardGrossCustomPivot,
        },
        {
            type: 'country',
            title: "Country",
            comp: DashboardGrossCustomPivot,
        },
        {
            type: 'slug',
            title: "Funnel",
            comp: DashboardGrossCustomPivotFunnel,
        },
        {
            type: 'report_item_ad_source.ad_source_level1',
            title: "Ad Source",
            comp: DashboardGrossCustomPivotAdSource,
        },
        {
            type: 'report_item_ad_source.ad_source_level1_adopted',
            title: "Ad Source (adopted)",
            comp: DashboardGrossCustomPivotAdSource,
        }
    ]

export type DashboardGrossCustomConfig = {
    title: string,
    type: string,
    filters: {
        gross?: MpFilterFactorySetUniversalItem,
        ad_cost?: MpFilterFactorySetUniversalItem,
        ad_cost_substraction?: true,
        analytics?: MpFilterFactorySetUniversalItem,
        recurring?: MpFilterFactorySetUniversalItem,
    }
}

export type DashboardGrossCustomFilter = {
    countries: string[],
    funnels: string[],
    adSources: string[],
    date: {
        dateFrom: string,
        dateTo: string,
    }
}

export type DashboardGrossCustomGrossPerfItem = {
    date?: string,
    report_item_payment: {
        gross_total_sum: number,
        gross_initial_sub_total_sum: number,
        gross_initial_lifetime_total_sum: number,
        gross_upsell_total_sum: number,
    },
    id_count: number,
}

export type DashboardGrossCustomAdSpendItem = {
    date: string,
    total_sum: number,
}
