import React, { useEffect, useState } from 'react'
import RealtimeV3FilterSelect from './RealtimeV3FilterSelect'
import { Button, Form, MP } from '@newageerp/crm-ui';
import { RealtimeV3DataSourceConfig } from './types';
import RealtimeV3Grouping from './RealtimeV3Grouping';
import { MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'

type Props = {
    onAdd: (c: RealtimeV3DataSourceConfig) => void,
    config?: RealtimeV3DataSourceConfig
}

export default function RealtimeV3ConfigManagement(props: Props) {
    const { dataSource } = MP.useMpInfo();

    const defConfig: RealtimeV3DataSourceConfig = {
        rows: [],
        filter: undefined,
        dataFilters: [],
        title: '',
    };
    const [config, setConfig] = useState<RealtimeV3DataSourceConfig>(props.config ? {...props.config} : defConfig);

    const onAdd = () => {
        if (!config.filter) return false;

        const dataFilters: MpFilterFactorySetUniversalItem[] = [
            {
                "comparison": "and",
                "filters": [
                    config.filter.filter[0].filters[0]
                ]
            },
        ];

        props.onAdd({ ...config, dataFilters })
        setConfig(defConfig);
    }
    return (
        <div className='text-sm space-y-2'>
            <RealtimeV3FilterSelect
                dataSource={dataSource}
                activeFilter={config.filter}
                setActiveFilter={(f) => setConfig({ ...config, filter: f })}
            />
            <RealtimeV3Grouping
                items={config.rows}
                setItems={r => setConfig({ ...config, rows: r })}

            />
            <div className='flex items-start gap-2'>
                <p className='uppercase'>Step 3:</p>
                <p className='text-slate-500 w-[150px]'>name it</p>
                <div>
                    <Form.TextInput value={config.title} onChange={e => setConfig({ ...config, title: e.target.value })} />
                </div>
            </div>

            <div className='flex items-start gap-2'>
                <p className='uppercase'>Step 4:</p>
                <p className='text-slate-500 w-[150px]'>ready, steady</p>
                <div>
                    <Button.ToolbarButton
                        disabled={!config.filter}
                        iconName='chart-line'
                        onClick={onAdd}>Go!</Button.ToolbarButton>
                </div>
            </div>

        </div>
    )
}
