import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { Fragment, useEffect, useState } from "react";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const keys = [
    'country',
    'ip',
    'origin',
    'slug',
    'useragent'
]

type Props = {
    orderId: string
}
export const ReportItemSession = (props: Props) => {
    const [jsonData, setJsonData] = useState();

    const getData = async () => {
        const url = '/app/plugins/CrmSession/getSession';
        const res = await fetch(
            url,
            {
                method: 'POST',
                body: JSON.stringify({
                    orderId: props.orderId
                }),
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        const resJson = await res.json();

        setJsonData(resJson.data);
    }

    useEffect(() => {
        getData().catch(console.error);
    }, [props.orderId])

    if (!jsonData) {
        return <Fragment />
    }

    return <div className="space-y-2">
        <Table
            thead={
                <thead>
                    <tr>
                        <Th className="w-[300px]">Key</Th>
                        <Th>Value</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {keys.map(k => <tr key={k}><Td>{k}</Td><Td>{jsonData[k]}</Td></tr>)}
                    <tr>
                        <Td>more...</Td>
                        <Td>
                        <JsonView data={jsonData} shouldExpandNode={() => false} style={defaultStyles} />
                        </Td>
                    </tr>
                </tbody>
            }
        />

        
    </div>
}