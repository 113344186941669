import React, { Fragment } from 'react'
import KlavyioEventSync from './Components/KlavyioEventSync'
import ReporItem from './Components/ReporItem'

type Props = {
    id: number
    dataSource: string
    element: any
}

export default function ResolverElementToolbarMoreMenu(props: Props) {
    return (
        <Fragment>
            {props.dataSource === 'klavyio-event-sync' && <KlavyioEventSync id={props.id}/>}
            {props.dataSource === 'report-item' && <ReporItem id={props.id} element={props.element}/>}

        </Fragment>
    )
}
