import { Compact, CompactRow, FieldLabel, FieldSelectMulti } from '@newageerp/v3.bundles.form-bundle';
import React from 'react'
import { useTranslation } from 'react-i18next';

type Props = {
    countries: string[],
    setCountries: (v: string[]) => void,
    countriesData: CountryFilterCountry[],
}

export default function CountryFilter(props: Props) {
    const { t } = useTranslation();
    const { countries, setCountries, countriesData } = props;

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Country')}</FieldLabel>}
                control={
                    <FieldSelectMulti
                        value={countries}
                        onChange={setCountries}
                        asString={true}
                        options={
                            countriesData.map((i: any) => {
                                return (
                                    {
                                        label: i.name,
                                        value: i.name,
                                    }
                                )
                            })
                        }
                    />
                }
                controlClassName='max-w-[500px]'
            />
        </Compact>
    )
}

export type CountryFilterCountry = {
    id: number,
    name: string,
}