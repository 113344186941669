import React, { useState } from 'react'
import validator from '@rjsf/validator-ajv8'
import { SFSForm } from '@newageerp/v3.templates.json-form';

export default function TabConfig() {
    const [data, setData] = useState({})
    return (
        <div>
            <SFSForm
                schema={schema}
                validator={validator}
                onSubmit={(e) => {
                    setData(e.formData)
                }}
                formData={data}
            />
        </div>
    )
}

const schema = {
    type: 'object',

    properties: {
        schema: { type: 'string', title: 'Schema', readOnly: true },
        type: { type: 'string', title: 'Type', readOnly: true },
        title: { type: 'string', title: 'Title', readOnly: true },
        tabGroup: { type: 'string', title: 'Tab group', readOnly: true },
        columns: {
            type: "array",
            title: "Columns",
            items: {
                type: 'object',
                properties: {
                    path: { type: 'string', title: 'Path' },
                    link: { type: 'number', title: "Link", enum: [0, 10, 20], enumNames: ['none', 'main', 'popup'] }
                }
            }
        }
    },
}
