import React from 'react'
import { Card } from '@newageerp/crm-ui'
type Props = {
  export: string,
}

export default function SfExploreDataExport(props: Props) {
  return (
    <Card.WhiteCard header={{ title: "Export" }}>
      <pre>{props.export}</pre>
    </Card.WhiteCard>
  )
}
