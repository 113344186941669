import React, { Fragment } from 'react'
import PaymentRefundRequest from './Components/PaymentRefundRequest'

type Props = {
    id: number
    dataSource: string
    element: any
}

export default function ResolverElementToolbarAfterFields(props: Props) {
    return (
        <Fragment>
            {props.dataSource === 'payment-refund-request' && <PaymentRefundRequest />}
        </Fragment>
    )
}
