import { StrictRJSFSchema } from '@rjsf/utils';

const AbSegmentSchema: StrictRJSFSchema = {
	type: 'object',
	title: 'Filter',
	properties: {
		type: {
			type: 'string',
			title: 'Type',
			enum: [
				'path',
				'url param',
				'country',
				'browserName',
				'mobileVendor',
				'mobileModel',
				'deviceType',
				'osName',
			],
		},
		param: {
			type: 'string',
			title: 'Param (when type = url param)',
		},
		comparison: {
			type: 'string',
			title: 'Comparison',
			enum: ['equals', 'contains', 'begins', 'ends'],
		},
		value: {
			type: 'string',
			title: 'Value',
		},
	},
};
const AbSegmentSetSchema: StrictRJSFSchema = {
	type: 'object',
	title: 'Segment',
	properties: {
		segmentId: {
			type: 'string',
			title: 'Segment ID',
		},
		values: {
			type: 'array',
			title: 'Filters',
			items: AbSegmentSchema,
		},
	},
};

export const AbSegmentSetAdminSchema: StrictRJSFSchema = {
	type: 'object',
	properties: {
		segments: {
			type: 'array',
			title: ' ',
			items: AbSegmentSetSchema,
		},
	},
};

const AbSplitValueSchema = (enums: {[key: string]: string[]}, abKey: string): StrictRJSFSchema => {
	return {
		type: 'object',
		properties: {
			percent: {
				type: 'string',
				title: 'Percent',
			},
			variant: {
				type: 'string',
				title: 'Variant',
				enum: abKey in enums ? enums[abKey] : undefined,
			},
		},
	};
};
const AbSplitValueSetSchema = (enums: {[key: string]: string[]}, abKey: string): StrictRJSFSchema  => {
	return {
		type: 'object',
		title: 'Split',
		properties: {
			name: {
				type: 'string',
				title: 'Split ID',
			},
			segments: {
				title: 'Segments',
				type: 'array',
				uniqueItems: true,
				items: {
					$ref: '#/definitions/segments',
				},
			},
			values: {
				type: 'array',
				title: 'Split',
				items: AbSplitValueSchema(enums, abKey),
			},
		},
	};
};
export const AbVarConfigSchema = (enums: {[key: string]: string[]}, abKey: string): StrictRJSFSchema => {
	return {
		type: 'object',
		title: 'Configuration',
		properties: {
			urlAliases: {
				type: 'array',
				title: 'URL aliases',
				items: { type: 'string', title: 'Item' },
			},
			splits: {
				type: 'array',
				title: 'Splits',
				items: AbSplitValueSetSchema(enums, abKey),
			},
		},
	};
};
