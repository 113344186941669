import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import { MainToolbarTitle, Table, Th, Td, TabContainer } from '@newageerp/v3.bundles.layout-bundle'
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import { useUList } from '@newageerp/v3.bundles.hooks-bundle';

export default function DashboardRealtimeV2Newsletter() {
    const { settings } = useUIBuilder();
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    const [uuids, setUUIDs] = useState<any>(['---']);

    const [lastReload, setLastReload] = useState('');
    // const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

    const [paymentsReq, paymentsReqData] = useUList('report-item', ['orderId', 'initialTotal', 'upsellTotal']);

    const defDates = {
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
    };

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const loadData = async () => {

        const sql = `(
            SELECT
            count(*) as c,
            '${settings.analytics.map['Checkout']}' as type,
            utm_medium,
            utm_campaign
            
            from an_event_last_source
            
            where an_event_last_source.utm_source in ('klaviyo', 'newsletter')
            and an_event_last_source.project = '${settings.analytics.project}'
            and an_event_last_source.createdAt >= toDateTime('${chartDates.dateFrom} 00:00:00', 'America/Phoenix')
            and an_event_last_source.createdAt <= toDateTime('${chartDates.dateTo} 23:59:59', 'America/Phoenix')

            GROUP BY utm_medium, utm_campaign
            )
            UNION ALL
            
            (
            SELECT
            count(distinct an_event_last_source.orderId) as c,
            '${settings.analytics.map['Purchase']}' as type,
            last_value(utm_medium) as utm_medium,
            last_value(utm_campaign) as utm_campaign

            from an_event_last_source
                left JOIN an_event on an_event.sessionUUID = an_event_last_source.orderId and an_event.type = '${settings.analytics.map['Purchase']}'
            where an_event_last_source.utm_source in ('klaviyo', 'newsletter')
            and an_event_last_source.project = '${settings.analytics.project}'
            and an_event.sessionUUID is not null
            and an_event.createdAt > an_event_last_source.createdAt
            and an_event.createdAt >= toDateTime('${chartDates.dateFrom} 00:00:00', 'America/Phoenix')
            and an_event.createdAt <= toDateTime('${chartDates.dateTo} 23:59:59', 'America/Phoenix')
            
            GROUP BY an_event_last_source.orderId
            )`;

        // console.log({sql})

        const res = await fetch(
            'https://an.apidata.app/api/crmStatsSql',
            {
                method: 'POST',
                body: JSON.stringify({ sql }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        const resJson = await res.json();
        setData(resJson.data ? resJson.data : []);

        setLastReload(moment().format('YYYY-MM-DD HH:mm'));

        const sqlUUIDs = `
            SELECT
            distinct an_event.sessionUUID as sessionUUID,
            utm_medium,
            utm_campaign

            from an_event_last_source
                left JOIN an_event on an_event.sessionUUID = an_event_last_source.orderId and an_event.type = '${settings.analytics.map['Purchase']}'
            where an_event_last_source.utm_source in ('klaviyo', 'newsletter')
            and an_event_last_source.project = '${settings.analytics.project}'
            and an_event.sessionUUID is not null
            and an_event_last_source.createdAt >= toDateTime('${chartDates.dateFrom} 00:00:00', 'America/Phoenix')
            and an_event_last_source.createdAt <= toDateTime('${chartDates.dateTo} 23:59:59', 'America/Phoenix')
            
            `;

        const resUUIDs = await fetch(
            'https://an.apidata.app/api/crmStatsSql',
            {
                method: 'POST',
                body: JSON.stringify({ sql: sqlUUIDs }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        const resJsonUUIDs = await resUUIDs.json();
        setUUIDs(resJsonUUIDs.data ? resJsonUUIDs.data : [{ 'sessionUUID': '---' }]);
    }

    useEffect(() => {
        loadData();
    }, [chartDates]);

    useEffect(() => {
        const t = setInterval(() => {
            // loadData();
            loadData();
        }, 60 * 5 * 1000);
        return () => {
            clearInterval(t);
        }
    }, [chartDates]);

    useEffect(() => {
        if (uuids.length > 0) {
            paymentsReq(
                [{
                    "and": [
                        ["i.orderId", "in", uuids.map((u: any) => u.sessionUUID), true]
                    ]
                }],
                1,
                9999,
                [{ key: 'i.id', value: 'ASC' }]
            )
        }
    }, [uuids])

    const uuidsF = uuids.map((el: any) => ({
        ...el,
        total: paymentsReqData.data.data.filter((i: any) => i.orderId === el.sessionUUID).map((e: any) => e.initialTotal + e.upsellTotal).reduce((a, b) => a + b, 0)
    }))

    const groupedByMediumUUIDs = groupMap(uuidsF, (el) => el.utm_medium)
    const groupedByMedium = groupMap(data, (el) => el.utm_medium)
    const mediums = Object.keys(groupedByMedium);

    const mediumsData: any = [];
    mediums.forEach(m => {
        const el = {
            v: m,
            views: groupedByMedium[m].filter((el: any) => el.type === settings.analytics.map['Checkout']).map((el: any) => el.c).reduce((a: number, b: number) => a + b, 0),
            purchases: groupedByMedium[m].filter((el: any) => el.type === settings.analytics.map['Purchase']).map((el: any) => el.c).reduce((a: number, b: number) => a + b, 0),
            totals: (m in groupedByMediumUUIDs) ? groupedByMediumUUIDs[m].map((el: any) => el.total).reduce((a: number, b: number) => a + b, 0) : 0,
        };
        mediumsData.push(el);
    })

    const groupedByCampaignUUIDs = groupMap(uuidsF, (el) => el.utm_campaign)
    const groupedByCampaign = groupMap(data, (el) => el.utm_campaign)
    const campaigns = Object.keys(groupedByCampaign);

    const campaignsData: any = [];
    campaigns.forEach(m => {
        const el = {
            v: m,
            views: groupedByCampaign[m].filter((el: any) => el.type === settings.analytics.map['Checkout']).map((el: any) => el.c).reduce((a: number, b: number) => a + b, 0),
            purchases: groupedByCampaign[m].filter((el: any) => el.type === settings.analytics.map['Purchase']).map((el: any) => el.c).reduce((a: number, b: number) => a + b, 0),
            totals: (m in groupedByCampaignUUIDs) ? groupedByCampaignUUIDs[m].map((el: any) => el.total).reduce((a: number, b: number) => a + b, 0) : 0,
        };
        campaignsData.push(el);
    })

    const groupedByCampaignMediumUUIDs = groupMap(uuidsF, (el) => `${el.utm_campaign} (${el.utm_medium})`)
    const groupedByCampaignMedium = groupMap(data, (el) => `${el.utm_campaign} (${el.utm_medium})`)
    const campaignMediums = Object.keys(groupedByCampaignMedium);

    const campaignMediumsData: any = [];
    campaignMediums.forEach(m => {
        const el = {
            v: m,
            views: groupedByCampaignMedium[m].filter((el: any) => el.type === settings.analytics.map['Checkout']).map((el: any) => el.c).reduce((a: number, b: number) => a + b, 0),
            purchases: groupedByCampaignMedium[m].filter((el: any) => el.type === settings.analytics.map['Purchase']).map((el: any) => el.c).reduce((a: number, b: number) => a + b, 0),
            totals: (m in groupedByCampaignMediumUUIDs) ? groupedByCampaignMediumUUIDs[m].map((el: any) => el.total).reduce((a: number, b: number) => a + b, 0) : 0,
        };
        campaignMediumsData.push(el);
    })

    return (
        <Fragment>
            <MainToolbarTitle title={lastReload} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>

                        <div>
                            <MainButton onClick={loadData} iconName='repeat' color='sky'>
                                Refresh
                            </MainButton>
                        </div>
                    </div>
                </WhiteCard>

                <WhiteCard>
                    <TabContainer
                        items={[
                            {
                                content:
                                    <Table
                                        thead={
                                            <thead>
                                                <tr>
                                                    <Th>Medium / campaign</Th>
                                                    <Th textAlignment='text-right'>View</Th>
                                                    <Th textAlignment='text-right'>Purchase</Th>
                                                    <Th textAlignment='text-right'>CR</Th>
                                                    <Th textAlignment='text-right'>Gross, USD</Th>
                                                </tr>
                                            </thead>
                                        }
                                        tbody={
                                            <tbody>
                                                <tr className='total-row font-bold'>
                                                    <Td></Td>
                                                    <Td textAlignment='text-right'>{campaignMediumsData.map((el: any) => el.views).reduce((a: number, b: number) => a + b, 0).toFixed(0)}</Td>
                                                    <Td textAlignment='text-right'>{campaignMediumsData.map((el: any) => el.purchases).reduce((a: number, b: number) => a + b, 0).toFixed(0)}</Td>
                                                    <Td textAlignment='text-right'></Td>
                                                    <Td textAlignment='text-right'>{campaignMediumsData.map((el: any) => el.totals).reduce((a: number, b: number) => a + b, 0).toFixed(2)}</Td>
                                                </tr>
                                                {campaignMediumsData.sort((a: any, b: any) => {
                                                    if (a.purchases > b.purchases) {
                                                        return -1;
                                                    }
                                                    if (a.purchases < b.purchases) {
                                                        return 1;
                                                    }
                                                    if (a.views > b.views) {
                                                        return -1;
                                                    }
                                                    if (a.views < b.views) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((el: any) => {

                                                    return <tr key={`${el.v}-${chartDates.dateFrom}`}>
                                                        <Td>{el.v}</Td>
                                                        <Td textAlignment='text-right'>{el.views.toFixed(0)}</Td>
                                                        <Td textAlignment='text-right'>{el.purchases.toFixed(0)}</Td>
                                                        <Td textAlignment='text-right'>{el.views > 0 ? (el.purchases / el.views * 100).toFixed(2) : "-"} %</Td>
                                                        <Td textAlignment='text-right'>{el.totals.toFixed(2)}</Td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        }
                                    />
                                ,
                                tab: {
                                    children: "UTM campaign (UTM medium)"
                                }
                            },
                            {
                                content:
                                    <Table
                                        thead={
                                            <thead>
                                                <tr>
                                                    <Th>Medium</Th>
                                                    <Th textAlignment='text-right'>View</Th>
                                                    <Th textAlignment='text-right'>Purchase</Th>
                                                    <Th textAlignment='text-right'>CR</Th>
                                                    <Th textAlignment='text-right'>Gross, USD</Th>
                                                </tr>
                                            </thead>
                                        }
                                        tbody={
                                            <tbody>
                                                <tr className='total-row font-bold'>
                                                    <Td></Td>
                                                    <Td textAlignment='text-right'>{mediumsData.map((el: any) => el.views).reduce((a: number, b: number) => a + b, 0).toFixed(0)}</Td>
                                                    <Td textAlignment='text-right'>{mediumsData.map((el: any) => el.purchases).reduce((a: number, b: number) => a + b, 0).toFixed(0)}</Td>
                                                    <Td textAlignment='text-right'></Td>
                                                    <Td textAlignment='text-right'>{mediumsData.map((el: any) => el.totals).reduce((a: number, b: number) => a + b, 0).toFixed(2)}</Td>
                                                </tr>
                                                {mediumsData.sort((a: any, b: any) => {
                                                    if (a.purchases > b.purchases) {
                                                        return -1;
                                                    }
                                                    if (a.purchases < b.purchases) {
                                                        return 1;
                                                    }
                                                    if (a.views > b.views) {
                                                        return -1;
                                                    }
                                                    if (a.views < b.views) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((el: any) => {
                                                    return <tr key={`${el.v}-${chartDates.dateFrom}`}>
                                                        <Td>{el.v}</Td>
                                                        <Td textAlignment='text-right'>{el.views.toFixed(0)}</Td>
                                                        <Td textAlignment='text-right'>{el.purchases.toFixed(0)}</Td>
                                                        <Td textAlignment='text-right'>{el.views > 0 ? (el.purchases / el.views * 100).toFixed(2) : "-"} %</Td>
                                                        <Td textAlignment='text-right'>{el.totals.toFixed(2)}</Td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        }
                                    />
                                ,
                                tab: {
                                    children: "UTM medium"
                                }
                            },
                            {
                                content: <Table
                                    thead={
                                        <thead>
                                            <tr>
                                                <Th>Campaign</Th>
                                                <Th textAlignment='text-right'>View</Th>
                                                <Th textAlignment='text-right'>Purchase</Th>
                                                <Th textAlignment='text-right'>CR</Th>
                                                <Th textAlignment='text-right'>Gross, USD</Th>
                                            </tr>
                                        </thead>
                                    }
                                    tbody={
                                        <tbody>
                                            <tr className='total-row font-bold'>
                                                <Td></Td>
                                                <Td textAlignment='text-right'>{campaignsData.map((el: any) => el.views).reduce((a: number, b: number) => a + b, 0).toFixed(0)}</Td>
                                                <Td textAlignment='text-right'>{campaignsData.map((el: any) => el.purchases).reduce((a: number, b: number) => a + b, 0).toFixed(0)}</Td>
                                                <Td textAlignment='text-right'></Td>
                                                <Td textAlignment='text-right'>{campaignsData.map((el: any) => el.totals).reduce((a: number, b: number) => a + b, 0).toFixed(2)}</Td>
                                            </tr>
                                            {campaignsData.sort((a: any, b: any) => {
                                                if (a.purchases > b.purchases) {
                                                    return -1;
                                                }
                                                if (a.purchases < b.purchases) {
                                                    return 1;
                                                }
                                                if (a.views > b.views) {
                                                    return -1;
                                                }
                                                if (a.views < b.views) {
                                                    return 1;
                                                }
                                                return 0;
                                            }).map((el: any) => {
                                                return <tr key={`${el.v}-${chartDates.dateFrom}`}>
                                                    <Td>{el.v}</Td>
                                                    <Td textAlignment='text-right'>{el.views.toFixed(0)}</Td>
                                                    <Td textAlignment='text-right'>{el.purchases.toFixed(0)}</Td>
                                                    <Td textAlignment='text-right'>{el.views > 0 ? (el.purchases / el.views * 100).toFixed(2) : "-"} %</Td>
                                                    <Td textAlignment='text-right'>{el.totals.toFixed(2)}</Td>
                                                </tr>
                                            })}
                                        </tbody>
                                    }
                                />
                                ,
                                tab: {
                                    children: 'UTM campaign'
                                }
                            }
                        ]}
                    />
                </WhiteCard>

            </div>
        </Fragment>
    )
}