import { StatusViewWithActionsWidget } from '@newageerp/v3.bundles.widgets-bundle'
import React from 'react'

export default function PaymentRefundRequest() {
  return (
    <StatusViewWithActionsWidget
        showOnlyActive={true}
        entity='payment-refund-request'
        type='status'
    />
  )
}
