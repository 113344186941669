import { Form } from '@newageerp/crm-ui'
import React from 'react'
import { RealtimeV3AvailableColumnPresets, RealtimeV3AvailableGroups } from './data'
import { RealtimeV3GroupingRowItem } from './types'

type Props = {
    item: RealtimeV3GroupingRowItem,
    changeItem: (key: string, value: string) => void,
}

export default function RealtimeV3GroupingRow(props: Props) {
    return (
        <div className='flex items-center gap-2'>
            <Form.SelectOptions
                className='w-[400px]'
                options={[
                    {
                        label: '...row value',
                        value: '',
                    },
                    ...RealtimeV3AvailableGroups
                ]}
                value={props.item.group}
                onChange={(e) => props.changeItem('group', e)}
            />
            <Form.SelectOptions
                className='w-[400px]'
                options={[
                    {
                        label: '...columns preset',
                        value: '',
                    },
                    ...RealtimeV3AvailableColumnPresets.map((c) => ({
                        value: c.title,
                        label: c.title
                    }))
                ]}
                value={props.item.fields}
                onChange={(e) => props.changeItem('fields', e)}
            />
        </div>
    )
}
