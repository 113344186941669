import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';

interface Props {
    fieldKey: string,
}

export default function PaymentsCardData(props: Props) {
    const { element } = useListTableRow();

    return (
        <div className='flex gap-x-2 items-center w-[40px]'>
            {!!element.paymentCardInfo && <Fragment>
                <img className='max-h-[15px] max-w-[20px]' src={`/payment/icons/${element.paymentCardInfo.cardType}.svg`} />
                <img className='max-h-[15px] max-w-[20px]' src={`/payment/icons/${element.paymentCardInfo.cardBrand}.svg`} />
            </Fragment>}
        </div>
    )
}
