import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorSubscriptionNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface ISubscriptionModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      active: boolean,
      source: string,
  }

export const ISubscriptionFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "active",
    "source"
];

export function useSubscriptionHookNae(id: number) : ISubscriptionModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorSubscriptionNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "subscription",
        fields: ISubscriptionFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
