import { ResolverWindowElementFormOptions } from '@newageerp/crm-ui'
import React, { Fragment } from 'react'
import ShippingOrderAction from './Components/ShippingOrderAction'
import SubscriptionCancelStatus from './Components/SubscriptionCancelStatus'
import PaymentRefundRequestStatusChange from './Components/PaymentRefundRequestStatusChange'
import SubscriptionPaymentActions from './Components/SubscriptionPaymentActions'
import SubscriptionLinkToOrder from './Components/SubscriptionLinkToOrder'
import PaymentsRefundAction from './Components/PaymentsRefundAction'
import PaymentsLinkToOrder from './Components/PaymentsLinkToOrder'
import PaymentsOrchestratorData from './Components/PaymentsOrchestratorData'
import PaymentsPspData from './Components/PaymentsPspData'
import PaymentsCardData from './Components/PaymentsCardData'
import PaymentsChargeData from './Components/PaymentsChargeData'

export default function ResolverListRowCustomField(props: ResolverWindowElementFormOptions) {
    return (
        <Fragment>
            {props.name === 'Payments/ChargeData' && <PaymentsChargeData fieldKey={props.fieldKey} />}
            {props.name === 'Payments/CardData' && <PaymentsCardData fieldKey={props.fieldKey} />}
            {props.name === 'Payments/PspData' && <PaymentsPspData fieldKey={props.fieldKey} />}
            {props.name === 'Payments/OrchestratorData' && <PaymentsOrchestratorData fieldKey={props.fieldKey} />}
            {props.name === 'Payments/LinkToOrder' && <PaymentsLinkToOrder fieldKey={props.fieldKey} />}
            {props.name === 'Payments/RefundAction' && <PaymentsRefundAction fieldKey={props.fieldKey} />}
            {props.name === 'Subscription/LinkToOrder' && <SubscriptionLinkToOrder fieldKey={props.fieldKey} />}
            {props.name === 'SubscriptionPayment/Actions' && <SubscriptionPaymentActions fieldKey={props.fieldKey} />}
            {props.name === 'PaymentRefundRequest/StatusChange' && <PaymentRefundRequestStatusChange fieldKey={props.fieldKey} />}
            {props.name === 'SubscriptionCancel/Status' && <SubscriptionCancelStatus fieldKey={props.fieldKey} />}
            {props.name === 'ShippingOrder/Action' && <ShippingOrderAction fieldKey={props.fieldKey} />}
        </Fragment>
    )
}
