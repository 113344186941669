import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle'
import React, { Fragment, useEffect } from 'react'
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { groupMap, numberFormat } from '@newageerp/v3.bundles.utils-bundle'
import { DashboardGrossV2borderRight } from '../../DashboardGrossV2'
import classNames from 'classnames'

type Props = {
    date: {
        from: string,
        to: string
    },
    loadState: number,
    adSources: string[],
    countries: string[],
    funnels: string[],
}

export default function GrossV2OrderItemsByDate(props: Props) {

    const [doReq, reqData] = useURequestRaw<any>("/app/plugins/Dashboard/getGrossByOrderItemsDate");

    const loadData = () => {
        doReq({
            dateFrom: props.date.from,
            dateTo: props.date.to,
            adSources: props.adSources,
            countries: props.countries,
            funnels: props.funnels,
        })
    }

    useEffect(() => {
        loadData();
    }, [props.loadState]);

    if (!reqData.data) {
        return <Fragment />
    }

    const groupedDataDate = groupMap(reqData.data.data, (el) => el.date)
    const dates = Object.keys(groupedDataDate)

    const groupedProducts = groupMap(reqData.data.data, (el) => el.title)
    const products = Object.keys(groupedProducts)

    const subs = reqData.data.data.filter((el: any) => el.title.indexOf('Subscription') === 0).map((i: any) => i.c).reduce((a: number, b: number) => a + b, 0)

    return (
        <Table
            thead={
                <thead>
                    <tr>
                        <Th className={DashboardGrossV2borderRight}>Date</Th>
                        {products.map(p => {
                            return <Fragment key={`p-${p}`}>
                                <Th textAlignment={'text-right'}>{p}</Th>
                                <Th textAlignment={'text-right'}>USD</Th>
                                <Th textAlignment={'text-right'}>AOV</Th>
                                <Th className={DashboardGrossV2borderRight} textAlignment={'text-right'}>%</Th>
                            </Fragment>
                        })}
                        <Th textAlignment={'text-right'}>Total, USD</Th>
                    </tr>
                </thead>
            }
            tbody={<tbody>
                {dates.map((date: string, iId: number) => {
                    const tItems = reqData.data.data.filter((el: any) => el.date === date);
                    const tT = tItems.map((i: any) => i.total).reduce((a: number, b: number) => a + b, 0);

                    const slugSubs = tItems.filter((el: any) => el.title.indexOf('Subscription') === 0).map((i: any) => i.c).reduce((a: number, b: number) => a + b, 0)

                    return (
                        <tr key={`sl-${date}`}>
                            <Td className={classNames(DashboardGrossV2borderRight, 'whitespace-nowrap')}>{date}</Td>
                            {products.map(p => {
                                const item = reqData.data.data.find((el: any) => el.date === date && el.title === p);

                                const q = item ? item.c : 0;
                                const t = item ? item.total : 0;

                                return <Fragment key={`p-${date}-${p}`}>
                                    <Td textAlignment={'text-right'} className='text-slate-700'>{q.toFixed(0)}</Td>
                                    <Td textAlignment={'text-right'} className='whitespace-nowrap'>{numberFormat({ userInput: t, decimals: 2, })}</Td>
                                    <Td textAlignment={'text-right'} className='whitespace-nowrap'>{numberFormat({ userInput: t / slugSubs, decimals: 2, })}</Td>
                                    <Td textAlignment={'text-right'} className={classNames(DashboardGrossV2borderRight, 'whitespace-nowrap')}>{numberFormat({ userInput: q / slugSubs * 100, decimals: 2, })}%</Td>
                                </Fragment>
                            })}
                            <Td textAlignment={'text-right'} className='font-medium whitespace-nowrap'>{numberFormat({ userInput: tT, decimals: 2, })}</Td>
                        </tr>
                    )
                })}
                <tr className='total-row font-bold'>
                    <Td>Total</Td>
                    {products.map(p => {
                        const items = reqData.data.data.filter((el: any) => el.title === p);

                        const q = items.map((i: any) => i.c).reduce((a: number, b: number) => a + b, 0);
                        const t = items.map((i: any) => i.total).reduce((a: number, b: number) => a + b, 0);

                        return <Fragment key={`p-total-${p}`}>
                            <Td textAlignment={'text-right'} className='text-slate-700 whitespace-nowrap'>{q.toFixed(0)}</Td>
                            <Td textAlignment={'text-right'} className='whitespace-nowrap'>{numberFormat({ userInput: t, decimals: 2, })}</Td>
                            <Td textAlignment={'text-right'} className='whitespace-nowrap'>{numberFormat({ userInput: t / subs, decimals: 2, })}</Td>
                            <Td textAlignment={'text-right'} className={classNames(DashboardGrossV2borderRight, 'whitespace-nowrap')}>{numberFormat({ userInput: q / subs * 100, decimals: 2, })}%</Td>
                        </Fragment>
                    })}
                    <Td textAlignment={'text-right'} className='font-medium whitespace-nowrap'>
                        {numberFormat({ userInput: reqData.data.data.map((i: any) => i.total).reduce((a: number, b: number) => a + b, 0), decimals: 2, })}
                    </Td>
                </tr>

            </tbody>}
        />
    )
}
