import React, { useEffect, useState } from 'react'
import { Card, Button, Tabs } from '@newageerp/crm-ui'
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import moment from 'moment';
import { useLocalStorage, useUList } from '@newageerp/v3.bundles.hooks-bundle';
import CountryFilter, { CountryFilterCountry } from '../Components/CountryFilter';
import CountryGroupFilter, { CountryGroupFilterGroup } from '../Components/CountryGroupFilter';
import FunnelFilter from '../Components/FunnelFilter';
import AdSourceFilter from '../Components/AdSourceFilter';
import { DashboardGrossCustomConfig, DashboardGrossCustomTabs } from './types';

type Props = {
    config: DashboardGrossCustomConfig
}

export default function DashboardGrossCustomData(props: Props) {
    const [dataLoading, setDataLoading] = useState(false);
    const [reloadKey, setReloadKey] = useState(0);

    const defDates = {
        dateFrom: moment().add(-10, 'day').format("YYYY-MM-DD"),
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }
    const [countries, setCountries] = useState<string[]>([]);
    const [adSources, setAdSources] = useLocalStorage('DashboardAdSourceFilter', []);
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);

    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    const [countryGroup, setCountryGroup] = useState(0);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    const doReload = () => {
        setReloadKey(new Date().getTime());
    }

    return (
        <div className='space-y-4'>
            <Card.WhiteCard style={{
                body: {
                    paddingHorizontal: 'px-2',
                    paddingTop: 'pt-1',
                    paddingBottom: 'pb-1',
                    spacing: 'space-y-2'
                }
            }}>
                <div className='flex flex-wrap gap-x-4 gap-y-2 items-end'>
                    <Compact>
                        <CompactRow
                            label={<FieldLabel>{('Date')}</FieldLabel>}
                            control={
                                <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                            }
                        />
                    </Compact>
                    <CountryFilter
                        countries={countries}
                        countriesData={countriesData.data.data}
                        setCountries={setCountries}
                    />
                    <CountryGroupFilter
                        countriesData={countriesData.data.data}
                        countryGroup={countryGroup}
                        countryGroupsData={countryGroupsData.data.data}
                        setCountries={setCountries}
                        setCountryGroup={setCountryGroup}
                    />

                    <FunnelFilter
                        funnels={funnels}
                        setFunnels={setFunnels}
                    />

                    <AdSourceFilter
                        adSources={adSources}
                        setAdSources={setAdSources}
                    />

                </div>
                <div>
                    <Button.FormButton
                        color={"sky"}
                        loading={dataLoading}
                        disabled={dataLoading}
                        onClick={doReload}
                        iconName='filter'
                    >
                        Filter
                    </Button.FormButton>
                </div>
            </Card.WhiteCard>

            <Tabs.Default
                tabs={DashboardGrossCustomTabs.map(c => ({
                    tab: {
                        title: c.title
                    },
                    content: <c.comp
                        groupBy={c.type}
                        config={props.config}
                        reloadKey={reloadKey}
                        filter={{
                            funnels,
                            countries,
                            adSources,
                            date: chartDates
                        }}
                        loading={ {
                            get: dataLoading,
                            set: setDataLoading
                        }}
                    />
                }))}
                style={{
                    tabPanel: {
                        padding: 'p-0'
                    }
                }}
            />
        </div>
    )
}
