import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';
import React, { Fragment, useEffect } from 'react'
import { NumberCardWidget, WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

export default function DashboardDev() {
    const [doReq, doReqParams] = useURequestRaw<any>("/app/plugins/DashboardDev/stats");

    const loadData = () => {
        doReq({});
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className='space-y-2'>
            <MainButton color='sky' iconName='rotate' onClick={loadData} loading={doReqParams.loading}>Refresh</MainButton>
            <WhiteCard title='Sync'>
                <div className='flex gap-2'>
                    {doReqParams.data?.sync.map((el: any, idx: number) => {
                        return <Fragment key={`s-${idx}`}>
                            <NumberCardWidget
                                color='slate'
                                children={el.count}
                                title={el.title}
                                className='min-w-[320px]'
                            />
                        </Fragment>
                    })}
                </div>
            </WhiteCard>
        </div>
    )
}
