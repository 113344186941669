import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';

interface Props {
    fieldKey: string,
}

export default function PaymentsChargeData(props: Props) {
    const { element } = useListTableRow();

    return (
        <div className='flex gap-x-2 items-center'>
            {!!element.paymentChargeInfo &&
                <Fragment>
                    {element.paymentChargeInfo.hasRefund &&
                        <i className='fa fa-solid fa-undo-alt' title="Has refund" />
                    }
                    {element.paymentChargeInfo.hasAlert &&
                        <i className='fa fa-solid fa-triangle-exclamation text-amber-500' title="Has alert" />
                    }
                    {element.paymentChargeInfo.hasRdr &&
                        <i className='fa fa-solid fa-square-exclamation text-purple-500' title="Has RDR" />
                    }
                    {element.paymentChargeInfo.hasChargeback &&
                        <i className='fa fa-solid fa-square-exclamation text-red-500' title="Has chargeback" />
                    }
                </Fragment>
            }
        </div>
    )
}
