import { Button, Form } from '@newageerp/crm-ui'
import React, { useState } from 'react'
import RealtimeV3GroupingRow from './RealtimeV3GroupingRow'
import { RealtimeV3GroupingRowItem } from './types';

type Props = {
    items: RealtimeV3GroupingRowItem[],
    setItems: (v: RealtimeV3GroupingRowItem[]) => void,
}

export default function RealtimeV3Grouping({ items, setItems }: Props) {
    const addItem = () => {
        setItems([...items, { group: '', fields: '' }]);
    }
    const removeItem = (idx: number) => {
        setItems([...items.filter((_, e) => e !== idx)]);
    }
    const changeItem = (idx: number, key: string, value: string) => {
        setItems([...items.map((el, e) => {
            if (e === idx) {
                return { ...el, [key]: value };
            }
            return { ...el };
        })]);
    }

    return (
        <div className='flex items-start gap-2'>
            <p className='uppercase'>Step 2:</p>
            <p className='text-slate-500 w-[150px]'>configure views</p>
            <div className='space-y-2'>
                {items.map((i, idx) => {
                    return (
                        <div key={`i-${idx}`} className='flex items-center gap-2'>
                            <RealtimeV3GroupingRow
                                changeItem={(key, value) => changeItem(idx, key, value)}
                                item={i}
                            />
                            <Button.ToolbarButton iconName='trash' onClick={() => removeItem(idx)} />
                        </div>)
                })}
                <div><Button.ToolbarButton onClick={addItem} iconName='plus'>Add</Button.ToolbarButton></div>
            </div>
        </div>
    )
}
