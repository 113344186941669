import { Button } from '@newageerp/crm-ui'
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle';
import React, { useState } from 'react'

export default function SubscriptionSupportReport() {
    const { data } = useListDataSource();
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        await axiosInstance.post('/app/plugins/SubscriptionReport/sync');
        setLoading(false);
        data.reload.do();
    }

    return (
        <Button.ToolbarButton loading={loading} iconName='download' title='Refresh list' onClick={onClick} />
    )
}
