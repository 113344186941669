import { useURequest } from '@newageerp/v3.bundles.hooks-bundle'
import { TabContainer, Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import React, { Fragment, useEffect } from 'react'
import { Text } from '@newageerp/v3.bundles.data-bundle'

type Props = {
    email: string
}

export default function KlavyioStats(props: Props) {
    const [doReq, doReqParams] = useURequest<any>("/app/plugins/Klavyio/getProfile");

    const loadData = () => {
        doReq({
            email: props.email
        });
    }
    useEffect(loadData, []);

    if (doReqParams.data.data.length === 0) {
        return <Fragment />
    }

    return (
        <TabContainer
            items={[
                {
                    tab: {
                        children: 'Profile'
                    },
                    content: <Fragment>
                        <Table
                            thead={
                                <thead>
                                    <tr>
                                        <Th>Param</Th>
                                        <Th>Value</Th>
                                    </tr>
                                </thead>
                            }
                            tbody={
                                <tbody>
                                    {doReqParams.data.data[0].map((el: any, elId: number) => {
                                        return (
                                            <tr key={`el-${elId}`}>
                                                <Td>{el.key}</Td>
                                                <Td>{el.val}</Td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            }
                        />
                    </Fragment>
                },
                {
                    tab: {
                        children: 'Events'
                    },
                    content: <Fragment>
                        <Table
                            thead={
                                <thead>
                                    <tr>
                                        <Th>Event</Th>
                                        <Th>Date</Th>
                                        <Th>Props</Th>
                                    </tr>
                                </thead>
                            }
                            tbody={
                                <tbody>
                                    {doReqParams.data.data[1].map((el: any, elId: number) => {
                                        return (
                                            <tr key={`ev-${elId}`}>
                                                <Td>{el.metric}</Td>
                                                <Td>{el.date}</Td>
                                                <Td>
                                                    <Text
                                                        value={el.props}
                                                    />
                                                </Td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            }
                        />
                    </Fragment>
                }
            ]}
        />
    )
}
