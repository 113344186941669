import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Compact, CompactRow, FieldDateRange, FieldLabel, FieldSelect, FieldSelectMulti, Wide, WideRow } from '@newageerp/v3.bundles.form-bundle';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import { AutoSizer } from 'react-virtualized'
import { LogoLoader, MainToolbarTitle, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import classNames from 'classnames';
import { useUList, useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';


const uri = 'data:application/vnd.ms-excel;base64,';

const template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
    'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
    'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
    'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
    '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
    'xml><![endif]--></head><body>{table}</body></html>';

const base64 = (s: string) => {
    return window.btoa(unescape(encodeURIComponent(s)));
}

const doFormat = (s: string, tableId: string) => {
    const context = {
        worksheet: 'Worksheet',
        // @ts-ignore
        table: window.document.getElementById(tableId).outerHTML,
    };

    // @ts-ignore
    return s.replace(/{(\w+)}/g, (_m, p) => context[p]);
}

const doDownload = (tableId: string) => {
    const element = window.document.createElement('a');
    element.href = uri + base64(doFormat(template, tableId));
    element.download = 'export.xls';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export default function DashboardNet() {
    const { t } = useTranslation();

    const [countryGroup, setCountryGroup] = useState(0);

    const [lang, setLang] = useState('');
    const [funnel, setFunnel] = useState('');
    const [countries, setCountries] = useState<string[]>([]);

    const [lastReload, setLastReload] = useState('');
    const [dateKey, setDateKey] = useState('timezoneReportDate');

    const defDates = {
        dateFrom: moment().add(-10, 'day').format("YYYY-MM-DD"),
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [countriesReq, countriesData] = useUList("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList("country-group", ['id', 'name', 'countries', 'countriesExclude']);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);


    const [chartStats, setChartStats] = useState([]);
    const [chartTypes, setChartTypes] = useState([]);
    const [chartTotals, setChartTotals] = useState<any>({});

    const [reqChartsData, reqDataChartsParams] = useURequestRaw("/app/plugins/Dashboard/chartStats");

    const loadCharData = () => {
        
        reqChartsData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
            dateKey,
            lang,
            funnel,
            countries,
        }).then((res: any) => {
            console.log('loadCharData', res)
            setChartTypes(res.data.types)
            setChartTotals(res.data.totals);
            setChartStats(res.data.data)
            setLastReload(moment().format('YYYY-MM-DD HH:mm'));
        })
    }

    useEffect(() => {
        loadCharData();
    }, [chartDates, lang, dateKey, funnel, countries]);

    useEffect(() => {
        const t = setInterval(() => {
            loadCharData();
        }, 60 * 1000);
        return () => {
            clearInterval(t);
        }
    }, [chartDates, lang, dateKey]);


    if (!chartStats) {
        return <Fragment />
    }

    const _statsReverse = JSON.parse(JSON.stringify(chartStats)).reverse();

    const roasTotal = !!chartTotals ? chartTotals.Ads ? (chartTotals.InitialNet + chartTotals.RefundNet) / chartTotals.Ads : 0 : 0;
    const cpaTotal = !!chartTotals ? (chartTotals.InitialNetCount - chartTotals.RefundNetCount) ? chartTotals.Ads / (chartTotals.InitialNetCount - chartTotals.RefundNetCount) : 0 : 0;
    const aovTotal = !!chartTotals ? (chartTotals.InitialNetCount - chartTotals.RefundNetCount) ? (chartTotals.InitialNet + chartTotals.RefundNet) / (chartTotals.InitialNetCount - chartTotals.RefundNetCount) : 0 : 0;
    const netPlTotal = !!chartTotals ? (chartTotals.InitialNet + chartTotals.RefundNet) - chartTotals.Ads + chartTotals.RecurringNet : 0;


    const borderRight = 'border-r border-blue-400';

    const showExtra = funnel === '';

    return (
        <Fragment>
            <MainToolbarTitle title={lastReload} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Language')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={lang}
                                        onChange={setLang}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "En",
                                                value: "en",
                                            },
                                            {
                                                label: "Es",
                                                value: "es",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Funnel')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={funnel}
                                        onChange={setFunnel}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "Main",
                                                value: "main",
                                            },
                                            {
                                                label: "Main (1)",
                                                value: "1",
                                            },
                                            {
                                                label: "Abandoned (B)",
                                                value: "main-b",
                                            },
                                            {
                                                label: "Main (C)",
                                                value: "c",
                                            },
                                            {
                                                label: "Main (D)",
                                                value: "d",
                                            },
                                            {
                                                label: "Adhd",
                                                value: "adhd",
                                            },
                                            {
                                                label: "Adhd (C)",
                                                value: "adhd-c",
                                            },
                                            {
                                                label: "Adhd (D)",
                                                value: "adhd-d",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Country')}</FieldLabel>}
                                control={
                                    <FieldSelectMulti
                                        value={countries}
                                        onChange={setCountries}
                                        asString={true}
                                        options={
                                            countriesData.data.data.map((i: any) => {
                                                return (
                                                    {
                                                        label: i.name,
                                                        value: i.name,
                                                    }
                                                )
                                            })
                                        }
                                    />
                                }
                                controlClassName='max-w-[500px]'
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Country group')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={countryGroup}
                                        onChange={setCountryGroup}
                                        options={[
                                            {
                                                label: "All",
                                                value: 0,
                                            },
                                            ...countryGroupsData.data.data.map((i: any) => {
                                                return (
                                                    {
                                                        label: i.name,
                                                        value: i.id,
                                                    }
                                                )
                                            })
                                        ]}
                                    />
                                }

                            />

                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Refunds')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={dateKey}
                                        onChange={setDateKey}
                                        options={[
                                            {
                                                label: "By source date",
                                                value: "timezoneReportDate",
                                            },
                                            {
                                                label: "By transaction date",
                                                value: "timezoneDate",
                                            },
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <div>
                            <ToolbarButton
                                iconName={"file-excel"}
                                onClick={() => doDownload('gross-pl-export')}
                            />
                        </div>
                    </div>
                </WhiteCard>

                {reqDataChartsParams.loading &&
                    <div className='flex justify-center'>
                        <LogoLoader size={60} />
                    </div>
                }
                
                {chartStats.length > 0 && chartTypes.length > 0 &&


                    <WhiteCard isCompact={true}>
                        <Table
                            id='gross-pl-export'
                            thead={<thead>
                                <tr>
                                    <Th>{t('Date')}</Th>
                                    <Th textAlignment={'text-right'}>{t('Initial Gross Revenue')}</Th>
                                    <Th textAlignment={'text-right'}>{t('Refunds')}</Th>
                                    <Th textAlignment={classNames('text-right', borderRight)}>{t('Initial Net Revenue')}</Th>
                                    <Th textAlignment='text-right'>{t('Subscriptions')}</Th>
                                    <Th textAlignment='text-right'>{t('Ad spend')}</Th>
                                    <Th textAlignment='text-right'>{t('CPA')}</Th>
                                    <Th textAlignment='text-right'>{t('AOV')}</Th>
                                    <Th textAlignment='text-right'>{t('ROAS')}</Th>
                                    <Th textAlignment='text-right'>{t('Recurring revenue')}</Th>
                                    <Th textAlignment='text-right'>{t('Net Performance')}</Th>
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {_statsReverse.map((stat: any) => {
                                        const ads = stat.Ads ? stat.Ads : 0;

                                        const roas = stat.Ads ? (stat.InitialNet + stat.RefundNet) / stat.Ads : 0;
                                        const cpa = (stat.InitialNetCount - stat.RefundNetCount) ? ads / (stat.InitialNetCount - stat.RefundNetCount) : 0;
                                        const aov = (stat.InitialNetCount - stat.RefundNetCount) ? (stat.InitialNet + stat.RefundNet) / (stat.InitialNetCount - stat.RefundNetCount) : 0;

                                        const netPl = (stat.InitialNet + stat.RefundNet) - ads + stat.RecurringNet;
                                        return (
                                            <tr key={`stat-${stat.title}`}>
                                                <Td>{stat.title}</Td>
                                                <Td textAlignment='text-right'>{(stat.Initial).toFixed(2)}</Td>
                                                <Td textAlignment='text-right'>{(stat.RefundNet).toFixed(2)}</Td>
                                                <Td textAlignment={classNames('text-right', borderRight)}>{(stat.InitialNet + stat.RefundNet).toFixed(2)}</Td>

                                                <Td textAlignment='text-right'>{(stat.InitialNetCount - stat.RefundNetCount).toFixed(0)}</Td>
                                                <Td textAlignment='text-right'>{ads.toFixed(2)}</Td>
                                                <Td textAlignment='text-right'>{cpa.toFixed(2)}</Td>
                                                <Td textAlignment='text-right'>{aov.toFixed(2)}</Td>
                                                <Td textAlignment='text-right'>{roas.toFixed(2)}</Td>
                                                <Td textAlignment='text-right'>{stat.RecurringNet}</Td>
                                                <Td textAlignment='text-right'>{netPl.toFixed(2)}</Td>
                                            </tr>
                                        )
                                    })}
                                    <tr className='total-row'>
                                        <Td>{t('Total')}</Td>
                                        <Td textAlignment='text-right'>{(chartTotals.Initial).toFixed(2)}</Td>
                                        <Td textAlignment='text-right'>{(chartTotals.RefundNet).toFixed(2)}</Td>
                                        <Td textAlignment={classNames('text-right', borderRight)}>{(chartTotals.InitialNet + chartTotals.RefundNet).toFixed(2)}</Td>

                                        <Td textAlignment='text-right'>{(chartTotals.InitialNetCount - chartTotals.RefundNetCount).toFixed(0)}</Td>
                                        <Td textAlignment='text-right'>{(chartTotals.Ads ? chartTotals.Ads : 0).toFixed(2)}</Td>
                                        <Td textAlignment='text-right'>{cpaTotal.toFixed(2)}</Td>
                                        <Td textAlignment='text-right'>{aovTotal.toFixed(2)}</Td>
                                        <Td textAlignment='text-right'>{roasTotal.toFixed(2)}</Td>
                                        <Td textAlignment='text-right'>{chartTotals.RecurringNet}</Td>
                                        <Td textAlignment='text-right'>{netPlTotal.toFixed(2)}</Td>
                                    </tr>
                                </tbody>
                            }
                        />

                    </WhiteCard>
                }
            </div>


        </Fragment>
    )
}
