import { Compact, CompactRow, FieldDate, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle'
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getKeysFromObject, groupMap } from '@newageerp/v3.bundles.utils-bundle'
import { useUList } from '@newageerp/v3.bundles.hooks-bundle'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar } from 'recharts'

type Item = {
    timezoneDate: string,
    timezoneDateFormatted: string,
    timeFormatted: string,
    hour: number,
    minutes: number,
    cpa: number,
    aov: number,
    roas: number,
    subs: number,
    adSpend: number
}

const defItem: Item = {
    timezoneDate: '',
    timezoneDateFormatted: '',
    timeFormatted: '',
    hour: 0,
    minutes: 0,
    cpa: 0,
    aov: 0,
    roas: 0,
    subs: 0,
    adSpend: 0,
}

export default function DashboardMonitoring() {
    const { t } = useTranslation();
    const [lastReload, setLastReload] = useState('');

    const defDates = {
        dateFrom: moment().add(-7, 'day').format("YYYY-MM-DD"),
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [monitoringReq, monitoringData] = useUList<Item>("monitoring-item", getKeysFromObject(defItem));

    const getData = async (dt?: {
        dateFrom: string,
        dateTo: string,
    }) => {
        await monitoringReq(
            [{
                "and": [
                    ["i.timezoneDate", "dgte", !!dt ? dt.dateFrom : chartDates.dateFrom, true],
                    ["i.timezoneDate", "dlte", !!dt ? dt.dateTo : chartDates.dateTo, true]
                ]
            }],
            1,
            999999,
            [{ key: 'i.timezoneDate', value: 'ASC' }]
        )

        setLastReload(moment().format('YYYY-MM-DD HH:mm'));
    }

    useEffect(() => {
        getData().catch(console.error)
    }, [chartDates]);

    useEffect(() => {
        const t = setInterval(() => {
            // loadData();

            if (!monitoringData.loading) {
                getData({
                    dateFrom: moment().add(-7, 'day').format("YYYY-MM-DD"),
                    dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
                }).catch(console.error)
            }
        }, 15 * 60 * 1000);
        return () => {
            clearInterval(t);
        }
    }, [chartDates]);

    const groupedMonitoringData: any = groupMap(monitoringData.data.data, (el: Item) => el.timezoneDateFormatted);
    const allDates = Object.keys(groupedMonitoringData);
    const allHours = monitoringData.data.data.map(el => el.hour).sort((a: number, b: number) => {
        if (a > b) {
            return 1;
        }
        return -1;
    }).filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    const aovData = allHours.map((h) => {
        const avData = monitoringData.data.data.filter((e: Item) => e.hour === h);

        const val: any = {
            name: h,
        }
        if (!!avData && avData.length > 0) {
            val['av'] = parseFloat((avData.map((el: Item) => el.aov).reduce((a: number, b: number) => a + b, 0) / avData.length).toFixed(2));
        }
        allDates.forEach(d => {
            const hData = groupedMonitoringData[d].filter((e: Item) => e.hour === h);

            if (!!hData && hData.length > 0) {
                val[d] = parseFloat((hData.map((el: Item) => el.aov).reduce((a: number, b: number) => a + b, 0) / hData.length).toFixed(2));
            }
        })
        return val;
    })

    const cpaData = allHours.map((h) => {
        const avData = monitoringData.data.data.filter((e: Item) => e.hour === h);

        const val: any = {
            name: h,
        }
        if (!!avData && avData.length > 0) {
            val['av'] = parseFloat((avData.map((el: Item) => el.cpa).reduce((a: number, b: number) => a + b, 0) / avData.length).toFixed(2));
        }
        allDates.forEach(d => {
            const hData = groupedMonitoringData[d].filter((e: Item) => e.hour === h);

            if (!!hData && hData.length > 0) {
                val[d] = parseFloat((hData.map((el: Item) => el.cpa).reduce((a: number, b: number) => a + b, 0) / hData.length).toFixed(2));
            }
        })
        return val;
    })

    const roasData = allHours.map((h) => {
        const avData = monitoringData.data.data.filter((e: Item) => e.hour === h);

        const val: any = {
            name: h,
        }
        if (!!avData && avData.length > 0) {
            val['av'] = Math.min(2, parseFloat((avData.map((el: Item) => el.roas).reduce((a: number, b: number) => a + b, 0) / avData.length).toFixed(2)));
        }
        allDates.forEach(d => {
            const hData = groupedMonitoringData[d].filter((e: Item) => e.hour === h);

            if (!!hData && hData.length > 0) {
                val[d] = Math.min(2, parseFloat((hData.map((el: Item) => el.roas).reduce((a: number, b: number) => a + b, 0) / hData.length).toFixed(2)));
            }
        })
        return val;
    })

    const subsData = allHours.map((h) => {
        const avData = monitoringData.data.data.filter((e: Item) => e.hour === h);

        const val: any = {
            name: h,
        }
        if (!!avData && avData.length > 0) {
            val['av'] = parseFloat((avData.map((el: Item) => el.subs).reduce((a: number, b: number) => a + b, 0) / avData.length).toFixed(2));
        }
        allDates.forEach(d => {
            const hData = groupedMonitoringData[d].filter((e: Item) => e.hour === h);

            if (!!hData && hData.length > 0) {
                val[d] = parseFloat((hData.map((el: Item) => el.subs).reduce((a: number, b: number) => a + b, 0) / hData.length).toFixed(2));
            }
        })
        return val;
    })

    const adSpendData = allHours.map((h) => {
        const avData = monitoringData.data.data.filter((e: Item) => e.hour === h);

        const val: any = {
            name: h,
        }
        if (!!avData && avData.length > 0) {
            val['av'] = parseFloat((avData.map((el: Item) => el.adSpend).reduce((a: number, b: number) => a + b, 0) / avData.length).toFixed(2));
        }
        allDates.forEach(d => {
            const hData = groupedMonitoringData[d].filter((e: Item) => e.hour === h);

            if (!!hData && hData.length > 0) {
                val[d] = parseFloat((hData.map((el: Item) => el.adSpend).reduce((a: number, b: number) => a + b, 0) / hData.length).toFixed(2));
            }
        })
        return val;
    })

    return (
        <Fragment>
            <MainToolbarTitle title={`Monitoring - ${lastReload}`} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                    </div>
                </WhiteCard>

                <WhiteCard isCompact={true} title='CPA'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart width={500} height={300} data={cpaData}>
                            <XAxis dataKey="name" />
                            <YAxis />

                            <Tooltip />

                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {allDates.map((d, idx) => {
                                const isLast = idx === allDates.length - 1;

                                return <Line type="monotone" dataKey={d} stroke={isLast ? 'rgba(3,105,161, 1)' : 'rgb(196, 181, 253)'} strokeWidth={isLast ? 4 : 1} key={`cpa-${d}`} strokeDasharray={isLast ? undefined : "5 5"} dot={{ radius: 0.5 }} />
                            })}

                            <Line type="monotone" dataKey={'av'} stroke={'rgb(52, 211, 153)'} strokeWidth={2} />


                        </LineChart>
                    </ResponsiveContainer>
                </WhiteCard>

                <WhiteCard isCompact={true} title='Ad spend'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart width={500} height={300} data={adSpendData}>
                            <XAxis dataKey="name" />
                            <YAxis />

                            <Tooltip />

                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {allDates.map((d, idx) => {
                                const isLast = idx === allDates.length - 1;

                                return <Line type="monotone" dataKey={d} stroke={isLast ? 'rgba(3,105,161, 1)' : 'rgb(196, 181, 253)'} strokeWidth={isLast ? 4 : 1} key={`adspend-${d}`} strokeDasharray={isLast ? undefined : "5 5"} dot={{ radius: 0.5 }} />
                            })}

                            <Line type="monotone" dataKey={'av'} stroke={'rgb(52, 211, 153)'} strokeWidth={2} />


                        </LineChart>
                    </ResponsiveContainer>
                </WhiteCard>

                <WhiteCard isCompact={true} title='AOV'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart width={500} height={300} data={aovData}>
                            <XAxis dataKey="name" />
                            <YAxis />

                            <Tooltip />

                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {allDates.map((d, idx) => {
                                const isLast = idx === allDates.length - 1;

                                return <Line type="monotone" dataKey={d} stroke={isLast ? 'rgba(3,105,161, 1)' : 'rgb(196, 181, 253)'} strokeWidth={isLast ? 4 : 1} key={`aov-${d}`} strokeDasharray={isLast ? undefined : "5 5"} />
                            })}

                            <Line type="monotone" dataKey={'av'} stroke={'rgb(52, 211, 153)'} strokeWidth={2} />


                        </LineChart>
                    </ResponsiveContainer>
                </WhiteCard>

                <WhiteCard isCompact={true} title='ROAS'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart width={500} height={300} data={roasData}>
                            <XAxis dataKey="name" />
                            <YAxis />

                            <Tooltip />

                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {allDates.map((d, idx) => {
                                const isLast = idx === allDates.length - 1;

                                return <Line type="monotone" dataKey={d} stroke={isLast ? 'rgba(3,105,161, 1)' : 'rgb(196, 181, 253)'} strokeWidth={isLast ? 4 : 1} key={`roas-${d}`} strokeDasharray={isLast ? undefined : "5 5"} />
                            })}

                            <Line type="monotone" dataKey={'av'} stroke={'rgb(52, 211, 153)'} strokeWidth={2} />


                        </LineChart>
                    </ResponsiveContainer>
                </WhiteCard>

                <WhiteCard isCompact={true} title='Subscriptions'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart width={500} height={300} data={subsData}>
                            <XAxis dataKey="name" />
                            <YAxis />

                            <Tooltip />

                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {allDates.map((d, idx) => {
                                const isLast = idx === allDates.length - 1;

                                return <Line type="monotone" dataKey={d} stroke={isLast ? 'rgba(3,105,161, 1)' : 'rgb(196, 181, 253)'} strokeWidth={isLast ? 4 : 1} key={`subs-${d}`} strokeDasharray={isLast ? undefined : "5 5"} />
                            })}

                            <Line type="monotone" dataKey={'av'} stroke={'rgb(52, 211, 153)'} strokeWidth={2} />


                        </LineChart>
                    </ResponsiveContainer>
                </WhiteCard>
            </div>
        </Fragment>
    )
}
