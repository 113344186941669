import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';

interface Props {
    fieldKey: string,
}

export default function ShippingOrderAction(props: Props) {
    const { element } = useListTableRow();
    const list = useListDataSource();
    const [doSave, doSaveReq] = useUSave('shipping-order');

    const doCancel = async () => {
        await doSave({
            status: 20,
        }, element.id)
        list.data.reload.do();
    }
    const doRestore = async () => {
        await doSave({
            status: 0,
        }, element.id)
        list.data.reload.do();
    }


    if (element.status === 0) {
        return (
            <MainButton loading={doSaveReq.loading} confirmation={true} onClick={doCancel} color='amber'>Cancel</MainButton>
        )
    }
    if (element.status === 20) {
        return (
            <MainButton loading={doSaveReq.loading} confirmation={true} onClick={doRestore} color='sky'>Restore</MainButton>
        )
    }
    return <Fragment />
}
