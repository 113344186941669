import React, { Fragment } from 'react'
import { MP } from '@newageerp/crm-ui'
// import { useParams } from '@newageerp/v3.templates.templates-core'
import { useUIBuilder } from "@newageerp/v3.app.mvc.ui-builder";
import RealtimeV3Dashboard from './RealtimeV3Dashboard';

export default function RealtimeV3() {
    // const params = useParams();
    const { settings } = useUIBuilder();
    
    if (!settings?.realtime?.project) return <Fragment />
    return (
        <MP.GlobalProvider settings={{
            apiKey: settings.mp.apiKey,
            baseUrl: 'https://mp.anl.crm.wtf/api/storage',
        }}>
            <MP.InfoProvider dataSource={'realtime-v3'} permissions={{
                doFilter: false,
            }}
                user={settings?.realtime?.project}
            >
                <RealtimeV3Dashboard />
            </MP.InfoProvider>
        </MP.GlobalProvider>
    )
}
