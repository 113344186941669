
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorAdCostNae, SelectorAdaloUserNae, SelectorCountryNae, SelectorCountryGroupNae, SelectorCustomerNae, SelectorFrontendNae, SelectorKlavyioEventSyncNae, SelectorOrderItemNae, SelectorOrderItemSkuGroupNae, SelectorPaymentNae, SelectorPaymentRefundRequestNae, SelectorQuizResultNae, SelectorReportItemNae, SelectorShippingOrderNae, SelectorSubscriptionNae, SelectorSubscriptionCancelNae, SelectorSubscriptionCancelReasonNae, SelectorSubscriptionCancelRequestNae, SelectorSubscriptionPaymentNae, SelectorSubscriptionPriceNae, SelectorSubscriptionSupportReportNae, SelectorUserNae } from '../models/ormSelectors';
import { useAdCostHookNae } from '../hooks/useAdCostHookNae';
import { useAdaloUserHookNae } from '../hooks/useAdaloUserHookNae';
import { useCountryHookNae } from '../hooks/useCountryHookNae';
import { useCountryGroupHookNae } from '../hooks/useCountryGroupHookNae';
import { useCustomerHookNae } from '../hooks/useCustomerHookNae';
import { useFrontendHookNae } from '../hooks/useFrontendHookNae';
import { useKlavyioEventSyncHookNae } from '../hooks/useKlavyioEventSyncHookNae';
import { useOrderItemHookNae } from '../hooks/useOrderItemHookNae';
import { useOrderItemSkuGroupHookNae } from '../hooks/useOrderItemSkuGroupHookNae';
import { usePaymentHookNae } from '../hooks/usePaymentHookNae';
import { usePaymentRefundRequestHookNae } from '../hooks/usePaymentRefundRequestHookNae';
import { useQuizResultHookNae } from '../hooks/useQuizResultHookNae';
import { useReportItemHookNae } from '../hooks/useReportItemHookNae';
import { useShippingOrderHookNae } from '../hooks/useShippingOrderHookNae';
import { useSubscriptionHookNae } from '../hooks/useSubscriptionHookNae';
import { useSubscriptionCancelHookNae } from '../hooks/useSubscriptionCancelHookNae';
import { useSubscriptionCancelReasonHookNae } from '../hooks/useSubscriptionCancelReasonHookNae';
import { useSubscriptionCancelRequestHookNae } from '../hooks/useSubscriptionCancelRequestHookNae';
import { useSubscriptionPaymentHookNae } from '../hooks/useSubscriptionPaymentHookNae';
import { useSubscriptionPriceHookNae } from '../hooks/useSubscriptionPriceHookNae';
import { useSubscriptionSupportReportHookNae } from '../hooks/useSubscriptionSupportReportHookNae';
import { useUserHookNae } from '../hooks/useUserHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'ad-cost') {
    return useAdCostHookNae;
  }
  if (schema === 'adalo-user') {
    return useAdaloUserHookNae;
  }
  if (schema === 'country') {
    return useCountryHookNae;
  }
  if (schema === 'country-group') {
    return useCountryGroupHookNae;
  }
  if (schema === 'customer') {
    return useCustomerHookNae;
  }
  if (schema === 'frontend') {
    return useFrontendHookNae;
  }
  if (schema === 'klavyio-event-sync') {
    return useKlavyioEventSyncHookNae;
  }
  if (schema === 'order-item') {
    return useOrderItemHookNae;
  }
  if (schema === 'order-item-sku-group') {
    return useOrderItemSkuGroupHookNae;
  }
  if (schema === 'payment') {
    return usePaymentHookNae;
  }
  if (schema === 'payment-refund-request') {
    return usePaymentRefundRequestHookNae;
  }
  if (schema === 'quiz-result') {
    return useQuizResultHookNae;
  }
  if (schema === 'report-item') {
    return useReportItemHookNae;
  }
  if (schema === 'shipping-order') {
    return useShippingOrderHookNae;
  }
  if (schema === 'subscription') {
    return useSubscriptionHookNae;
  }
  if (schema === 'subscription-cancel') {
    return useSubscriptionCancelHookNae;
  }
  if (schema === 'subscription-cancel-reason') {
    return useSubscriptionCancelReasonHookNae;
  }
  if (schema === 'subscription-cancel-request') {
    return useSubscriptionCancelRequestHookNae;
  }
  if (schema === 'subscription-payment') {
    return useSubscriptionPaymentHookNae;
  }
  if (schema === 'subscription-price') {
    return useSubscriptionPriceHookNae;
  }
  if (schema === 'subscription-support-report') {
    return useSubscriptionSupportReportHookNae;
  }
  if (schema === 'user') {
    return useUserHookNae;
  }
  return selector;
}

export function AdCostEmptyFieldNae(props: PropsLink) {
  const element = useAdCostHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function AdaloUserEmptyFieldNae(props: PropsLink) {
  const element = useAdaloUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CountryEmptyFieldNae(props: PropsLink) {
  const element = useCountryHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CountryGroupEmptyFieldNae(props: PropsLink) {
  const element = useCountryGroupHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CustomerEmptyFieldNae(props: PropsLink) {
  const element = useCustomerHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function FrontendEmptyFieldNae(props: PropsLink) {
  const element = useFrontendHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function KlavyioEventSyncEmptyFieldNae(props: PropsLink) {
  const element = useKlavyioEventSyncHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function OrderItemEmptyFieldNae(props: PropsLink) {
  const element = useOrderItemHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function OrderItemSkuGroupEmptyFieldNae(props: PropsLink) {
  const element = useOrderItemSkuGroupHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentEmptyFieldNae(props: PropsLink) {
  const element = usePaymentHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentRefundRequestEmptyFieldNae(props: PropsLink) {
  const element = usePaymentRefundRequestHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function QuizResultEmptyFieldNae(props: PropsLink) {
  const element = useQuizResultHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ReportItemEmptyFieldNae(props: PropsLink) {
  const element = useReportItemHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ShippingOrderEmptyFieldNae(props: PropsLink) {
  const element = useShippingOrderHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionCancelEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionCancelHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionCancelReasonEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionCancelReasonHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionCancelRequestEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionCancelRequestHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionPaymentEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionPaymentHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionPriceEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionPriceHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SubscriptionSupportReportEmptyFieldNae(props: PropsLink) {
  const element = useSubscriptionSupportReportHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function UserEmptyFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

