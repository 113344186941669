import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle'
import { SFSForm } from '@newageerp/v3.templates.json-form';
import React, { Fragment, useEffect, useState } from 'react'
import validator from '@rjsf/validator-ajv8'
import classNames from 'classnames';
import { Card, Tabs, Table, Window, Button } from '@newageerp/crm-ui'
import { useParams } from '@newageerp/v3.templates.templates-core';

export default function AbTestManagementForm() {
    const [search, setSearch] = useState('');

    const routeParams = useParams<{ project: string }>();
    const [extraFilter, setExtraFilter] = useState<{ key: string, value: string }>();

    const [config, setConfig] = useState<any>();
    const [enumsData, setEnumsData] = useState<any>()
    const [data, setData] = useState<any>()

    const updateData = async () => {
        const _data = await getItem('funnels')
        if (_data) {
            setData(_data)
        }
    }

    useEffect(() => {
        updateData().catch(console.error)
    }, [])

    const loadConfig = async () => {
        if (routeParams.project) {
            const res = await axiosInstance.post('/app/nae-core/sf-config/getUserConfig', { config: 'abtest' })
            if (!!res.data && !!res.data.success) {
                const data = res.data.data.find((el: any) => el.project === routeParams.project);

                if (data) {
                    const resEnums = await fetch(`${data.enumsUrl}/api/enums`, {
                        method: 'POST'
                    })
                    const resEnumsJson = await resEnums.json();

                    setEnumsData(resEnumsJson.data);
                    setConfig(data);
                }
            }
        }
    }
    useEffect(() => {
        setEnumsData(undefined);
        setConfig(undefined);
        loadConfig().catch(console.error);
    }, [routeParams.project])

    const clearAbData = async () => {
        for (const projectUrl of config.projectUrls) {
            const url = `${projectUrl}/api/ab-data/clear`
            const _res = await fetch(url, {
                body: JSON.stringify({}),
                method: 'POST',
                headers: {
                    // 'Content-Type': 'application/json',
                },
                cache: 'no-store',
            })
        }
    }

    const getItem = async (slug: string) => {
        const res = await fetch(`${config.dataUrl}/funnel/get`, {
            body: JSON.stringify({
                slug,
                project: config.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const resJson = await res.json()
        return resJson?.data?.templates
    }
    const updateItem = async (slug: string, formData: any) => {
        await fetch(`${config.dataUrl}/funnel/updateTemplates`, {
            body: JSON.stringify({
                slug,
                templates: formData,
                project: config.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        await clearAbData()
        await updateData();
    }

    if (!config) {
        return <Fragment />
    }

    return (
        <div>
            <div className='flex gap-8'>
                <Card.WhiteCard style={{
                    container: {
                        class: 'w-full'
                    }
                }}>

                    <Tabs.Default
                        tabs={[
                            {
                                tab: {
                                    title: 'Funnels',
                                },
                                content: (
                                    <FunnelsForm
                                        getItem={getItem}
                                        updateItem={updateItem}
                                        enumsData={enumsData}
                                        updateData={updateData}
                                        funnelsData={data}
                                    />
                                ),
                            },
                            {
                                tab: {
                                    title: 'Traffic split',
                                },
                                content: (
                                    <TrafficSplitForm
                                        getItem={getItem}
                                        updateItem={updateItem}
                                        enumsData={enumsData}
                                        updateData={updateData}
                                        funnelsData={data}
                                    />
                                ),
                            },
                            {
                                tab: {
                                    title: 'Templates stat',
                                },
                                content: (
                                    <StatsForm
                                        enumsData={enumsData}
                                        funnelsData={data}
                                        config={config}
                                        setExtraFilter={setExtraFilter}
                                    />
                                ),
                            },
                            {
                                tab: {
                                    title: 'Templates summary',
                                },
                                content: (
                                    <SummaryForm
                                        enumsData={enumsData}
                                        funnelsData={data}
                                        config={config}
                                    />
                                ),
                            },
                        ]}
                    />
                </Card.WhiteCard>

                <Card.WhiteCard
                    header={{
                        toolbar: <Card.toolbar.CardToolbar>
                            <Card.toolbar.SearchBar width={"w-full"} value={search} onSearch={setSearch} instantSearch={true} />
                        </Card.toolbar.CardToolbar>
                    }}
                >
                    <div className='space-y-2 w-[400px] text-sm'>
                        {!!extraFilter && <div className='p-2 text-sm italic bg-sky-50 text-slate-500'>
                            Extra filter applied. {extraFilter.key} = {extraFilter.value}. <button className='underline hover:no-underline' onClick={() => setExtraFilter(undefined)}>remove</button>
                        </div>}
                        {!!data && <div className={'space-y-4'}>

                            <div className={'divide-y divide-slate-300 max-h-[75vh] overflow-y-auto'}>
                                {data.filter((_e: any) => {
                                    if (!search) return true;
                                    return `${_e.landing}/${_e.checkout}`.toLowerCase().indexOf(search.toLowerCase()) > -1 || `${_e.internalNote}`.toLowerCase().indexOf(search.toLowerCase()) > -1;
                                }).filter((_e: any) => {
                                    if (!extraFilter) return true;
                                    return _e[extraFilter.key] === extraFilter.value;
                                }).map((e: any) => {
                                    const idx = data.map((_e: any) => `${_e.landing}/${_e.checkout}`).indexOf(`${e.landing}/${e.checkout}`);

                                    return (
                                        <div key={`idx-${idx}`} className='space-y-2 py-2'>
                                            <div className='flex gap-4'>
                                                <span className='w-[20px] text-right text-slate-600'>{idx + 1}</span>
                                                <p className='flex-grow'>/{e.landing}/{e.checkout}</p>
                                                {config.projectUrls.length > 0 && <span className='flex gap-2'>
                                                    {config.projectUrls.map((l: string) => <a key={`${l}-${idx}`} href={`${linkForVariant(l, e)}`} className='text-blue-500 underline hover:no-underline' target='_blank'>
                                                        <i className='fa-regular fa-link' title={l} />
                                                    </a>)}
                                                </span>}
                                            </div>
                                            {!!e.internalNote &&
                                                <p className='text-slate-600'>{e.internalNote}</p>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
                </Card.WhiteCard>
            </div>
        </div>
    )
}

const linkForVariant = (url: string, e: any) => {
    return `${url}/${e.landing}/${e.checkout}`;
}

type TabProps = {
    getItem: (slug: string) => any;
    updateItem: (slug: string, formData: any) => void;
    enumsData: any,
    updateData: () => void,
    funnelsData: any
};

const FunnelsForm = (props: TabProps) => {
    const [data, setData] = useState<any>()
    const [isLoaded, setIsLoaded] = useState(false)

    const slug = 'funnels'

    let schema = {
        type: 'array',
        items: {
            type: 'object',
            properties: {
                landing: { type: 'string', title: 'Landing' },
                checkout: { type: 'string', title: 'Checkout' },
                // variant: { type: 'string', title: 'Variant' },
                // currency: { type: 'string', title: 'Currency', enum: ['usd', 'eur', 'gbp'], default: 'usd' },

                internalNote: { type: 'string', title: 'Internal note' },

                homePageTemplate: { type: 'string', title: 'Home page', enum: props.enumsData.homePageTemplate, default: 'Default' },
                quizPageTemplate: { type: 'string', title: 'Quiz page', enum: props.enumsData.quizPageTemplate, default: 'Default' },
                resultPageTemplate: { type: 'string', title: 'Results page', enum: props.enumsData.resultPageTemplate, default: 'Default' },
                emailPageTemplate: { type: 'string', title: 'Email page', enum: props.enumsData.emailPageTemplate, default: 'Default' },
                checkoutPageTemplate: { type: 'string', title: 'Checkout page', enum: props.enumsData.checkoutPageTemplate, default: 'Default' },
                upsellPageTemplate: { type: 'string', title: 'Upsell page', enum: props.enumsData.upsellPageTemplate ? props.enumsData.upsellPageTemplate : ['Default'], default: 'Default' },

            },
        },
    }

    useEffect(() => {
        const asyncF = async () => {
            const _data = await props.getItem(slug)
            if (_data) {
                setData(_data)
                props.updateData();
            }
            setIsLoaded(true)
        }
        asyncF().catch(console.error)
    }, [])

    return (
        <div className={'w-[600px]'}>
            {isLoaded && (
                <SFSForm
                    schema={schema}
                    validator={validator}
                    onSubmit={(e) => {
                        props.updateItem(slug, e.formData)
                        setData(e.formData)
                    }}
                    formData={data}
                />
            )}



        </div>
    )
}


const TrafficSplitForm = (props: TabProps) => {
    const [data, setData] = useState<any>()
    const [isLoaded, setIsLoaded] = useState(false)

    const slug = 'traffic'

    let schema = {
        type: 'array',
        items: {
            type: 'object',
            required: [
                "input"
            ],
            properties: {
                input: { type: 'string', title: 'Input' },
                split: {
                    type: 'array',
                    title: 'Split',
                    items: {
                        type: 'object',
                        required: [
                            "url",
                            "split"
                        ],
                        properties: {
                            url: { type: 'string', title: 'Url' },
                            split: { type: 'number', title: 'Percent' },
                        },
                    },
                },
            },
        },
    }

    useEffect(() => {
        const asyncF = async () => {
            const _data = await props.getItem(slug)
            if (_data) {
                setData(_data)
            }
            setIsLoaded(true)
        }
        asyncF().catch(console.error)
    }, [])

    const tbl = Table.default;

    return (
        <div className={'w-[600px]'}>
            {isLoaded && !!data && (
                <div className='space-y-6'>
                    <SFSForm
                        schema={schema}
                        validator={validator}
                        onSubmit={(e) => {
                            props.updateItem(slug, e.formData)
                            setData(e.formData)
                        }}
                        formData={data}
                    />

                    <tbl.Table
                        thead={
                            <thead>
                                <tr>
                                    <tbl.Th>Input</tbl.Th>
                                    <tbl.Th>{""}</tbl.Th>
                                    <tbl.Th>Split</tbl.Th>
                                    <tbl.Th>URL</tbl.Th>
                                </tr>
                            </thead>
                        }
                        tbody={
                            <tbody>
                                {data.map((el: any) => {
                                    const funnels = props.funnelsData.map((e: any) => `${e.landing}/${e.checkout}`)
                                    const isOk = funnels.indexOf(el.input) === -1;
                                    const percents = el.split.map((e: any) => e.split).reduce((a: number, b: number) => a + b, 0);

                                    return (
                                        <Fragment key={`split-${el.input}`}>
                                            <tr>
                                                <tbl.Td props={{ rowSpan: el.split.length + 1 }}>
                                                    {el.input}

                                                    <i className={
                                                        classNames(
                                                            'fa fa-solid',
                                                            { 'fa-check text-green-500': isOk },
                                                            { 'fa-ban text-red-500': !isOk }
                                                        )}
                                                    />
                                                </tbl.Td>
                                                <tbl.Td></tbl.Td>
                                                <tbl.Td>{percents}% <i className={
                                                    classNames(
                                                        'fa fa-solid',
                                                        { 'fa-check text-green-500': percents === 100 },
                                                        { 'fa-ban text-red-500': percents !== 100 }
                                                    )}
                                                /></tbl.Td>
                                                <tbl.Td></tbl.Td>
                                            </tr>
                                            {el.split.map((split: any, idx: number) => {
                                                const isOk = funnels.indexOf(split.url) >= 0

                                                return (
                                                    <tr>
                                                        <tbl.Td>{idx + 1}</tbl.Td>
                                                        <tbl.Td>{split.split}%</tbl.Td>
                                                        <tbl.Td>
                                                            {split.url}
                                                            <i className={
                                                                classNames(
                                                                    'fa fa-solid',
                                                                    { 'fa-check text-green-500': isOk },
                                                                    { 'fa-ban text-red-500': !isOk }
                                                                )}
                                                            />
                                                        </tbl.Td>
                                                    </tr>
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}

                            </tbody>
                        }
                    />

                </div>
            )}
        </div>
    )
}

type StatsFormProps = {
    enumsData: any,
    funnelsData: any,
    config: any,
    setExtraFilter: (o: { key: string, value: string }) => void,
}

const StatsForm = (props: StatsFormProps) => {
    const enumTemplates = Object.keys(props.enumsData);
    const tbl = Table.default;

    const [funnelsToShow, setFunnelsToShow] = useState([]);

    return <Fragment>
        <tbl.Table
            thead={
                <thead>
                    <tr>
                        <tbl.Th>Type</tbl.Th>
                        <tbl.Th>Template</tbl.Th>
                        <tbl.Th>Usage</tbl.Th>
                        <tbl.Th>{""}</tbl.Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {enumTemplates.map(t => {
                        const enums = props.enumsData[t];

                        return <Fragment key={`${t}`}>
                            <tr className='font-bold bg-sky-100'>
                                <tbl.Td>{t}</tbl.Td>
                                <tbl.Td>{""}</tbl.Td>
                                <tbl.Td>{""}</tbl.Td>
                                <tbl.Td>{""}</tbl.Td>
                            </tr>
                            {enums.map((en: string) => {
                                const funnels = props.funnelsData.filter((f: any) => f[t] === en);

                                return <tr
                                    key={`${t}-${en}`}
                                    className={
                                        classNames(
                                            { 'bg-red-50': funnels.length === 0 }
                                        )
                                    }
                                >
                                    <tbl.Td>{""}</tbl.Td>
                                    <tbl.Td>{en}</tbl.Td>
                                    <tbl.Td>{funnels.length}</tbl.Td>
                                    <tbl.Td>
                                        {/* <Button.ToolbarButton iconName='eye' onClick={() => setFunnelsToShow(funnels)} /> */}
                                        <Button.ToolbarButton iconName='filter' onClick={() => props.setExtraFilter({ key: t, value: en })} />
                                    </tbl.Td>
                                </tr>
                            })}
                        </Fragment>
                    })}
                </tbody>
            }
        />
        <Window.Popup isPopup={funnelsToShow.length > 0} onClick={() => setFunnelsToShow([])} title={"Funnels"} className='w-[400px]'>
            {funnelsToShow.map((e: any) => {
                const idx = props.funnelsData.map((_e: any) => `${_e.landing}/${_e.checkout}`).indexOf(`${e.landing}/${e.checkout}`);

                return (
                    <div key={`idx-${idx}`} className='space-y-2 py-2'>
                        <div className='flex gap-4'>
                            <span className='w-[20px] text-right text-slate-600'>{idx + 1}</span>
                            <p className='flex-grow'>/{e.landing}/{e.checkout}</p>
                            {props.config.projectUrls.length > 0 && <span className='flex gap-2'>
                                {props.config.projectUrls.map((l: string) => <a key={`${l}-${idx}`} href={`${linkForVariant(l, e)}`} className='text-blue-500 underline hover:no-underline' target='_blank'>
                                    <i className='fa-regular fa-link' title={l} />
                                </a>)}
                            </span>}
                        </div>
                        {!!e.internalNote &&
                            <p className='text-slate-600'>{e.internalNote}</p>
                        }
                    </div>
                )
            })}
        </Window.Popup>
    </Fragment>
}


type SummaryFormProps = {
    enumsData: any,
    funnelsData: any,
    config: any,
}

const SummaryForm = (props: SummaryFormProps) => {
    const enumTemplates = Object.keys(props.enumsData);
    const tbl = Table.default;
    const [search, setSearch] = useState('');

    return <div className='space-y-4'>
        <Card.toolbar.SearchBar value={search} onSearch={setSearch} width={'w-full'} instantSearch={true} />
        <tbl.Table
            thead={
                <thead>
                    <tr>
                        <tbl.Th>#</tbl.Th>
                        <tbl.Th>Landing</tbl.Th>
                        <tbl.Th>Checkout</tbl.Th>
                        {enumTemplates.map(en => <tbl.Th key={en}>{en}</tbl.Th>)}
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {props.funnelsData.filter((_e: any) => {
                        if (!search) return true;
                        return `${_e.landing}/${_e.checkout}`.toLowerCase().indexOf(search.toLowerCase()) > -1 || `${_e.internalNote}`.toLowerCase().indexOf(search.toLowerCase()) > -1;
                    }).sort((a: any, b: any) => `${a.landing}/${a.checkout}`.localeCompare(`${b.landing}/${b.checkout}`)).map((e: any) => {
                        const idx = props.funnelsData.map((_e: any) => `${_e.landing}/${_e.checkout}`).indexOf(`${e.landing}/${e.checkout}`);

                        return <Fragment key={`f-${idx}`}>
                            <tr>
                                <tbl.Td props={{ className: 'text-right' }}>{idx + 1}</tbl.Td>
                                <tbl.Td>{e.landing}</tbl.Td>
                                <tbl.Td>{e.checkout}</tbl.Td>

                                {enumTemplates.map(en => <tbl.Td key={`f-${idx}-en`}>{e[en]}</tbl.Td>)}
                            </tr>

                        </Fragment>
                    })}
                </tbody>
            }
        />

    </div>
}