import { Compact, CompactRow, FieldDateRange, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import { MainToolbarTitle, TabContainer } from '@newageerp/v3.bundles.layout-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CancelationsReport from './CancelationsReport'
import { TemplatesLoader } from '@newageerp/v3.templates.templates-core'

export default function DashboardCancelations() {
    const { t } = useTranslation();

    const [dateType, setDateType] = useState('timezone_stop_date');

    const defDates = {
        dateFrom: moment().add(-10, 'day').format("YYYY-MM-DD"),
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }


    return (
        <Fragment>
            <MainToolbarTitle title={"Cancelations"} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Group by date')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={dateType}
                                        onChange={setDateType}
                                        options={[
                                            {
                                                label: "Stop date",
                                                value: "timezone_stop_date",
                                            },
                                            {
                                                label: "Sub date",
                                                value: "timezone_date",
                                            },
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                    </div>
                </WhiteCard>

                <TabContainer
                    items={[
                        {
                            content: <CancelationsReport chartDates={chartDates} dateType={dateType} />,
                            tab: {
                                children: 'Report'
                            }
                        }
                        ,
                        {
                            content:
                                <div key={`${chartDates.dateFrom}-${chartDates.dateTo}-${dateType}`}>
                                    <TemplatesLoader
                                        templateName="PageInlineList"
                                        data={
                                            {
                                                schema: "report-item",
                                                type: "cancelations",
                                                // isPopup: true,
                                                extraFilters: [
                                                    {
                                                        "and":
                                                            [
                                                                [`i.${dateType === 'timezone_stop_date' ? 'timezoneStopDate' : 'timezoneDate'}`, "dgte", chartDates.dateFrom, true],
                                                                [`i.${dateType === 'timezone_stop_date' ? 'timezoneStopDate' : 'timezoneDate'}`, "dlte", chartDates.dateTo, true]
                                                            ]
                                                    }
                                                ],
                                                addToolbar: true,
                                            }}
                                    />
                                </div>,
                            tab: {
                                children: 'List'
                            }
                        },
                        // {
                        //     content:
                        //         <div key={`${chartDates.dateFrom}-${chartDates.dateTo}-${dateType}`}>
                        //             <TemplatesLoader
                        //                 templateName="PageInlineList"
                        //                 data={
                        //                     {
                        //                         schema: "subscription-cancel",
                        //                         type: "main",
                        //                         // isPopup: true,
                        //                         extraFilters: [
                        //                             {
                        //                                 "and":
                        //                                     dateType === 'timezone_stop_date' ? [
                        //                                         ["i.date", "dgte", chartDates.dateFrom, true],
                        //                                         ["i.date", "dlte", chartDates.dateTo, true]
                        //                                     ] : [
                        //                                         ["i.subscription.startDate", "dgte", chartDates.dateFrom, true],
                        //                                         ["i.subscription.startDate", "dlte", chartDates.dateTo, true]
                        //                                     ]
                        //                             }
                        //                         ],
                        //                         addToolbar: true,
                        //                     }}
                        //             />
                        //         </div>,
                        //     tab: {
                        //         children: 'List'
                        //     }
                        // }
                    ]}
                />

            </div>
        </Fragment>
    )
}
