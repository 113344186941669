
import Chart from './Chart'

export default function DashboardProductPerformanceTmp() {
    
    return (
        <div className='w-full space-y-2'>
            <Chart type='DAY'/>
            <Chart type='WEEK'/>
            <Chart type='MONTH'/>
        </div>
    )
}