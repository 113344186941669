import React, { Fragment, useEffect, useState } from 'react'
import { RealtimeV3DataSourceConfig, RealtimeV3DateSelection } from './types'
import { Button, Card, Tabs, Window } from '@newageerp/crm-ui'
import RealtimeV3Dates from './RealtimeV3Dates'
import moment from 'moment'
import { MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import RealtimeV3Data from './RealtimeV3Data'
import RealtimeV3ConfigManagement from './RealtimeV3ConfigManagement'
import { RealtimeV3AvailableColumnPresets, RealtimeV3AvailableGroups } from './data'

type Props = {
    config: RealtimeV3DataSourceConfig
    onRemove: () => void,
    onChange: (c: RealtimeV3DataSourceConfig) => void,
}

export default function RealtimeV3ConfigParser(props: Props) {
    const dtNow = moment().utcOffset('-0700');
    const [editMode, setEditMode] = useState(false);

    const defDates = {
        dateFrom: dtNow.format('YYYY-MM-DD'),
        dateTo: dtNow.format('YYYY-MM-DD')
    };

    const clearState = () => {
        setDates(defDates);
    }

    const [dates, setDates] = useState<RealtimeV3DateSelection>(defDates);
    const [filters, setFilters] = useState<MpFilterFactorySetUniversalItem[]>([]);
    const [childFilters, setChildFilters] = useState<MpFilterFactorySetUniversalItem[]>([]);

    useEffect(() => {
        setFilters([
            {
                "comparison": "and",
                "filters": [
                    {
                        "class": "NumberEqLargeFilter",
                        "props": {
                            "column": "created_at_us",
                            "value": moment.utc(`${dates.dateFrom} 00:00:00`).unix() + 25200,
                        }
                    },
                    {
                        "class": "NumberEqLessFilter",
                        "props": {
                            "column": "created_at_us",
                            "value": moment.utc(`${dates.dateTo} 23:59:59`).unix() + 25200,
                        }
                    },
                ]
            }
        ]);
    }, [dates]);

    useEffect(() => {
        if (filters.length > 0) {
            setChildFilters(
                [
                    ...props.config.dataFilters,
                    ...filters
                ]
            );
        }
    }, [props.config.dataFilters, filters])


    return (
        <Card.WhiteCard>
            <div className='flex items-center gap-2'>
                <RealtimeV3Dates dates={dates} setDates={setDates} clearState={clearState} />

                <Button.ToolbarButton iconName='rotate' onClick={() => setDates({ ...dates })} />
            </div>


            {childFilters.length > 0 &&
                <Tabs.Default
                    tabs={[
                        ...props.config.rows.map((r) => {
                            const r1 = RealtimeV3AvailableColumnPresets.find(e => e.title === r.fields)
                            const r2 = RealtimeV3AvailableGroups.find(e => e.value === r.group)

                            return (
                                ({
                                    tab: {
                                        title: `${r2 ? r2.label : '-'} (${r1 ? r1.title : '-'})`,
                                    },
                                    content: <RealtimeV3Data
                                        filters={childFilters}
                                        item={r}
                                    />
                                })
                            )
                        })
                    ]}
                />
            }
            <div className='flex items-center gap-2'>
                <Button.ToolbarButton iconName='edit' onClick={() => setEditMode(true)}>Edit</Button.ToolbarButton>
                <Button.ToolbarButton iconName='trash' onClick={props.onRemove}>Remove</Button.ToolbarButton>
            </div>

            {!!editMode &&
                <Window.Popup isPopup={editMode} onClick={() => setEditMode(false)}>
                    <RealtimeV3ConfigManagement onAdd={(c) => {
                        props.onChange(c);
                        setEditMode(false);
                    }} config={props.config} />
                </Window.Popup>
            }
        </Card.WhiteCard>
    )
}
