import React, { Fragment } from 'react'
import { RealtimeV3DataSourceConfig } from './types';
import RealtimeV3ConfigManagement from './RealtimeV3ConfigManagement';
import { useLocalStorage } from '@newageerp/v3.bundles.hooks-bundle';
import { Tabs } from '@newageerp/crm-ui';
import RealtimeV3ConfigParser from './RealtimeV3ConfigParser';


export default function RealtimeV3Dashboard() {
    const [configs, setConfigs] = useLocalStorage('realtime-v3-configs', []);
    const onAdd = (c: RealtimeV3DataSourceConfig) => {
        setConfigs([
            ...configs,
            c,
        ])
    }
    const onRemove = (idx: number) => {
        setConfigs([...configs.filter((_: any, cIdx: number) => cIdx !== idx)]);
    }
    const onChange = (idx: number, config: RealtimeV3DataSourceConfig) => {
        setConfigs([...configs.map((el: any, cIdx: number) => {
            if (idx !== cIdx) return el;
            return config;
        })]);
    }

    return (
        <Fragment>
            <Tabs.Default
                tabs={[
                    {
                        tab: {
                            title: 'Config'
                        },
                        content: <RealtimeV3ConfigManagement onAdd={onAdd} />
                    },
                    ...configs.map((c: RealtimeV3DataSourceConfig, cIdx: number) => ({
                        tab: {
                            title: c.title,
                        },
                        content: <RealtimeV3ConfigParser
                            config={c}
                            onRemove={() => onRemove(cIdx)}
                            onChange={c => onChange(cIdx, c)}
                        />
                    }))
                ]}
            />

        </Fragment>
    )
}