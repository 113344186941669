export const HomePageEvents = [
    'Home',
    'Landing'
]

export const PurchaseEvents = [
    'Paid',
    'Purchase'
]

export const RealtimeV3AvailableColumnPresets = [
    {
        title: 'HB performance',
        events: [
            'HB-Landing',
            'HB-Header-Click',
            'HB-Banner-Click',
            'HB-Image-Click'
        ],
        cr: true,
    },
    {
        title: 'Quiz performance',
        events: [
            ...HomePageEvents,
            '/Q-[0-9]+/'
        ],
        drop: true,
    },
    {
        title: 'HB + Sales',
        events: [
            'HB-Landing',
            ...HomePageEvents,
            'QuizStart',
            'QuizFinish',
            'LeftEmail',
            'Checkout',
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        drop: true,
    },
    {
        title: 'Sales',
        events: [
            ...HomePageEvents,
            'QuizStart',
            'QuizFinish',
            'LeftEmail',
            'Checkout',
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        drop: true,
    },
    {
        title: 'HB + Media buyer',
        events: [
            'HB-Landing',
            ...HomePageEvents,
            'Checkout',
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        // drop: true,
    },
    {
        title: 'Media buyer',
        events: [
            ...HomePageEvents,
            'Checkout',
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        // drop: true,
    }
];


export const RealtimeV3AvailableGroups = [

    { value: 'attrl_advertorial.attr_value', label: 'Advertorial' },
    { value: 'funnel', label: 'Funnel' },
    { value: 'context_geo.country', label: 'Country' },
    { value: 'context_browser.origin', label: 'Origin' },
    { value: 'context_browser.os', label: 'OS' },
    { value: 'context_browser.browser', label: 'Browser' },
    { value: 'context_browser.type', label: 'Device' },
    { value: 'project', label: 'Project' },

    { value: 'utmf_source_medium', label: 'UTM First Source + Medium' },
    { value: 'utmf_source_campaign', label: 'UTM First Source + Campaign' },
    { value: 'utmf_source_content', label: 'UTM First Source + Content' },
    { value: 'utmf_source_term', label: 'UTM First Source + Term' },
    { value: 'utmf_source_medium_campaign', label: 'UTM First Source + Medium + Campaign' },
    { value: 'utmf_utm_source.utm_value', label: 'UTM First Source' },
    { value: 'utmf_utm_medium.utm_value', label: 'UTM First Medium' },
    { value: 'utmf_utm_campaign.utm_value', label: 'UTM First Campaign' },
    { value: 'utmf_utm_content.utm_value', label: 'UTM First Content' },
    { value: 'utmf_utm_term.utm_value', label: 'UTM First Term' },

    { value: 'utml_source_medium', label: 'UTM Last Source + Medium' },
    { value: 'utml_source_campaign', label: 'UTM Last Source + Campaign' },
    { value: 'utml_source_content', label: 'UTM Last Source + Content' },
    { value: 'utml_source_term', label: 'UTM Last Source + Term' },
    { value: 'utml_source_medium_campaign', label: 'UTM Last Source + Medium + Campaign' },
    { value: 'utml_utm_source.utm_value', label: 'UTM Last Source' },
    { value: 'utml_utm_medium.utm_value', label: 'UTM Last Medium' },
    { value: 'utml_utm_campaign.utm_value', label: 'UTM Last Campaign' },
    { value: 'utml_utm_content.utm_value', label: 'UTM Last Content' },
    { value: 'utml_utm_term.utm_value', label: 'UTM Last Term' },

];