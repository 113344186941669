import React, { Fragment } from 'react'
import { ReportItemWidgetSubscription } from './ReportItemSubscriptions'
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle'
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle'
import { useNaeWindow } from '@newageerp/v3.popups.window-provider'
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle'

type Props = {
    el: ReportItemWidgetSubscription
    reload: () => void
}

export default function ReportItemSubscriptionsToolbar({ el, reload }: Props) {
    const { showViewPopup } = useNaeWindow();

    const [reqSave, reqSaveParams] = useUSave('subscription');

    const doSave = async (data: any) => {
        await reqSave(data, el.id)
        reload();
    }

    const convertToLifeTime = async (subId: number) => {
        if (!window.confirm('Are you sure?')) return false;
        axiosInstance.post(
            '/app/plugins/SubscriptionLifetime/convertToLifetime',
            { id: subId }
        )
    }


    const onClick = (subId: number) => {
        const event = new CustomEvent(
            'SFSOpenEditModalWindow',
            {
                detail: {
                    schema: 'subscription-cancel',
                    type: 'main',
                    id: "new",
                    options: {
                        createOptions: {
                            convert: {
                                schema: 'subscription',
                                element: { id: subId }
                            }
                        }
                    },
                    onSaveCallback: (el: any, back: any) => {
                        if (back) {
                            back();
                        }
                        reload();
                    }
                }
            }
        );
        window.dispatchEvent(event);
    }

    const openModal = () => {
        showViewPopup({
            id: el.id,
            schema: 'subscription',
            type: "main",
        });
    }


    return (
        <Fragment>
            {!!el.period && <StatusWidget color='sky' smallPadding={true}>{el.period}</StatusWidget>}
            {el.active && <StatusWidget color='teal' smallPadding={true}>Active</StatusWidget>}
            {!el.active && <StatusWidget color='red' smallPadding={true}>Inactive</StatusWidget>}
            {el.lifetime && <StatusWidget color='purple' smallPadding={true}>Lifetime</StatusWidget>}

            {el.active &&
                <ToolbarButton
                    iconName='ban'
                    children={'cancel'}
                    small={true}
                    onClick={() => onClick(el.id)}
                />
            }

            {!el.lifetime &&
                <ToolbarButton
                    iconName='badge-dollar'
                    children={'convert to lifetime'}
                    small={true}
                    onClick={() => convertToLifeTime(el.id)}
                />
            }

            {!el.active && <ToolbarButton
                iconName='arrows-rotate'
                children={'reactivate'}
                small={true}
                onClick={() => doSave({
                    active: true,
                    finishDate: null,
                    lifetime: false,
                })}
                confirmation={true}
                loading={reqSaveParams.loading}
            />}

            <ToolbarButton
                iconName='up-right-from-square'
                children='open'
                onClick={openModal}
                small={true}
            />
        </Fragment>
    )
}

