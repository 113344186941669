import { RJSFSchema } from '@rjsf/utils';

export const KlavyioConfigSchema: RJSFSchema = {
    type: "object",
    properties: {
        serviceUrl: { type: 'string' },
        enabledSync: {type: 'number'},
        queueName: {type: 'string'},
        recurringQueueName: {type: 'string'},
    }
  }