import React from 'react'
import { TabContainer } from '@newageerp/v3.bundles.layout-bundle'
import GrossV2OrderItemsByFunnel from './GrossV2OrderItems/GrossV2OrderItemsByFunnel'
import GrossV2OrderItemsByDate from './GrossV2OrderItems/GrossV2OrderItemsByDate'

type Props = {
    date: {
        from: string,
        to: string
    },
    loadState: number,
    adSources: string[],
    countries: string[],
    funnels: string[],
}

export default function GrossV2OrderItems(props: Props) {

    return (
        <TabContainer

            items={[
                {
                    content: <GrossV2OrderItemsByDate {...props} />,
                    tab: {
                        children: 'By date'
                    }
                },
                {
                    content: <GrossV2OrderItemsByFunnel {...props} />,
                    tab: {
                        children: 'By funnel'
                    }
                }
            ]}
        />
    )
}
