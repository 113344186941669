import { ORM } from "redux-orm";
import AdCostModel from "./AdCostModel"
import AdaloUserModel from "./AdaloUserModel"
import CountryModel from "./CountryModel"
import CountryGroupModel from "./CountryGroupModel"
import CustomerModel from "./CustomerModel"
import FrontendModel from "./FrontendModel"
import KlavyioEventSyncModel from "./KlavyioEventSyncModel"
import OrderItemModel from "./OrderItemModel"
import OrderItemSkuGroupModel from "./OrderItemSkuGroupModel"
import PaymentModel from "./PaymentModel"
import PaymentRefundRequestModel from "./PaymentRefundRequestModel"
import QueueModel from "./QueueModel"
import QuizResultModel from "./QuizResultModel"
import ReportItemModel from "./ReportItemModel"
import ShippingOrderModel from "./ShippingOrderModel"
import SubscriptionModel from "./SubscriptionModel"
import SubscriptionCancelModel from "./SubscriptionCancelModel"
import SubscriptionCancelReasonModel from "./SubscriptionCancelReasonModel"
import SubscriptionCancelRequestModel from "./SubscriptionCancelRequestModel"
import SubscriptionPaymentModel from "./SubscriptionPaymentModel"
import SubscriptionPriceModel from "./SubscriptionPriceModel"
import SubscriptionSupportReportModel from "./SubscriptionSupportReportModel"
import UserModel from "./UserModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    AdCostModel,
    AdaloUserModel,
    CountryModel,
    CountryGroupModel,
    CustomerModel,
    FrontendModel,
    KlavyioEventSyncModel,
    OrderItemModel,
    OrderItemSkuGroupModel,
    PaymentModel,
    PaymentRefundRequestModel,
    QueueModel,
    QuizResultModel,
    ReportItemModel,
    ShippingOrderModel,
    SubscriptionModel,
    SubscriptionCancelModel,
    SubscriptionCancelReasonModel,
    SubscriptionCancelRequestModel,
    SubscriptionPaymentModel,
    SubscriptionPriceModel,
    SubscriptionSupportReportModel,
    UserModel,
);

export default orm;