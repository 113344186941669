
import { MenuItemWithAction, MenuItem, MenuDivider } from '@newageerp/v3.bundles.modal-bundle'
import React, { Fragment, useEffect, useState } from 'react'

type Props = {
    id: number
    element: any
}

export default function ReporItem(props: Props) {
    const [config, setConfig] = useState<any>({});
    const getConfigData = async () => {
        const res = await fetch('/app/nae-core/sf-config/getUserConfig?config=sessions');
        const resJson = await res.json();
        if (resJson.success) setConfig(resJson.data);
    }

    useEffect(() => {
        getConfigData().catch(console.error);
    }, []);

    const access = {
        cart: ('cartUrl' in config),
        session: ('sessionUrl' in config)
    }

    return (
        <Fragment>
            <MenuItemWithAction
                elementId={props.id}
                children='Resync report item'
                url="/app/plugins/ReportItem/resyncReportItem"
                iconName='arrows-rotate'
            />
            <MenuItemWithAction
                elementId={props.id}
                children='Resync order items'
                url='/app/plugins/ReportItem/resyncOrderItems'
                iconName='arrows-rotate'
            />
            <MenuItemWithAction
                elementId={props.id}
                children='Resync order items (force)'
                url='/app/plugins/ReportItem/resyncOrderItemsForceSub'
                iconName='arrows-rotate'
            />

            {(access.cart || access.session) && <MenuDivider />}

            {access.cart && <MenuItem
                hrefNewWindow={`${config.cartUrl}/app/nae-core/u/find/cart-data/uuid/${props.element.orderId}`}
                children='Show in cart system'
                iconName='arrow-up-right-from-square'
            />}
            {access.session && <MenuItem
                hrefNewWindow={`${config.sessionUrl}/app/nae-core/u/find/session-data/uuid/${props.element.orderId}`}
                children='Show in session system'
                iconName='arrow-up-right-from-square'
            />}
        </Fragment>
    )
}
