import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';

interface Props {
    fieldKey: string,
}

export default function PaymentsPspData(props: Props) {
    const { element } = useListTableRow();

    return (
        <div className='flex gap-x-2 items-center w-[50px]'>
            {!!element.paymentPsp && <Fragment>
                <img className='max-h-[15px] max-w-[50px]' src={`/payment/icons/${element.paymentPsp.pspName}.svg`} alt={element.paymentPsp.pspName} title={element.paymentPsp.pspName} />
            </Fragment>}

            {!element.paymentPsp && <Fragment>
                <img className='max-h-[15px] max-w-[50px]' src={`/payment/icons/${element.source}.svg`} alt={element.source} title={element.source} />
            </Fragment>}
        </div>
    )
}