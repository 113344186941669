import { Card, MP, Table, Window, Button } from '@newageerp/crm-ui';
import React, { useEffect, useState } from 'react'
import { MpFilterStorageListResponseData } from "@newageerp/mp-types";

type Props = {
    reloadKey: number,
    selectedCharts: string[][],
    setSelectedCharts: (v: string[][]) => void,
    setAvailableFilters: (v: MpFilterStorageListResponseData[]) => void,
    setShowFiltersPopup: (v: boolean) => void,
    onClear: () => void,
}

export default function ProductPerformanceChartList(props: Props) {
  

    const { dataSource } = MP.useMpInfo();

    const onSelectChart = (idx: number, title: string) => {
        const _selectedCharts: string[][] = JSON.parse(JSON.stringify(props.selectedCharts));
        if (_selectedCharts[idx].indexOf(title) > -1) {
            _selectedCharts[idx] = _selectedCharts[idx].filter(t => t !== title);
        } else {
            _selectedCharts[idx] = [..._selectedCharts[idx], title]
        }
        props.setSelectedCharts(_selectedCharts);
    }

    const [search, setSearch] = useState('');
    const [filterListRequest, { data: savedFilters }] = MP.filter.useFiltersList();

    const getFiltersList = async () => {
        await filterListRequest({
            dataSource,
            user: 'all'
        })
    }

    useEffect(() => {
        if (props.reloadKey > 0) {
            getFiltersList().catch(console.error)
        }
    }, [props.reloadKey]);

    useEffect(() => {
        props.setAvailableFilters(savedFilters);
    }, [savedFilters]);

    const tbl = Table.small;

    const filtersToShow = savedFilters.filter(f => {
        if (!search) return true;
        return f.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
    })

    return (
        <Card.WhiteCard
            header={{
                title: 'Available filters',
                toolbar: <Card.toolbar.CardToolbar>
                    <div className='flex items-center justify-between w-full'>
                        <Card.toolbar.SearchBar value={search} onSearch={setSearch} />

                        <div className='flex gap-2'>
                            <Button.ToolbarButton iconName='empty-set' onClick={props.onClear}>Clear chart filters</Button.ToolbarButton>
                            <Button.ToolbarButton iconName='cogs' onClick={() => props.setShowFiltersPopup(true)}>Manage saved filters</Button.ToolbarButton>
                        </div>
                    </div>
                </Card.toolbar.CardToolbar>
            }}
        >
            <tbl.Table
                thead={
                    <thead>
                        <tr>
                            <tbl.Th props={{
                                className: 'w-[250px]'
                            }}>Filter</tbl.Th>
                            <tbl.Th props={{
                                className: 'w-[50px]'
                            }}>{"Charts"}</tbl.Th>

                            {props.selectedCharts.map((_, chartdIdx) => {
                                return (
                                    <tbl.Th props={{
                                        className: 'w-[30px]'
                                    }} key={`th-${chartdIdx}`}>{""}</tbl.Th>
                                )
                            })}
                            <tbl.Th>{" "}</tbl.Th>
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        {filtersToShow.map((f, fIdx) => {

                            return <tr key={`f-${fIdx}-${f.title}`}>
                                <tbl.Td>
                                    {f.title}
                                </tbl.Td>
                                <tbl.Td>{" "}</tbl.Td>

                                {props.selectedCharts.map((charts, chartdIdx) => {
                                    const isChecked = charts.indexOf(f.title) > -1;

                                    return (
                                        <tbl.Td key={`f-${fIdx}-${f.title}-${chartdIdx}`} props={{ className: 'text-left' }}>

                                            <Window.list.pagination.PageButton onClick={() => onSelectChart(chartdIdx, f.title)} active={isChecked}>
                                                {chartdIdx + 1}
                                            </Window.list.pagination.PageButton>

                                        </tbl.Td>
                                    )
                                })}
                                <tbl.Td>{" "}</tbl.Td>
                            </tr>
                        })}
                    </tbody>
                }
            />

        </Card.WhiteCard>
    )
}
