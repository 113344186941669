import { DashboardGrossCustomConfig, DashboardGrossCustomFilter, DashboardGrossCustomGroupBy } from "./types"
import { MpFilterFactorySetUniversalItem, MpAggregateColumn, MpOrderColumn } from '@newageerp/mp-types'

export const DashboardGrossCustomDataAggregate: MpAggregateColumn[] = [
    {
        "column": "report_item_payment.gross_total",
        "function": "sum",
        "title": "Gross total"
    },
    {
        "column": "report_item_payment.gross_initial_sub_total",
        "function": "sum",
        "title": "All subs total"
    },
    {
        "column": "report_item_payment.gross_initial_lifetime_total",
        "function": "sum",
        "title": "Lifetime subs total"
    },
    {
        "column": "report_item_payment.gross_upsell_total",
        "function": "sum",
        "title": "Upsell total"
    },
    {
        "column": "id",
        "function": "count",
        "title": "Subscriptions"
    }
]

export const DashboardGrossCustomDataFilter = (
    config: DashboardGrossCustomConfig,
    filter: DashboardGrossCustomFilter
): MpFilterFactorySetUniversalItem[] => {
    if (!config.filters.gross) {
        return [];
    }

    const dataFilter: MpFilterFactorySetUniversalItem[] = [
        config.filters.gross,
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "date",
                                "valueFrom": filter.date.dateFrom,
                                "valueTo": filter.date.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "test_user",
                                "value": "0"
                            }
                        }
                    ]
                }
            ]
        }
    ];
    if (filter.countries && filter.countries.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "country",
                                    "value": filter.countries.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    if (filter.adSources && filter.adSources.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "report_item_ad_source.ad_source_level1",
                                    "value": filter.adSources.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    if (filter.funnels && filter.funnels.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "slug",
                                    "value": filter.funnels.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    return dataFilter;
}


export const DashboardGrossCustomAdSpendAggregate: MpAggregateColumn[] = [
    {
        "column": "total",
        "function": "sum",
        "title": "Ad Spend total"
    }
]

export const DashboardGrossCustomAdSpendFilter = (
    config: DashboardGrossCustomConfig,
    filter: DashboardGrossCustomFilter
): MpFilterFactorySetUniversalItem[] => {
    if (!config.filters.ad_cost) {
        return [];
    }

    const dataFilter: MpFilterFactorySetUniversalItem[] = [
        config.filters.ad_cost,
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "date",
                                "valueFrom": filter.date.dateFrom,
                                "valueTo": filter.date.dateTo
                            }
                        }
                    ]
                }
            ]
        }
    ];

    
    if (filter.countries && filter.countries.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "country",
                                    "value": filter.countries.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    return dataFilter;
}
export const DashboardGrossCustomAdSpendFilterAll = (
    config: DashboardGrossCustomConfig,
    filter: DashboardGrossCustomFilter
): MpFilterFactorySetUniversalItem[] => {

    const dataFilter: MpFilterFactorySetUniversalItem[] = [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "date",
                                "valueFrom": filter.date.dateFrom,
                                "valueTo": filter.date.dateTo
                            }
                        }
                    ]
                }
            ]
        }
    ];

    
    if (filter.countries && filter.countries.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "country",
                                    "value": filter.countries.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    return dataFilter;
}

export const DashboardGrossCustomDataSort = (
    groupBy: DashboardGrossCustomGroupBy
): MpOrderColumn[] => {
    if (groupBy === 'date') {
        return [
            {
                "column": "date",
                "direction": "DESC"
            }
        ];
    }
    return [
        {
            "column": groupBy,
            "direction": "ASC"
        }
    ]
}


export const DashboardGrossCustomRecurringFilter = (
    config: DashboardGrossCustomConfig,
    filter: DashboardGrossCustomFilter
): MpFilterFactorySetUniversalItem[] => {
    if (!config.filters.recurring) {
        return [];
    }

    const dataFilter: MpFilterFactorySetUniversalItem[] = [
        config.filters.recurring,
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "date",
                                "valueFrom": filter.date.dateFrom,
                                "valueTo": filter.date.dateTo
                            }
                        }
                    ]
                }
            ]
        }
    ];
    if (filter.countries && filter.countries.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "report_item.country",
                                    "value": filter.countries.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    if (filter.adSources && filter.adSources.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "report_item.report_item_ad_source.ad_source_level1",
                                    "value": filter.adSources.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    if (filter.funnels && filter.funnels.length > 0) {
        dataFilter.push(
            {
                "comparison": "and",
                "filters": [
                    {
                        "comparison": "and",
                        "filters": [
                            {
                                "class": "DataInFilter",
                                "props": {
                                    "column": "report_item.slug",
                                    "value": filter.funnels.join("\n")
                                }
                            },
                        ]
                    }
                ]
            }
        )
    }
    return dataFilter;
}


export const DashboardGrossCustomRecurringGroupByPath = (
    groupBy: DashboardGrossCustomGroupBy
) => {
    const groupByColumn = groupBy === 'date' ? 'date' : `report_item.${groupBy}`;
    return groupByColumn;
}

export const CrmGrossFrom = 'crm.report_item';
export const CrmAdsFromFb = 'crm-ads.ad_cost_facebook_custom';
export const CrmAdsFromTk = 'crm-ads.ad_cost_tiktok_custom';

export const CrmAdsFromFbAll = 'crm-ads.ad_cost_facebook';
export const CrmAdsFromTkAll = 'crm-ads.ad_cost_tik_tok';
export const CrmAdsFromGoogleAll = 'crm-ads.ad_cost_google';


export const CrmAdsFields = [
    "id",
    "date[date]:date",
    "country",
    "total"
  ]

  export const CrmGrossFields = [
    "id",
    "order_id",
    "timezone_date[date]:date",
    "country",
    "slug",
    "period",
    "test_user",
    "report_item_ad_source.ad_source_level1",
    "report_item_ad_source.ad_source_level1_adopted",
    "report_item_ad_source.ad_source_level2",
    "report_item_payment.gross_total",
    "report_item_payment.gross_initial_sub_total",
    "report_item_payment.gross_initial_lifetime_total",
    "report_item_payment.gross_upsell_total"
  ]