import { IAdCostFieldsNae } from './useAdCostHookNae';
import { IAdaloUserFieldsNae } from './useAdaloUserHookNae';
import { ICountryFieldsNae } from './useCountryHookNae';
import { ICountryGroupFieldsNae } from './useCountryGroupHookNae';
import { ICustomerFieldsNae } from './useCustomerHookNae';
import { IFrontendFieldsNae } from './useFrontendHookNae';
import { IKlavyioEventSyncFieldsNae } from './useKlavyioEventSyncHookNae';
import { IOrderItemFieldsNae } from './useOrderItemHookNae';
import { IOrderItemSkuGroupFieldsNae } from './useOrderItemSkuGroupHookNae';
import { IPaymentFieldsNae } from './usePaymentHookNae';
import { IPaymentRefundRequestFieldsNae } from './usePaymentRefundRequestHookNae';
import { IQuizResultFieldsNae } from './useQuizResultHookNae';
import { IReportItemFieldsNae } from './useReportItemHookNae';
import { IShippingOrderFieldsNae } from './useShippingOrderHookNae';
import { ISubscriptionFieldsNae } from './useSubscriptionHookNae';
import { ISubscriptionCancelFieldsNae } from './useSubscriptionCancelHookNae';
import { ISubscriptionCancelReasonFieldsNae } from './useSubscriptionCancelReasonHookNae';
import { ISubscriptionCancelRequestFieldsNae } from './useSubscriptionCancelRequestHookNae';
import { ISubscriptionPaymentFieldsNae } from './useSubscriptionPaymentHookNae';
import { ISubscriptionPriceFieldsNae } from './useSubscriptionPriceHookNae';
import { ISubscriptionSupportReportFieldsNae } from './useSubscriptionSupportReportHookNae';
import { IUserFieldsNae } from './useUserHookNae';
import { SelectorAdCostNae,SelectorAdaloUserNae,SelectorCountryNae,SelectorCountryGroupNae,SelectorCustomerNae,SelectorFrontendNae,SelectorKlavyioEventSyncNae,SelectorOrderItemNae,SelectorOrderItemSkuGroupNae,SelectorPaymentNae,SelectorPaymentRefundRequestNae,SelectorQuizResultNae,SelectorReportItemNae,SelectorShippingOrderNae,SelectorSubscriptionNae,SelectorSubscriptionCancelNae,SelectorSubscriptionCancelReasonNae,SelectorSubscriptionCancelRequestNae,SelectorSubscriptionPaymentNae,SelectorSubscriptionPriceNae,SelectorSubscriptionSupportReportNae,SelectorUserNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.AdCost.className) {
            return {
                dataToCheck: SelectorAdCostNae(store.getState()),
                fields: IAdCostFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.AdaloUser.className) {
            return {
                dataToCheck: SelectorAdaloUserNae(store.getState()),
                fields: IAdaloUserFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Country.className) {
            return {
                dataToCheck: SelectorCountryNae(store.getState()),
                fields: ICountryFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.CountryGroup.className) {
            return {
                dataToCheck: SelectorCountryGroupNae(store.getState()),
                fields: ICountryGroupFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Customer.className) {
            return {
                dataToCheck: SelectorCustomerNae(store.getState()),
                fields: ICustomerFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Frontend.className) {
            return {
                dataToCheck: SelectorFrontendNae(store.getState()),
                fields: IFrontendFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.KlavyioEventSync.className) {
            return {
                dataToCheck: SelectorKlavyioEventSyncNae(store.getState()),
                fields: IKlavyioEventSyncFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.OrderItem.className) {
            return {
                dataToCheck: SelectorOrderItemNae(store.getState()),
                fields: IOrderItemFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.OrderItemSkuGroup.className) {
            return {
                dataToCheck: SelectorOrderItemSkuGroupNae(store.getState()),
                fields: IOrderItemSkuGroupFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Payment.className) {
            return {
                dataToCheck: SelectorPaymentNae(store.getState()),
                fields: IPaymentFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaymentRefundRequest.className) {
            return {
                dataToCheck: SelectorPaymentRefundRequestNae(store.getState()),
                fields: IPaymentRefundRequestFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.QuizResult.className) {
            return {
                dataToCheck: SelectorQuizResultNae(store.getState()),
                fields: IQuizResultFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.ReportItem.className) {
            return {
                dataToCheck: SelectorReportItemNae(store.getState()),
                fields: IReportItemFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.ShippingOrder.className) {
            return {
                dataToCheck: SelectorShippingOrderNae(store.getState()),
                fields: IShippingOrderFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Subscription.className) {
            return {
                dataToCheck: SelectorSubscriptionNae(store.getState()),
                fields: ISubscriptionFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SubscriptionCancel.className) {
            return {
                dataToCheck: SelectorSubscriptionCancelNae(store.getState()),
                fields: ISubscriptionCancelFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SubscriptionCancelReason.className) {
            return {
                dataToCheck: SelectorSubscriptionCancelReasonNae(store.getState()),
                fields: ISubscriptionCancelReasonFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SubscriptionCancelRequest.className) {
            return {
                dataToCheck: SelectorSubscriptionCancelRequestNae(store.getState()),
                fields: ISubscriptionCancelRequestFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SubscriptionPayment.className) {
            return {
                dataToCheck: SelectorSubscriptionPaymentNae(store.getState()),
                fields: ISubscriptionPaymentFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SubscriptionPrice.className) {
            return {
                dataToCheck: SelectorSubscriptionPriceNae(store.getState()),
                fields: ISubscriptionPriceFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SubscriptionSupportReport.className) {
            return {
                dataToCheck: SelectorSubscriptionSupportReportNae(store.getState()),
                fields: ISubscriptionSupportReportFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.User.className) {
            return {
                dataToCheck: SelectorUserNae(store.getState()),
                fields: IUserFieldsNae,    
            }
        }
        return undefined;
}