import { MP, Window, Form, Button } from '@newageerp/crm-ui';
import React, { Fragment, useEffect, useState } from 'react'
import { MpFilterStorageListResponseData } from '@newageerp/mp-types'

type Props = {
    dataSource: string,
    activeFilter: MpFilterStorageListResponseData | undefined,
    setActiveFilter: (v?: MpFilterStorageListResponseData) => void,
}

export default function RealtimeV3FilterSelect(props: Props) {
    const { dataSource } = props;

    const [filterListRequest, { data: savedFilters }] = MP.filter.useFiltersList();
    const {user} = MP.useMpInfo();

    const [selectedFilter, setSelectedFilter] = useState(props.activeFilter ? props.activeFilter.title : '')
    useEffect(() => {
        if (savedFilters.length > 0) {
            if (selectedFilter) {
                props.setActiveFilter(savedFilters.find(d => d.title === selectedFilter));
            } else {
                props.setActiveFilter(undefined);
            }
        }
    }, [selectedFilter, savedFilters]);


    const [showFiltersPopup, setShowFiltersPopup] = useState(false);
    const getFiltersList = async () => {
        await filterListRequest({
            dataSource,
            user: `${user}`
        })
    }
    useEffect(() => {
        if (!showFiltersPopup) {
            getFiltersList().then(() => {
                if (props.activeFilter) {
                    setSelectedFilter(props.activeFilter.title)
                }
            }).catch(console.error)
        }
    }, [showFiltersPopup]);

    return (
        <Fragment>
            <div className='flex justify-between items-start'>
                <div className='flex items-start gap-2'>
                    <p className='uppercase'>Step 1:</p>
                    <p className='text-slate-500 w-[150px]'>select data source</p>
                    <div>
                        <Form.SelectOptions
                            className='w-[400px]'
                            options={[
                                {
                                    label: '...data source',
                                    value: '',
                                },
                                ...savedFilters.map(f => ({
                                    label: f.title,
                                    value: f.title,
                                }))
                            ]}
                            value={selectedFilter}
                            onChange={setSelectedFilter}
                        />
                    </div>
                </div>

                <div>
                    <Button.ToolbarButton iconName='cogs' onClick={() => setShowFiltersPopup(true)} />
                </div>
            </div>
                <Window.Popup
                    isPopup={showFiltersPopup}
                    title="Filters management"
                    onClick={() => setShowFiltersPopup(false)}
                    className='w-full h-[80vh]'
                >
                    <MP.filter.SaveManagement />
                </Window.Popup>
            
        </Fragment>
    )
}

