import React from 'react'
import { useListTableRow } from '@newageerp/v3.bundles.app-bundle';

interface Props {
    fieldKey: string,
}

export default function PaymentsLinkToOrder(props: Props) {
    const { element } = useListTableRow();

    const link = `/app/nae-core/u/find/report-item/orderId/${element.orderId.substring(0, 36)}`;

    const onClick = () => {
        window.location.href = link;
    }

    return (
        <button className='text-blue-500' onClick={onClick}>{element.orderId}</button>
    )
}
