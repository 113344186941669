import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Compact, CompactRow, FieldDateRange, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import { useTranslation } from 'react-i18next';

import { LogoLoader, MainToolbarTitle, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

import classNames from 'classnames'
import { useLocalStorage, useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import LanguageFilter from './Components/LanguageFilter';
import FunnelFilter from './Components/FunnelFilter';
import AngleFilter from './Components/AngleFilter';
import GenderFilter from './Components/GenderFilter';
import CountryFilter, { CountryFilterCountry } from './Components/CountryFilter';
import CountryGroupFilter, { CountryGroupFilterGroup } from './Components/CountryGroupFilter';
import PlanSkuFilter from './Components/PlanSkuFilter';
import AdSourceFilter from './Components/AdSourceFilter';
import AdSourceL2Filter from './Components/AdSourceL2Filter';
import AdSourceFilterAdopted from './Components/AdSourceFilterAdopted';
import SubPeriodFilter from './Components/SubPeriodFilter';
import CohortSettings from './Components/CohortSettings';
import { checkPermission, Permissions } from '../Permissions/Permissions';


export default function DashboardProductPerformance2() {
    const { t } = useTranslation();
    const { userState } = useTemplatesCore();

    const [cohort, setCohort] = useState('month');
    const [isPermissionsShowMoney, setPermissionsShowMoney] = useState(false);

    const isPermissionsDropStats = checkPermission(userState, Permissions.Enum.support_head) || checkPermission(userState, Permissions.Enum.newsletter);

    const [lang, setLang] = useState('');
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);
    const [adSources, setAdSources] = useLocalStorage('DashboardAdSourceFilter', []);
    const [adSourcesAdopted, setAdSourcesAdopted] = useLocalStorage('DashboardAdSourceAdoptedFilter', []);
    const [adSourcesL2, setAdSourcesL2] = useLocalStorage('DashboardAdSourceL2Filter', []);

    const [angle, setAngle] = useState('');
    const [gender, setGender] = useState('');
    const [countries, setCountries] = useState<string[]>([]);
    const [countryGroup, setCountryGroup] = useState(0);
    const [plan, setPlan] = useState('');
    const [showNewVersion, setShowNewVersion] = useState('');
    const [boughtCouch, setBoughtCouch] = useState('');

    const [skuPlans, setSkuPlans] = useState<string[]>([]);

    const [splitByPaymentMethods, setSplitByPaymentMethods] = useState('0');
    const [showSubribersChurn, setShowSubribersChurn] = useState(isPermissionsDropStats ? '1' : '0');
    const [showRecurringFromInitialToEop, setShowRecurringFromInitialToEop] = useState(isPermissionsDropStats ? '1' : '0');

    const [showTrials, setShowTrials] = useState('');

    const [stats, setStats] = useState<any>({});
    const [statsExtra, setStatsExtra] = useState<any>({});

    const [type, setType] = useState('performance');

    const [ltvStats, setLtvStats] = useState<any>();

    const defDates = {
        dateFrom: '2022-12-01',
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [reqData, reqDataParams] = useURequest("/app/plugins/ProductPerformance/calculateV2");
    const loadData = () => {
        reqData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
            lang,
            funnels,
            countries,
            plan,
            gender,
            angle,
            showNewVersion,
            boughtCouch,
            skuPlans,
            adSources,
            adSourcesAdopted,
            adSourcesL2,
            cohort,
            showTrials,
        }).then((res: any) => {
            setStats(res.data.reports);
            setStatsExtra(res.data.extra);
            setLtvStats(res.data.ltv);
            setPermissionsShowMoney(res.data.showMoney);
        })
    }

    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    const groupByTabs = [
        {
            title: 'Product performance',
            key: 'performance'
        },

    ];
    if (isPermissionsShowMoney) {
        groupByTabs.push({
            title: 'LTV',
            key: 'ltv'
        });
    }

    return (
        <Fragment>
            <MainToolbarTitle title={"Product performance"} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true} className='space-y-2'>
                    <div className='flex flex-wrap gap-x-4 gap-y-1 items-start'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <CohortSettings
                            cohort={cohort}
                            setCohort={setCohort}
                        />
                        <SubPeriodFilter
                            period={plan}
                            setPeriod={setPlan}
                        />

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Trials')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showTrials}
                                        onChange={setShowTrials}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "1",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>

                    </div>
                    <div className='flex flex-wrap gap-x-4 gap-y-1 items-start'>

                        <LanguageFilter
                            lang={lang}
                            setLang={setLang}
                        />

                        <FunnelFilter
                            funnels={funnels}
                            setFunnels={setFunnels}
                        />

                        <AngleFilter
                            angle={angle}
                            setAngle={setAngle}
                        />

                        <GenderFilter
                            gender={gender}
                            setGender={setGender}
                        />



                        <CountryFilter
                            countries={countries}
                            setCountries={setCountries}
                            countriesData={countriesData.data.data}
                        />

                        <CountryGroupFilter
                            countryGroup={countryGroup}
                            setCountryGroup={setCountryGroup}
                            countryGroupsData={countryGroupsData.data.data}

                            setCountries={setCountries}
                            countriesData={countriesData.data.data}
                        />

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Bought couch')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={boughtCouch}
                                        onChange={setBoughtCouch}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "1",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <PlanSkuFilter
                                skuPlans={skuPlans}
                                setSkuPlans={setSkuPlans}
                            />
                        </Compact>

                    </div>
                    <div className='flex flex-wrap gap-x-4 gap-y-1 items-start'>
                        <AdSourceFilter
                            adSources={adSources}
                            setAdSources={setAdSources}
                        />

                        <AdSourceFilterAdopted
                            adSources={adSourcesAdopted}
                            setAdSources={setAdSourcesAdopted}
                        />

                        <AdSourceL2Filter
                            adSources={adSourcesL2}
                            setAdSources={setAdSourcesL2}
                        />
                    </div>
                    <div className='flex flex-wrap gap-x-4 gap-y-1 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Show Favorites Functionality')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showNewVersion}
                                        onChange={setShowNewVersion}
                                        options={[
                                            {
                                                label: "All",
                                                value: "",
                                            },
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "1",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Split by payment methods')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={splitByPaymentMethods}
                                        onChange={setSplitByPaymentMethods}
                                        options={[
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "1",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Show subscribers churn')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showSubribersChurn}
                                        onChange={setShowSubribersChurn}
                                        options={[
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "1",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Show churn from initial to EOP')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showRecurringFromInitialToEop}
                                        onChange={setShowRecurringFromInitialToEop}
                                        options={[
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "1",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                    </div>
                    <div>
                        <MainButton color={"sky"} iconName='filter' disabled={reqDataParams.loading} onClick={loadData}>Filter</MainButton>
                    </div>
                </WhiteCard>

                <div className="w-full">
                    <div className={classNames('border-b', 'border-slate-200')}>
                        {groupByTabs.map((t) => {
                            const active = t.key === type;
                            return (
                                <button
                                    className={
                                        classNames(
                                            'text-sm',
                                            { 'font-semibold': active },
                                            'px-[16px]',
                                            'py-[8px]',
                                            { 'text-sky-500': active },
                                            { 'text-slate-500': !active },
                                        )
                                    }
                                    onClick={() => setType(t.key)}
                                    style={active ? { boxShadow: 'inset 0px -2px 0px 0px #0EA5E9' } : undefined}
                                >
                                    {t.title}
                                </button>
                            )
                        })}
                    </div>
                </div>

                {reqDataParams.loading && <LogoLoader size={60} />}

                {!!statsExtra && statsExtra.loaded && type === 'performance' &&
                    <WhiteCard>
                        <Table
                            thead={<thead>
                                <tr>
                                    <Td></Td>
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {statsExtra.dates.map((d: string) => {
                                        return (
                                            <Fragment key={`d-${d}`}>
                                                <tr className='total-row font-bold'>
                                                    <Td className='w-[300px]' xSmall={true} ySmall={true}>{d}</Td>
                                                    {statsExtra.periods.map((p: number) => {
                                                        return (
                                                            <Td xSmall={true} ySmall={true} textAlignment={'text-right'}>{`${p + 1}`} period</Td>
                                                        )
                                                    })}
                                                    <Td></Td>
                                                </tr>
                                                <tr>
                                                    <Td xSmall={true} ySmall={true}>Customers Acquired During Period</Td>
                                                    <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                        {numberFormat({
                                                            userInput: stats[d].customers_acquired,
                                                            decimals: 0,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })}
                                                    </Td>
                                                    <Td></Td>
                                                </tr>
                                                <tr>
                                                    <Td xSmall={true} ySmall={true}>Cancellations (Lifetime) EOP	</Td>
                                                    <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                        {numberFormat({
                                                            userInput: stats[d].lifetimeDrops,
                                                            decimals: 0,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })}
                                                    </Td>
                                                    <Td></Td>
                                                </tr>
                                                <tr>
                                                    <Td xSmall={true} ySmall={true}>Subscribers Acquired During Period</Td>
                                                    <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                        {numberFormat({
                                                            userInput: stats[d].subscribers_acquired,
                                                            decimals: 0,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })}
                                                    </Td>
                                                    <Td></Td>
                                                </tr>
                                                <tr>
                                                    <Td xSmall={true} ySmall={true}>Churn (Lifetime), %	</Td>
                                                    <Td xSmall={true} ySmall={true} textAlignment='text-right'>
                                                        {numberFormat({
                                                            userInput: (stats[d].lifetimeDrops / stats[d].customers_acquired * 100),
                                                            decimals: 2,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })}%</Td>
                                                    <Td></Td>
                                                </tr>
                                                {showSubribersChurn === '1' && <Fragment>
                                                    <tr>
                                                        <Td xSmall={true} ySmall={true}>Subscribers, EOP</Td>
                                                        <DrawFromTo
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].drops[p])
                                                            }
                                                            start={stats[d].subscribers_acquired}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Cancellations (Subscribers opted out), EOP</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].drops[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Churn (Subscribers opted out), %	</Td>
                                                        <DrawFromToChurnV2
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].drops[p])
                                                            }
                                                            start={stats[d].subscribers_acquired}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    {showRecurringFromInitialToEop === '1' &&
                                                        <tr className=''>
                                                            <Td xSmall={true} ySmall={true}>Churn (Subscribers opted out from initial), %</Td>
                                                            <DrawFromToChurn
                                                                data={
                                                                    stats[d].periods.map((p: number) => stats[d].drops[p])
                                                                }
                                                                start={stats[d].subscribers_acquired}
                                                                precision={2}
                                                            />
                                                            <Td></Td>
                                                        </tr>
                                                    }
                                                    <tr className='font-medium'>
                                                        <Td xSmall={true} ySmall={true}>Subscribers churn (monthly), %</Td>
                                                        <DrawFromToChurnV2
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].drops[p])
                                                            }
                                                            start={stats[d].customers_acquired}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    {showRecurringFromInitialToEop === '1' &&
                                                        <tr className=''>
                                                            <Td xSmall={true} ySmall={true}>Subscribers churn (from initial to EOP), %	</Td>
                                                            <DrawFromToChurn
                                                                data={
                                                                    stats[d].periods.map((p: number) => stats[d].drops[p])
                                                                }
                                                                start={stats[d].customers_acquired}
                                                                precision={2}
                                                            />
                                                            <Td></Td>
                                                        </tr>
                                                    }
                                                </Fragment>}


                                                <tr className='font-medium'>
                                                    <Td xSmall={true} ySmall={true}>Recurring churn (GROSS) (monthly), %</Td>
                                                    <DrawFromToChurnV2B
                                                        data={
                                                            stats[d].periods.map((p: number) => stats[d].grossSubscriptions[p])
                                                        }
                                                        start={stats[d].grossSubscriptions[0]}
                                                        precision={2}
                                                    />
                                                    <Td></Td>
                                                </tr>

                                                {splitByPaymentMethods === '1' && <Fragment>
                                                    {statsExtra.subSources.map((subSource: string) => {
                                                        return (
                                                            <Fragment key={`${subSource}-d-${d}`}>
                                                                <tr className='italic'>
                                                                    <Td xSmall={true} ySmall={true}>--- {subSource}</Td>
                                                                    <DrawFromToChurnV2B
                                                                        data={
                                                                            stats[d].periods.map((p: number) => stats[d].grossSubscriptionsBySource[subSource][p])
                                                                        }
                                                                        start={stats[d].grossSubscriptionsBySource[subSource][0]}
                                                                        precision={2}
                                                                    />
                                                                    <Td></Td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Fragment>}

                                                {showRecurringFromInitialToEop === '1' && <Fragment>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Recurring churn (GROSS) (from initial to EOP), %	</Td>
                                                        <DrawFromToChurnB
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].grossSubscriptions[p])
                                                            }
                                                            start={stats[d].grossSubscriptions[0]}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    {splitByPaymentMethods === '1' && <Fragment>
                                                        {statsExtra.subSources.map((subSource: string) => {
                                                            return (
                                                                <Fragment key={`${subSource}-d-${d}`}>
                                                                    <tr className='italic'>
                                                                        <Td xSmall={true} ySmall={true}>--- {subSource}</Td>
                                                                        <DrawFromToChurnB
                                                                            data={
                                                                                stats[d].periods.map((p: number) => stats[d].grossSubscriptionsBySource[subSource][p])
                                                                            }
                                                                            start={stats[d].grossSubscriptionsBySource[subSource][0]}
                                                                            precision={2}
                                                                        />
                                                                        <Td></Td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </Fragment>}
                                                </Fragment>
                                                }







                                                <tr className='font-medium text-purple-600'>
                                                    <Td xSmall={true} ySmall={true}>Recurring churn (NET) (monthly), %</Td>
                                                    <DrawFromToChurnV2B
                                                        data={
                                                            stats[d].periods.map((p: number) => stats[d].netSubscriptions[p])
                                                        }
                                                        start={stats[d].netSubscriptions[0]}
                                                        precision={2}

                                                    />
                                                    <Td></Td>
                                                </tr>

                                                {splitByPaymentMethods === '1' && <Fragment>
                                                    {statsExtra.subSources.map((subSource: string) => {
                                                        return (
                                                            <Fragment key={`${subSource}-d-${d}`}>
                                                                <tr className='italic text-purple-600'>
                                                                    <Td xSmall={true} ySmall={true}>--- {subSource}</Td>
                                                                    <DrawFromToChurnV2B
                                                                        data={
                                                                            stats[d].periods.map((p: number) => stats[d].netSubscriptionsBySource[subSource][p])
                                                                        }
                                                                        start={stats[d].netSubscriptionsBySource[subSource][0]}
                                                                        precision={2}
                                                                    />
                                                                    <Td></Td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Fragment>}
                                                {showRecurringFromInitialToEop === '1' && <Fragment>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Recurring churn (NET) (from initial to EOP), %	</Td>
                                                        <DrawFromToChurnB
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].netSubscriptions[p])
                                                            }
                                                            start={stats[d].netSubscriptions[0]}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                </Fragment>
                                                }

                                                {isPermissionsShowMoney && <Fragment>
                                                    <tr className='font-medium'>
                                                        <Td xSmall={true} ySmall={true}>Subscriptions (GROSS), USD</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].grossSubscriptions[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    {splitByPaymentMethods === '1' &&
                                                        <Fragment>
                                                            {statsExtra.subSources.map((subSource: string) => {
                                                                return (
                                                                    <Fragment key={`${subSource}-d-${d}-sub`}>
                                                                        <tr className='italic'>
                                                                            <Td xSmall={true} ySmall={true}>--- {subSource}</Td>
                                                                            <DrawFromToSimple
                                                                                data={
                                                                                    stats[d].periods.map((p: number) => stats[d].grossSubscriptionsBySource[subSource][p])
                                                                                }
                                                                                start={0}
                                                                                add={true}
                                                                                precision={2}
                                                                            />
                                                                            <Td></Td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    }

                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Subscriptions refunded, USD	</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].refundSubscriptions[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>

                                                    <tr className='font-medium text-cyan-600'>
                                                        <Td xSmall={true} ySmall={true}>Subscriptions (NET), USD</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].netSubscriptions[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    {splitByPaymentMethods === '1' &&
                                                        <Fragment>
                                                            {statsExtra.subSources.map((subSource: string) => {
                                                                return (
                                                                    <Fragment key={`${subSource}-d-${d}-sub`}>
                                                                        <tr className='italic text-cyan-600'>
                                                                            <Td xSmall={true} ySmall={true}>--- {subSource}</Td>
                                                                            <DrawFromToSimple
                                                                                data={
                                                                                    stats[d].periods.map((p: number) => stats[d].netSubscriptionsBySource[subSource][p])
                                                                                }
                                                                                start={0}
                                                                                add={true}
                                                                                precision={2}
                                                                            />
                                                                            <Td></Td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    }
                                                </Fragment>}


                                                {isPermissionsShowMoney && <Fragment>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Life time, USD</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].grossLifeTime[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>

                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Upsells, USD</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].grossUpsells[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Upsells refund, USD</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].refundUpsells[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    {/* <tr className=''>
                                                    <Td xSmall={true} ySmall={true}>Chargeback, USD</Td>

                                                </tr> */}
                                                    <tr className='font-medium text-blue-800'>
                                                        <Td xSmall={true} ySmall={true}>Revenue after refunds & chb, USD</Td>
                                                        <DrawFromToSimple
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].netValues[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                    <tr className=''>
                                                        <Td xSmall={true} ySmall={true}>Cumulative life time value (LTV), USD</Td>
                                                        <DrawFromTo
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].netValues[p])
                                                            }
                                                            start={0}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>

                                                    <tr className='font-medium text-blue-800'>
                                                        <Td xSmall={true} ySmall={true}>Cumulative LTV per customer, USD	</Td>
                                                        <DrawFromTo
                                                            data={
                                                                stats[d].periods.map((p: number) => stats[d].netValues[p])
                                                            }
                                                            start={0}
                                                            divider={stats[d].customers_acquired}
                                                            add={true}
                                                            precision={2}
                                                        />
                                                        <Td></Td>
                                                    </tr>
                                                </Fragment>}

                                            </Fragment>
                                        )
                                    })}

                                </tbody>
                            }
                        />
                    </WhiteCard>}

                {!!ltvStats && type === 'ltv' && <LtvTable netData={ltvStats.totals} countsData={ltvStats.counts} periods={ltvStats.periods} plans={ltvStats.plans} />}

            </div>
        </Fragment>
    )
}

const DrawFromToSimple = (props: DrawFromToProps) => {
    const divider = props.divider ? props.divider : 1;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {

            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (p / divider),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}
            </Td>)
        })}
    </Fragment>
}

type DrawFromToProps = {
    start: number,
    data: number[],
    precision?: number,
    add?: boolean,
    divider?: number
}
const DrawFromTo = (props: DrawFromToProps) => {
    let start = props.start;
    const divider = props.divider ? props.divider : 1;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            if (props.add) {
                start += p;
            } else {
                start -= p;
            }
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (start / divider),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}
            </Td>)
        })}
    </Fragment>
}


type DrawFromToChurnProps = {
    start: number,
    data: number[],
    precision?: number,
}
const DrawFromToChurn = (props: DrawFromToChurnProps) => {
    let start = props.start;
    let startX = 0;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            startX += p;
            // start -= p;

            const v = (startX / start * 100);

            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: v,
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}
const DrawFromToChurnV2 = (props: DrawFromToChurnProps) => {
    let start = props.start;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            start -= p;
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (p / (start + p) * 100),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}



const DrawFromToChurnB = (props: DrawFromToChurnProps) => {
    let start = props.start;
    let startX = 0;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            // startX += p;
            // start -= p;

            // const v = (startX / start * 100);

            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: 100 - (p / start * 100),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}

const DrawFromToChurnV2B = (props: DrawFromToChurnProps) => {
    let start = props.start;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            if (p === 0) {
                return <Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>

                </Td>
            }
            const d = (start - p);
            start -= d;
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: 100 - (p / (start + d) * 100),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}


type DrawFromToPercentProps = {
    start: number,
    startAll: number,
    data: number[],
    dataAll: number[],
    precision?: number,
    add?: boolean
}
const DrawFromToPercent = (props: DrawFromToPercentProps) => {
    let start = props.start;
    let startAll = props.startAll;

    return <Fragment>
        {props.data.map((p: number, iId: number) => {
            const pAll = props.dataAll[iId];

            if (props.add) {
                start += p;
                startAll += pAll;
            } else {
                start -= p;
                startAll -= pAll;
            }
            return (<Td xSmall={true} ySmall={true} key={`dr-${iId}`} textAlignment='text-right'>
                {numberFormat({
                    userInput: (start / startAll * 100),
                    decimals: props.precision ? props.precision : 0,
                    decPoint: '.',
                    thousandsSep: ' '
                })}%
            </Td>)
        })}
    </Fragment>
}

type LtvTableProps = {
    netData: any,
    countsData: any,
    plans: string[],
    periods: string[],
}

const LtvTable = (props: LtvTableProps) => {

    const { userState } = useTemplatesCore();
    const isAdmin = checkPermission(userState, Permissions.Enum.admin);

    let planTotals: any = {
        '1m': 0,
        '3m': 0,
        '6m': 0,
    }
    let netTotals: any = {
        '1m': 0,
        '3m': 0,
        '6m': 0,
    }

    props.plans.forEach((plan) => {
        props.periods.forEach(p => {
            const v = !!props.netData[plan][p] ? props.netData[plan][p] : 0;
            const count = !!props.countsData[plan][p] ? props.countsData[plan][p] : 0;

            let ltv = 0;
            if (count > 0) {
                ltv = v / count;
            }

            planTotals[plan] += ltv;
            netTotals[plan] += v;
        });
    });


    const allNet = (netTotals['1m'] + netTotals['3m'] + netTotals['6m']);
    const allPossibleNet = (planTotals['1m'] * (!!props.countsData['1m'] && props.countsData['1m'].length > 0 ? props.countsData['1m'][0] : 0) + planTotals['3m'] * (!!props.countsData['3m'] && props.countsData['3m'].length > 0 ? props.countsData['3m'][0] : 0) + planTotals['6m'] * (!!props.countsData['6m'] && props.countsData['6m'].length > 0 ? props.countsData['6m'][0] : 0));
    const allUsers = ((!!props.countsData['1m'] && props.countsData['1m'].length > 0 ? props.countsData['1m'][0] : 0) + (!!props.countsData['3m'] && props.countsData['3m'].length > 0 ? props.countsData['3m'][0] : 0) + (!!props.countsData['6m'] && props.countsData['6m'].length > 0 ? props.countsData['6m'][0] : 0));

    return <WhiteCard>

        <Table
            thead={
                <thead>
                    <tr>
                        <Th>Plan</Th>
                        {props.periods.map(p => {
                            return <Th key={`p-${p}`} className='text-right'>{p + 1}</Th>
                        })}
                        {isAdmin &&
                            <Th className='font-medium' textAlignment='text-right'>Total</Th>
                        }
                        {isAdmin &&
                            <Th className='font-medium' textAlignment='text-right'>Expected Total</Th>
                        }
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {props.plans.map((plan) => {
                        let planTotal = 0;
                        let netTotal = 0;
                        return <Fragment key={`pl-${plan}`}>

                            <tr className='italic font-light text-sm'>
                                <Td>USERS {plan}</Td>
                                {props.periods.map(p => {
                                    const count = !!props.countsData[plan][p] ? props.countsData[plan][p] : 0;
                                    return <Td key={`u-${plan}-p-${p}`} className='text-right'>
                                        {numberFormat({
                                            userInput: count,
                                            decimals: 0,
                                            decPoint: '.',
                                            thousandsSep: ' '
                                        })}
                                    </Td>
                                })}
                                {isAdmin &&
                                    <Td></Td>
                                }
                                {isAdmin &&
                                    <Td></Td>
                                }
                            </tr>
                            {isAdmin &&
                                <tr className='italic font-light text-sm'>
                                    <Td>NET {plan}</Td>
                                    {props.periods.map(p => {
                                        const v = !!props.netData[plan][p] ? props.netData[plan][p] : 0;
                                        netTotal += v;

                                        return <Td key={`n-${plan}-p-${p}`} className='text-right'>
                                            {numberFormat({
                                                userInput: v,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    })}
                                    {isAdmin &&
                                        <Td className='font-medium' textAlignment='text-right'>
                                            {numberFormat({
                                                userInput: netTotal,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    }
                                    {isAdmin &&
                                        <Td className='font-medium' textAlignment='text-right'>
                                            {numberFormat({
                                                userInput: planTotals[plan] * props.countsData[plan][0],
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    }
                                </tr>
                            }
                            <tr>
                                <Td>LTV {plan}</Td>
                                {props.periods.map(p => {
                                    const count = !!props.countsData[plan][p] ? props.countsData[plan][p] : 0;
                                    const v = !!props.netData[plan][p] ? props.netData[plan][p] : 0;

                                    let ltv = 0;
                                    if (count > 0) {
                                        ltv = v / count;
                                    }

                                    return <Td key={`pl-${plan}-p-${p}`} className='text-right'>
                                        {numberFormat({
                                            userInput: ltv,
                                            decimals: 2,
                                            decPoint: '.',
                                            thousandsSep: ' '
                                        })}
                                    </Td>
                                })}
                                {isAdmin &&
                                    <Td></Td>
                                }
                                {isAdmin &&
                                    <Td></Td>
                                }
                            </tr>
                            <tr className='italic font-medium total-row'>
                                <Td>Cumulative LTV {plan}</Td>
                                {props.periods.map(p => {
                                    const count = !!props.countsData[plan][p] ? props.countsData[plan][p] : 0;
                                    const v = !!props.netData[plan][p] ? props.netData[plan][p] : 0;

                                    let ltv = 0;
                                    if (count > 0) {
                                        ltv = v / count;
                                    }
                                    planTotal += ltv;

                                    return <Td key={`cpl-${plan}-p-${p}`} className='text-right'>
                                        {numberFormat({
                                            userInput: planTotal,
                                            decimals: 2,
                                            decPoint: '.',
                                            thousandsSep: ' '
                                        })}
                                    </Td>
                                })}
                                {isAdmin &&
                                    <Td></Td>
                                }
                                {isAdmin &&
                                    <Td></Td>
                                }
                            </tr>
                        </Fragment>
                    })}
                    {isAdmin && <tr className='font-extrabold'>
                        <Td>
                            Cumulative LTV ALL
                        </Td>
                        <Td colSpan={props.periods.length} textAlignment='text-right'>
                            {(allPossibleNet / allUsers).toFixed(2)}
                        </Td>
                        <Td textAlignment='text-right'>{numberFormat({
                            userInput: allNet,
                            decimals: 2,
                            decPoint: '.',
                            thousandsSep: ' '
                        })}</Td>
                        <Td textAlignment='text-right'>{numberFormat({
                            userInput: allPossibleNet,
                            decimals: 2,
                            decPoint: '.',
                            thousandsSep: ' '
                        })}</Td>
                    </tr>}
                </tbody>
            }
        />

    </WhiteCard>
}