import React, { Fragment, useEffect, useState } from 'react'
import { Window } from '@newageerp/crm-ui'
import { MainButton, ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle'
import { FieldTextarea } from '@newageerp/v3.bundles.form-bundle';
import { useUList, useUSave } from '@newageerp/v3.bundles.hooks-bundle';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { toast } from '@newageerp/v3.templates.templates-core';

export default function SfExploreDataImport() {
    const listDataSource = useListDataSource();

    const [showPopup, setShowPopup] = useState(false);
    const [importTxt, setImportTxt] = useState('');

    const [doGetFolder, getFolderParams] = useUList('sf-explore-data-folder', ['id']);
    const [doGetElement, getElementParams] = useUList('sf-explore-data-item', ['id']);

    const [doSaveReq, saveParams] = useUSave('sf-explore-data-item');
    const doSave = async () => {
        try {
            const element = JSON.parse(importTxt);

            const folders = await doGetFolder(
                [{
                    "and": [
                        ["i.title", "=", element.folder, true]
                    ]
                }],
                1,
                1
            )
            // console.log({
            //     folders
            // })
            if (folders.data.data.length > 0) {
                element.folder = folders.data.data[0];
            }
            let newElId = 'new';
            const existingElements = await doGetElement(
                [{
                    "and": [
                        ["i.exploreId", "=", element.exploreId, true]
                    ]
                }],
                1,
                1
            )
            if (existingElements.data.data.length > 0) {
                newElId = existingElements.data.data[0].id;
            }
            await doSaveReq(element, newElId);
            listDataSource.data.reload.do();
            setShowPopup(false);

            toast.success('Success import');
        } catch (e) {
            toast.error('Something went wrong...')
        }
    }

    const isLoading = getElementParams.loading || getFolderParams.loading || saveParams.loading;

    useEffect(() => {
        if (!showPopup) setImportTxt('');
    }, [showPopup])

    return (
        <Fragment>
            <ToolbarButton iconName='file-upload' onClick={() => setShowPopup(true)} />
            <Window.Popup isPopup={showPopup} onClick={() => setShowPopup(false)}>
                <FieldTextarea className='w-[500px]' rows={20} value={importTxt} onChange={e => setImportTxt(e.target.value)} />
                <MainButton iconName='save' loading={isLoading} disabled={!importTxt} onClick={doSave}>Upload</MainButton>
            </Window.Popup>
        </Fragment>
    )
}
